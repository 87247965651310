import {
	INTRO_CHANGED,
	INTRO,
	AppActionsTypes,
	CURRENT_CLUB,
	CURRENT_CLUB_CHANGED,
	LS_INTRO,
	LS_CURRENT_CLUB,
	LS_CURRENT_TEAM,
	CURRENT_TEAM,
	CURRENT_TEAM_CHANGED,
} from './types';

export const changeIntro = (intro: INTRO): AppActionsTypes => {
	localStorage.setItem(LS_INTRO, intro);

	return {
		type: INTRO_CHANGED,
		intro,
	};
};

export const changeCurrentClub = (club: CURRENT_CLUB): AppActionsTypes => {
	if (club) localStorage.setItem(LS_CURRENT_CLUB, club);
	else localStorage.removeItem(LS_CURRENT_CLUB);

	return {
		type: CURRENT_CLUB_CHANGED,
		club,
	};
};

export const changeCurrentTeam = (team: CURRENT_TEAM): AppActionsTypes => {
	if (team) localStorage.setItem(LS_CURRENT_TEAM, team);
	else localStorage.removeItem(LS_CURRENT_TEAM);

	return {
		type: CURRENT_TEAM_CHANGED,
		team,
	};
};
