import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Props } from './types';

import { Button } from 'src/components';
import * as Styled from 'src/components/portal/portal-intro/styles';

export const End: React.FC<Props> = ({ close }: Props) => {
	const { t } = useTranslation();

	return (
		<>
			<Styled.H1>{t('portal.intro.end.title')}</Styled.H1>
			<Styled.Text>{t('portal.intro.end.intro')}</Styled.Text>
			<Styled.Center>
				<Button action={close}>{t('portal.intro.end.button')}</Button>
			</Styled.Center>
		</>
	);
};

export default End;
