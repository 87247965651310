import styled from 'styled-components';

export const ModalHeading = styled.h2`
	font-size: ${({ theme: { fonts } }) => fonts.sizes.big};
	margin: 0 0 20px 0;
	text-align: center;
`;

export const Small = styled.p`
	margin-top: 0;
	font-size: 0.85rem !important;
`;
