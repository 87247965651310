import styled from 'styled-components';

export const LoaderContainer = styled.div`
	width: 100vw;
	height: calc(100vh - 210px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	div {
		margin-bottom: 30px;
	}
`;
