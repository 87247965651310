import React, { useState } from 'react';
import * as firebase from 'firebase/app';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Styled from './styles';
import type { Props } from './types';

import { Input, Button, PortalHeader, Footer } from 'src/components';
import { fireStore } from 'src/utils';
import type { Player } from 'src/types';

export const DeleteTeam: React.FC<Props> = ({ frame = false, closeAction, team }: Props) => {
	const [deleteName, setDeleteName] = useState('');
	const { t } = useTranslation();

	const DeleteCurrentTeam = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!team.name) {
			toast.error(t('portal.errors.emptyName'));
			return;
		}
		if (team.name !== deleteName) {
			toast.error(t('portal.errors.nameTeamNoMatch'));
			return;
		}

		const teamDoc = await fireStore.doc(`teams/${team.__id}`);

		await teamDoc.update({ __deleted: true, players: [] });

		team.matches.forEach((_match) => _match.update({ __deleted: true }));
		team.trainings.forEach((_training) => _training.update({ __deleted: true }));

		team.players.forEach(async (_player) => {
			const player = (await _player.get()).data() as Player;
			const guestTeam = player._guestTeams.find((_guestTeam) => _guestTeam.id === teamDoc.id);
			const baseTeam = player._baseTeams.find((_baseTeam) => _baseTeam.id === teamDoc.id);
			if (guestTeam)
				_player.update({
					_guestTeams: firebase.default.firestore.FieldValue.arrayRemove(teamDoc),
				});
			if (baseTeam)
				_player.update({
					_baseTeams: firebase.default.firestore.FieldValue.arrayRemove(teamDoc),
				});
		});

		toast.info(`${team.name} ${t('portal.common.deleted')}`);
		if (closeAction) closeAction();
	};

	return (
		<>
			<Helmet>
				<title>
					{team.name} {t('portal.common.delete')}
				</title>
			</Helmet>
			{!frame && <PortalHeader />}
			<Styled.ModalHeading>
				{team.name} {t('portal.common.delete')}
			</Styled.ModalHeading>

			<Styled.Center>
				<form onSubmit={DeleteCurrentTeam}>
					<br />
					{t('portal.delete.teamDescription')}
					<b>{team.name}</b>
					<br />
					<Input
						name="name"
						type="text"
						label={t('portal.delete.teamPlaceholder')}
						placeholder={team.name}
						required
						defaultValue={''}
						onChange={(e) => setDeleteName(e.target.value)}
					/>
					<Styled.ButtonWrapper>
						<div onClick={closeAction}>{t('portal.common.cancel')}</div>
						<Button>{t('portal.common.delete')}</Button>
					</Styled.ButtonWrapper>
				</form>
			</Styled.Center>
			{!frame && <Footer />}
		</>
	);
};

export default DeleteTeam;
