import styled from 'styled-components';

export const ModalHeading = styled.h2`
	font-size: ${({ theme: { fonts } }) => fonts.sizes.big};
	margin: 0 0 20px 0;
	text-align: center;
`;

export const Center = styled.div`
	text-align: center;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 50px 0 30px;

	div {
		cursor: pointer;
		margin-right: 20px;
	}
`;

export const LoaderContainerOverlay = styled.div`
	transition: 0.3s ease all;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	background: #ffffff;
	opacity: 0.7;

	div {
		margin-bottom: 30px;
	}
`;

export const SelectWrapper = styled.div`
	display: inline-block;
	position: relative;
	i {
		pointer-events: none;
		position: absolute;
		top: 8px;
		right: 4px;
		width: 30px;
		height: 36px;
		transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
	}
	&:hover i {
		transition: color 0.3s ease-in-out;
		color: #ffffff;
	}
`;

export const Select = styled.select`
	width: 100%;
	height: 40px;
	cursor: pointer;
	border-radius: 0;
	background-color: transparent;
	border: 2px solid ${({ theme: { colors } }) => colors.blue};
	border-radius: 50px;
	color: ${({ theme: { colors } }) => colors.textColor};
	padding: 0 35px 0 15px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	transition: 0.3s ease-in-out all;

	&::-ms-expand {
		display: none;
	}

	option {
		background-color: #fff;
		color: ${({ theme: { colors } }) => colors.textColor};
	}

	&:hover {
		background-color: ${({ theme: { colors } }) => colors.blue};
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;
