import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';
import type { Props, OwnProps, StateProps, ParamsProps } from './types';

import type { RootState } from 'src/redux';
import { fireApp } from 'src/utils';
import { Header, Footer } from 'src/components';

export const LogIn: React.FC<Props> = ({ isLoggedIn, frame = false, redirectTo }: Props) => {
	const { push } = useHistory();
	const { userEmail } = useParams<ParamsProps>();
	const [emailValue, setEmailValue] = useState(userEmail ? userEmail : '');
	const { t } = useTranslation();

	const handleLogin = useCallback(
		async (event) => {
			event.preventDefault();
			const { email, password } = event.target.elements;
			try {
				await fireApp.auth().signInWithEmailAndPassword(email.value, password.value);
				if (redirectTo) {
					push(redirectTo);
				} else {
					push('/portal');
				}
			} catch (error) {
				toast.error(error.message);
			}
		},
		[redirectTo, push],
	);

	useEffect(() => {
		if (isLoggedIn) push('/portal');
	}, [isLoggedIn, push]);

	return (
		<>
			<Helmet>
				<title>{t('portal.auth.login')}</title>
			</Helmet>
			{!frame && <Header />}
			<Styled.Container frame={frame}>
				<h1>{t('portal.auth.login')}</h1>
				<Styled.Form onSubmit={handleLogin}>
					<Styled.Label>
						{t('portal.common.email')}
						<Styled.Input
							name="email"
							type="email"
							placeholder="john@example.com"
							autoComplete="username"
							value={emailValue}
							onChange={(e) => setEmailValue(e.target.value)}
						/>
					</Styled.Label>
					<Styled.Label>
						{t('portal.auth.password')}
						<Styled.Input name="password" type="password" autoComplete="current-password" />
					</Styled.Label>
					<Styled.Button type="submit">{t('portal.auth.login')}</Styled.Button>
				</Styled.Form>
				<Styled.Small>
					<Link to="/forgot-password">
						<Styled.Link>{t('portal.auth.passwordForgottenTitle')}</Styled.Link>
					</Link>
					<p
						dangerouslySetInnerHTML={{
							__html: t('portal.auth.noAccountYet', { link: t('portal.auth.here') }),
						}}
					></p>
				</Styled.Small>
			</Styled.Container>
			{!frame && <Footer />}
		</>
	);
};

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
	...ownProps,
	isLoggedIn: !state.firebase.auth.isEmpty,
});

export default connect(mapStateToProps)(LogIn);
