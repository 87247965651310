import { CURRENT_CLUB, CURRENT_TEAM, INTRO, LS_CURRENT_CLUB, LS_CURRENT_TEAM, LS_INTRO } from './types';

export const getIntro = (): INTRO => {
	const lsIntro = localStorage.getItem(LS_INTRO);

	if (!lsIntro) return 'UNSEEN';
	else if (lsIntro === 'UNSEEN') return 'UNSEEN';
	else if (lsIntro === 'SEEN') return 'SEEN';
	else {
		localStorage.setItem(LS_INTRO, 'UNSEEN');

		return 'UNSEEN';
	}
};

export const getCurrentClub = (): CURRENT_CLUB => {
	const lsCurrentClub = localStorage.getItem(LS_CURRENT_CLUB);

	if (lsCurrentClub) {
		// TODO: FUTURE: Query if allowed?
		return lsCurrentClub;
	} else return undefined;
};

export const getCurrentTeam = (): CURRENT_TEAM => {
	const lsCurrentTeam = localStorage.getItem(LS_CURRENT_TEAM);

	if (lsCurrentTeam) {
		// TODO: FUTURE: Query if allowed?
		return lsCurrentTeam;
	} else return undefined;
};
