import React from 'react';

import * as Styled from './styles';
import type { Props } from './types';

export const BurgerMenu: React.FC<Props> = ({ open, visible, setOpen }: Props) => {
	return (
		<Styled.IconWrapper onClick={() => setOpen(!open)}>
			<Styled.Icon open={open} visible={visible}></Styled.Icon>
		</Styled.IconWrapper>
	);
};

export default BurgerMenu;
