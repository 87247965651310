import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';
import type { Props, StateProps, FirestoreConnectProps } from './types';

import type { RootState } from 'src/redux';
import { fireStore } from 'src/utils';

export const ClubDropdown: React.FC<Props> = ({ currentClub, clubs }: Props) => {
	const { t } = useTranslation();
	const node = useRef();
	const [dropdownState, setDropdownState] = useState(false);
	let club;

	if (clubs && currentClub) club = clubs.find((club) => club.__id === currentClub)?.name.slice(0, 27);

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);
		return () => document.removeEventListener('mousedown', handleClick);
	}, []);

	const handleClick = (event: Event): void => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (node.current.contains(event.target)) return;

		setDropdownState(false);
	};

	return (
		<Styled.Container ref={node}>
			{clubs && (
				<>
					<Styled.Club onClick={() => setDropdownState(!dropdownState)}>
						{club ? club : t('portal.clubDropdown.choose')}
						<Styled.ChevronDown />
					</Styled.Club>

					<Styled.Dropdown open={dropdownState}>
						{clubs.map((club, index: number) => {
							return (
								<Link key={index} onClick={() => setDropdownState(false)} to={`/portal/club/${club.__id}/teams`}>
									{club.name}
								</Link>
							);
						})}
						<Link to="/portal/create-club">{t('portal.clubDropdown.new')}</Link>
					</Styled.Dropdown>
				</>
			)}
		</Styled.Container>
	);
};

const mapStateToProps = (state: RootState): StateProps => ({
	uid: state.firebase.auth.uid,
	clubs: state.firestore.ordered.clubs,
	currentClub: state.app.currentClub,
});

export default compose(
	connect(mapStateToProps),
	firestoreConnect(({ uid }: FirestoreConnectProps) => [
		{
			collection: 'clubs',
			where: [
				['__deleted', '==', false],
				['_trainers', 'array-contains', fireStore.doc(`users/${uid}`)],
			],
		},
	]),
)(ClubDropdown) as React.ComponentType;
