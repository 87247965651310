import styled from 'styled-components';

import type { StyledProps } from './types';

export const NavBar = styled.nav<StyledProps>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100px;
	padding: 10px 5vw 10px 5vw;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 10;
	transition: 0.3s ease all;

	${({ bg, theme: { colors, layout } }) =>
		bg &&
		`
		height: 80px;
		padding: 0 5vw 0 5vw;
		background: ${colors.background};
		box-shadow: 0 5px 8px rgba(0,0,0, 0.1);
		@media ${layout.device.tabletL} {
			background: transparent;
			box-shadow: none;
		}

		.link a{
			color: ${colors.textColor}!important;

			&:before{
				background-color: ${colors.textColor}!important;
			}
		}
	`}

	.link a {
		color: #fff;
		text-decoration: none;
		display: inline-block;
		position: relative;
		transition: 0.3s all ease-in-out;
		font-size: ${({ theme: { fonts } }) => fonts.sizes.normal};

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			width: 0;
			left: auto;
			right: 0;
			background-color: #fff;
			height: 1px;
			width: 0%;
			transition: 0.3s all ease-in-out;
		}

		&:hover {
			&::before {
				width: 100%;
				right: auto;
				left: 0;
			}
		}
	}

	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		height: 75px;
		${({ open }) =>
			open &&
			`
		background: #fff;
		display: block;
		height: 100%;
	`}
	}
`;

export const Divider = styled.div`
	width: 1px;
	height: 24px;
	opacity: 0.4;
	background-color: ${({ theme: { colors } }) => colors.textColor};
	margin-right: 20px;

	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		display: none;
	}
`;

export const Li = styled.li`
	margin-right: 20px !important;

	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		margin-top: 20px;
		width: 100%;
		display: flex;
		justify-content: center;

		div div div {
			background: transparent;
		}
	}
`;

export const NavList = styled.ul<StyledProps>`
	display: flex;
	justify-content: space-between;
	list-style: none;
	margin-left: auto;
	margin-right: 40px;

	li {
		margin-right: 30px;

		&:last-child {
			margin-right: 0;
		}
	}

	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		${({ open }) =>
			!open &&
			`
		display: none;
	`}

		width: 100%;
		margin: 0;
		padding: 0;
		flex-direction: column;

		li {
			padding: 15px 0;
			margin-right: 0;
			text-align: center;
			a {
				color: ${({ theme: { colors } }) => colors.textColor};
			}
		}
	}
	@media ${({ theme: { layout } }) => layout.device.mobileS} {
		${({ open }) =>
			!open &&
			`
		display: none;
	`}

		width: 100%;
		margin: 0;
		padding: 0;
		flex-direction: column;

		li {
			padding: 10px 0;
			margin-right: 0;
			text-align: center;
			a {
				color: ${({ theme: { colors } }) => colors.textColor};
			}
		}
	}
`;

export const Logo = styled.img<StyledProps>`
	margin: 10px;
	user-select: none;
	-webkit-user-drag: none;
	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		width: 30px;
		display: ${({ open }) => (open ? 'block' : 'none')};
	}
`;
