import React from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ChevronDown } from 'css.gg';

import * as Styled from './styles';
import type { Props } from './types';

import { Input, Button, PortalHeader, Footer } from 'src/components';
import { fireStore } from 'src/utils';
import type { RootState } from 'src/redux';
import { Permission } from 'src/types/permission.d';

export const Create: React.FC<Props> = ({ frame = false, closeAction, currentClub }: Props) => {
	const auth = useSelector((state: RootState) => state.firebase.auth);
	const { t } = useTranslation();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const createNewTeam = (event: any) => {
		event.preventDefault();
		const { name, matchPlayTime, season } = event.target.elements;

		if (!name.value) {
			toast.error(t('portal.errors.emptyName'));
			return;
		}
		if (!season.value) {
			toast.error(t('portal.errors.emptySeason'));
			return;
		}
		if (season.value.length !== 9) {
			toast.error(t('portal.errors.invalidSeason'));
			return;
		}
		// Get years from season string
		const years = season.value.split('-');
		if (years.length !== 2) {
			toast.error(t('portal.errors.invalidSeason'));
			return;
		}
		if (isNaN(Number(years[0])) || isNaN(Number(years[1]))) {
			toast.error(t('portal.errors.invalidYear'));
			return;
		}
		if (Number(years[0]) + 1 !== Number(years[1])) {
			toast.error(t('portal.errors.invalidSeasonLength'));
			return;
		}
		if (Number(years[0]) < new Date().getFullYear() || Number(years[0]) > new Date().getFullYear() + 1) {
			toast.error(t('portal.errors.invalidSeasonPast'));
			return;
		}
		if (!matchPlayTime.value) {
			toast.error(t('portal.errors.emptyPlaytime'));
			return;
		}

		const insertTeam = async () => {
			const newId = fireStore.collection('teams').doc().id;

			if (currentClub) {
				fireStore
					.collection('teams')
					.doc(newId)
					.set({
						__deleted: false,
						__id: newId,
						_club: fireStore.collection('clubs').doc(currentClub),
						name: name.value,
						season: season.value,
						tier: 'FREE',
						matchPlayTime: Number(matchPlayTime.value),
						trainings: [],
						matches: [],
						players: [],
						_permissionsUsers: [
							{
								type: Permission.OWNER,
								user: fireStore.collection('users').doc(auth.uid),
							},
						],
						_users: [fireStore.collection('users').doc(auth.uid)],
					})
					.then(() => {
						toast.info(t('portal.create.teamCreated'));
						if (closeAction) closeAction();
					})
					.catch(toast.error);
			}
		};

		insertTeam();
	};

	return (
		<>
			<Helmet>
				<title>{t('portal.create.team')}</title>
			</Helmet>
			{!frame && <PortalHeader />}

			<Styled.ModalHeading>{t('portal.create.teamFull')}</Styled.ModalHeading>

			<Styled.Center>
				<form onSubmit={createNewTeam}>
					<Input name="name" type="text" label={t('portal.delete.teamPlaceholder')} required />
					<br />
					{t('portal.common.season')}
					<br />
					<Styled.SelectWrapper>
						<Styled.Select
							name="season"
							required
							defaultValue={`${new Date().getFullYear()}-${new Date().getFullYear() + 1}`}
						>
							<option value={`${new Date().getFullYear()}-${new Date().getFullYear() + 1}`}>
								{`${new Date().getFullYear()}-${new Date().getFullYear() + 1}`}
							</option>
							<option value={`${new Date().getFullYear() + 1}-${new Date().getFullYear() + 2}`}>
								{`${new Date().getFullYear() + 1}-${new Date().getFullYear() + 2}`}
							</option>
						</Styled.Select>
						<ChevronDown />
					</Styled.SelectWrapper>
					<br />
					<br />
					{t('portal.common.gamePlaytime')}
					<br />
					<Styled.SelectWrapper>
						<Styled.Select name="matchPlayTime" required defaultValue="90">
							<option value="30">30</option>
							<option value="40">40</option>
							<option value="50">50</option>
							<option value="60">60</option>
							<option value="70">70</option>
							<option value="80">80</option>
							<option value="90">90</option>
						</Styled.Select>
						<ChevronDown />
					</Styled.SelectWrapper>
					<Styled.ButtonWrapper>
						<div onClick={closeAction}>{t('portal.common.cancel')}</div>
						<Button>{t('portal.create.team')}</Button>
					</Styled.ButtonWrapper>
				</form>
			</Styled.Center>

			{!frame && <Footer />}
		</>
	);
};

export default Create;
