import React, { useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ChevronLeft, ChevronRight } from 'css.gg';

import * as Pages from './pages';
import * as Styled from './styles';
import type { Props } from './types';

import * as StyledModal from 'src/components/global/modal/styles';

const PortalIntro: React.FC<Props> = ({ setIntro }: Props) => {
	const pages = 6;
	const [page, setPage] = useState(1);

	const _getPage = (page: number) => {
		switch (page) {
			case 1:
				return <Pages.Start />;
			case 2:
				return <Pages.Clubs />;
			case 3:
				return <Pages.Teams />;
			case 4:
				return <Pages.Trainers />;
			case 5:
				return <Pages.Players />;
			case 6:
				return <Pages.End close={() => setIntro('SEEN')} />;
			default:
				return <Pages.Teams />;
		}
	};

	return (
		<StyledModal.Wrapper
			onMouseDown={(event) => {
				event.target === event.currentTarget && setIntro('SEEN');
			}}
		>
			<StyledModal.Content>
				<StyledModal.CloseButton onClick={() => setIntro('SEEN')} />
				<Styled.IntroWrapper>
					{_getPage(page)}

					{page !== 1 && (
						<Styled.ChevronLeft onClick={() => setPage((page) => (page !== 1 ? page - 1 : page))}>
							<ChevronLeft />
						</Styled.ChevronLeft>
					)}

					{page !== pages && (
						<Styled.ChevronRight onClick={() => setPage((page) => (page !== pages ? page + 1 : page))}>
							<ChevronRight />
						</Styled.ChevronRight>
					)}
					<Styled.Dots>
						<Styled.Dot active={page === 1} onClick={() => setPage(1)}></Styled.Dot>
						<Styled.Dot active={page === 2} onClick={() => setPage(2)}></Styled.Dot>
						<Styled.Dot active={page === 3} onClick={() => setPage(3)}></Styled.Dot>
						<Styled.Dot active={page === 4} onClick={() => setPage(4)}></Styled.Dot>
						<Styled.Dot active={page === 5} onClick={() => setPage(5)}></Styled.Dot>
						<Styled.Dot active={page === 6} onClick={() => setPage(6)}></Styled.Dot>
					</Styled.Dots>
				</Styled.IntroWrapper>
			</StyledModal.Content>
		</StyledModal.Wrapper>
	);
};
export default PortalIntro;
