import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from 'src/components/portal/portal-intro/styles';

export const Teams: React.FC = () => {
	const { t } = useTranslation();

	return (
		<>
			<Styled.H1>{t('portal.intro.teams.title')}</Styled.H1>
			<Styled.Text
				dangerouslySetInnerHTML={{ __html: t('portal.intro.teams.intro', { interpolation: { escapeValue: false } }) }}
			></Styled.Text>
		</>
	);
};

export default Teams;
