import styled from 'styled-components';

import { theme } from 'src/styles';

export const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 11;
`;

export const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: calc(100vh - ${theme.layout.footer.height}px);
	background: ${theme.colors.background};
	opacity: 0.7;
`;

export const Container = styled.div`
	width: 100vw;
	height: calc(100vh - ${theme.layout.footer.height}px);
	display: flex;
	justify-content: center;
	align-items: center;
`;
