import React, { useState } from 'react';
import { default as firebase } from 'firebase/app';
import { ImpulseSpinner } from 'react-spinners-kit';
import { toast } from 'react-toastify';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ChevronDown } from 'css.gg';

import * as Styled from './styles';
import type { Props, StateProps, OwnProps, FirestoreConnectProps } from './types';

import type { RootState } from 'src/redux';
import { colors } from 'src/styles';
import { Input, Button, PortalHeader, Footer } from 'src/components';
import { fireFunctions, fireStore } from 'src/utils';
import type { PermissionUser, Team, User } from 'src/types';
import { Permission } from 'src/types/permission.d';

export const CreateGeneral: React.FC<Props> = ({ closeAction, frame = false, teams, clubID }: Props) => {
	const [updating, setUpdating] = useState(false);
	const { t } = useTranslation();

	const newTrainer = async (event: React.SyntheticEvent) => {
		event.preventDefault();
		setUpdating(true);
		const target = event.target as typeof event.target & {
			name: { value: string };
			type: { value: Permission };
			team: { value: string };
			email: { value: string };
		};

		console.log('sssszzzzzzzzzzzzzzzzzzzzzzzzzz',target );

		const name = target.name.value;
		const type = target.type.value;
		const teamID = target.team.value;
		const email = target.email.value.toLowerCase();

		const userQuery = await fireStore.collection('users').where('email', '==', email).limit(1).get();

		if (!userQuery.empty) {
			const userDoc = userQuery.docs[0];
			const userRef = userDoc.ref;
			const user: User = { ...userDoc, uid: userDoc.id };
			const team: Team = (await fireStore.doc(`teams/${teamID}`).get()).data() as Team;
			const isAlreadyInTeam = team._permissionsUsers.find(
				(_permissionUser: PermissionUser) => _permissionUser.user.id === user.uid,
			);

			if (isAlreadyInTeam) {
				toast.error(`${user.displayName || t('portal.common.user')} ${t('portal.errors.alreadyInTeam')}`);
				setUpdating(false);
			} else {
				await _setUserAccess({ userRef, teamID, type });

				toast.info(`${user.displayName || t('portal.common.user')} ${t('portal.create.added')}`);
				setUpdating(false);
				if (closeAction) closeAction();
			}
		} else
			fireFunctions
				.httpsCallable('createUser')({
					email,
					displayName: name,
					teamID,
					clubID,
					type,
				})
				.then(
					async ({
						data: {
							data: { user },
						},
					}: {
						data: { data: { user: User } };
					}) => {
						await _setUserAccess({ userRef: fireStore.doc(`users/${user.uid}`), teamID, type });

						toast.info(t('portal.extra.newTrainerFromAdmin', { displayName: user?.displayName }));
						setUpdating(false);
						if (closeAction) closeAction();
					},
				)
				.catch((error) => {
					console.error(error);
					toast.error(error);
				});
	};

	const _setUserAccess = async ({
		userRef,
		teamID,
		type,
	}: {
		userRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
		teamID: string;
		type: Permission;
	}) => {
		const teamReference = fireStore.doc(`teams/${teamID}`);
		const teamDoc = await teamReference.get();

		const newDoc = {
			...teamDoc.data(),
			_permissionsUsers: [...teamDoc?.data()?._permissionsUsers, { type, user: userRef }],
			_users: [...teamDoc?.data()?._users, userRef],
		};

		await teamReference.update(newDoc);

		await fireStore.doc(`clubs/${clubID}`).update({
			_trainers: firebase.firestore.FieldValue.arrayUnion(userRef),
		});
	};

	return (
		<>
			<Helmet>
				<title>{t('portal.create.trainer')}</title>
			</Helmet>

			{!frame && <PortalHeader />}
			<Styled.ModalHeading>{t('portal.create.trainer')}</Styled.ModalHeading>
			<Styled.Center>
				<p>{t('portal.create.trainerText')}</p>
				<form onSubmit={newTrainer}>
					<Input name="name" type="text" label={`${t('portal.common.trainer')} ${t('portal.common.name')}`} required />
					<Input
						name="email"
						type="email"
						label={`${t('portal.common.trainer')} ${t('portal.common.email')}`}
						required
					/>
					<br />
					{teams && teams.length > 0 && (
						<>
							{t('portal.common.team')}
							<br />
							<Styled.SelectWrapper>
								<Styled.Select name="team">
									{teams.map((_team) => (
										<option key={_team.__id} value={_team.__id}>
											{_team.name}
										</option>
									))}
								</Styled.Select>
								<ChevronDown />
							</Styled.SelectWrapper>
						</>
					)}
					<br />
					<br />
					{t('portal.rights.title')}
					<br />
					<Styled.SelectWrapper>
						<Styled.Select name="type">
							<option value="MANAGE">{t('portal.rights.portalApp')}</option>
							<option value="WRITE">{t('portal.rights.app')}</option>
							<option value="READ">{t('portal.rights.appRead')}</option>
						</Styled.Select>
						<ChevronDown />
					</Styled.SelectWrapper>
					<br />
					<Styled.ButtonWrapper>
						<div onClick={closeAction}>{t('portal.common.cancel')}</div>
						<Button>{t('portal.create.trainer')}</Button>
					</Styled.ButtonWrapper>
				</form>
			</Styled.Center>
			{updating && (
				<Styled.LoaderContainerOverlay>
					<ImpulseSpinner size={80} frontColor={colors.blue} backColor={colors.grey} />
				</Styled.LoaderContainerOverlay>
			)}
			{!frame && <Footer />}
		</>
	);
};

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
	...props,
	teams: state.firestore.ordered.teams,
});

export default compose(
	connect(mapStateToProps),
	firestoreConnect(({ clubID }: FirestoreConnectProps) => [
		{
			collection: 'teams',
			storeAs: 'teams',
			where: [
				['__deleted', '==', false],
				['_club', '==', fireStore.doc(`clubs/${clubID}`)],
				['tier', '==', 'PREMIUM'],
			],
		},
	]),
)(CreateGeneral) as React.ComponentType<Props>;
