import styled from 'styled-components';

export const Wrapper = styled.div`
	position: relative;
`;

export const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Message = styled.div`
	background: ${({ theme: { colors } }) => colors.blue};
	color: ${({ theme: { colors } }) => colors.background};
	padding: 20px;
	display: inline-block;
	border-radius: 20px;
	margin-bottom: 20px;
`;
