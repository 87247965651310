import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';
import type { OwnProps, StateProps, Props } from './types';

import type { RootState } from 'src/redux';
import { fireApp } from 'src/utils';
import { Header, Footer } from 'src/components';

export const ForgotPassword: React.FC<Props> = ({ isLoggedIn, frame = false }: Props) => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const handleReset = useCallback(
		async (event) => {
			event.preventDefault();
			const { email } = event.target.elements;
			try {
				await fireApp.auth().sendPasswordResetEmail(email.value);
				toast.info(t('portal.auth.passwordForgotten') + ` ${email.value}`);
				push('/login');
			} catch (error) {
				if (
					error.toString() ===
					'Error: There is no user record corresponding to this identifier. The user may have been deleted.'
				) {
					toast.error(t('portal.auth.userNotFound'));
				} else {
					toast.error(error.toString());
				}
			}
		},
		[push],
	);

	useEffect(() => {
		if (isLoggedIn) push('/portal');
	}, [isLoggedIn, push]);

	return (
		<>
			<Helmet>
				<title>{t('portal.auth.passwordForgottenTitle')}</title>
			</Helmet>
			{!frame && <Header />}
			<Styled.Container>
				<h1>{t('portal.auth.passwordForgottenTitle')}</h1>
				<Styled.Form onSubmit={handleReset}>
					<Styled.Label>
						{t('portal.common.email')}
						<Styled.Input name="email" type="email" placeholder="john@example.com" required />
					</Styled.Label>
					<Styled.Button type="submit">{t('portal.auth.requestPassword')}</Styled.Button>
				</Styled.Form>

				<Styled.Small>
					<p
						dangerouslySetInnerHTML={{
							__html: t('portal.auth.noAccountYet', { link: t('portal.auth.here') }),
						}}
					></p>
				</Styled.Small>
			</Styled.Container>
			{!frame && <Footer />}
		</>
	);
};

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
	...ownProps,
	isLoggedIn: !state.firebase.auth.isEmpty,
});

export default connect(mapStateToProps)(ForgotPassword);
