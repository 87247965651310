import React from 'react';

import * as Styled from './styles';
import type { Props } from './types';

export const Button: React.FC<Props> = ({
	action,
	children,
	marginBottom = '0px',
	small = false,
	color = 'blue',
	...props
}: Props) => {
	return (
		<Styled.Button small={small} marginBottom={marginBottom} onClick={action} color={color} {...props}>
			{children}
		</Styled.Button>
	);
};

export default Button;
