import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from 'src/components/portal/portal-intro/styles';

export const Clubs: React.FC = () => {
	const { t } = useTranslation();

	return (
		<>
			<Styled.H1>{t('portal.intro.clubs.title')}</Styled.H1>
			<Styled.Text>{t('portal.intro.clubs.intro')}</Styled.Text>
		</>
	);
};

export default Clubs;
