import styled from 'styled-components';

import { downloadBG } from 'src/assets';

export const Container = styled.section`
	background: linear-gradient(180deg, rgba(48, 99, 154, 1) 0%, rgba(48, 99, 154, 0.8) 100%);
	background-image: url(${downloadBG});
	background-image: linear-gradient(180deg, rgba(48, 99, 154, 1) 0%, rgba(48, 99, 154, 0.8) 100%), url(${downloadBG});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 70vh;
	width: 100%;
	display: flex;
	color: white;
	padding-right: 7%;
	flex-direction: row-reverse;
	align-items: center;
	position: relative;
	padding-top: 0;

	@media only screen and (max-width: ${({ theme: { layout } }) => layout.screenSizes.medium}) {
		padding: 5%;
		height: auto;
	}
`;

export const PhonesContainer = styled.div`
	z-index: 1;
	position: absolute;
	width: 100%;
	height: 126%;
	top: -13%;
	left: 0;
	overflow: hidden;
	user-select: none;
	-webkit-user-drag: none;
	pointer-events: none;

	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		display: none;
	}
`;

export const Phones = styled.div`
	z-index: 1;
	width: 100%;
`;

export const Phone = styled.img`
	width: auto;
	position: absolute;
	right: 100px;
	min-width: 100%;
	min-height: 100%;

	@media ${({ theme: { layout } }) => layout.device.laptopL} {
		right: 0;
	}
`;

export const FeatureInfo = styled.div`
	max-width: 35%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: ${({ theme: { fonts } }) => fonts.sizes.normal};

	@media ${({ theme: { layout } }) => layout.device.laptopM} {
		max-width: 40%;
	}

	@media only screen and (max-width: 1120px) {
		max-width: 45%;
	}

	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		max-width: 100%;
		align-items: flex-start;
		padding: 0 30px;
	}
`;

export const FeatureHeading = styled.h1`
	font-size: ${({ theme: { fonts } }) => fonts.sizes.huge};
	font-weight: ${({ theme: { fonts } }) => fonts.weights.bolder};
	margin-bottom: 10px;
	margin-top: 0;
`;

export const DownloadImages = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 100%;
	margin-top: 40px;
	z-index: 1;

	@media only screen and (max-width: ${({ theme: { layout } }) => layout.screenSizes.medium}) {
		justify-content: center;
	}

	> a {
		margin: 0;
		padding: 0;
		display: inline-flex;
		height: 100%;
	}

	> a:first-of-type {
		margin-right: 10px;
	}
`;

export const DownloadImage = styled.img`
	height: auto;
	align-self: center;
	max-height: 50px;

	@media only screen and (max-width: ${({ theme: { layout } }) => layout.screenSizes.small}) {
		max-width: 135px;
	}
`;

export const CircleContainer = styled.div`
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 0;
	width: 375px;
	height: 375px;
	overflow: hidden;
`;

export const Circle = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	background: transparent;
	width: 750px;
	height: 750px;
	border-radius: 500px;
	border: 150px solid rgba(255, 255, 255, 0.05);
`;
