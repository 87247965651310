import styled from 'styled-components';

import type { StyledProps } from './types';

export const Container = styled.footer<StyledProps>`
	box-sizing: border-box;
	height: ${({ background, theme: { layout } }) =>
		background ? `${layout.footer.height + 5}px` : `${layout.footer.height}px`};
	position: relative;
	display: flex;
	justify-content: center;
	padding: 30px;
	z-index: 1;
	background-color: ${({ background }) => (background ? '#ffffff' : 'transparent')};

	a {
		margin-right: 25px;
		color: ${({ theme: { colors } }) => colors.textColor};
	}

	@media only screen and (max-width: ${({ theme: { layout } }) => layout.screenSizes.medium}) {
		padding: 20px;
	}
`;
