import React, { useState } from 'react';
import { default as firebase } from 'firebase/app';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ChevronDown } from 'css.gg';

import * as Styled from './styles';
import type { Props } from './types';

import { Input, Checkbox, Button, PortalHeader, Footer } from 'src/components';
import { fireStore } from 'src/utils';
import type { Player } from 'src/types';

const Edit: React.FC<Props> = ({ frame = false, closeAction, player, teams }: Props) => {
	const [firstName, setFirstName] = useState(player.firstName);
	const [middleName, setMiddleName] = useState(player.middleName);
	const [lastName, setLastName] = useState(player.lastName);

	const birthYear = player.birthDate && player.birthDate.toDate().getFullYear();
	const birthMonth = player.birthDate && player.birthDate.toDate().getMonth();
	const birthDay = player.birthDate && player.birthDate.toDate().getDate();

	const [birthDate, setBirthDate] = useState(
		player.birthDate
			? String(
					`${birthYear}-${birthMonth < 9 ? '0' + birthMonth : birthMonth}-${birthDay < 9 ? '0' + birthDay : birthDay}`,
			  )
			: '',
	);
	const [backNumber, setBackNumber] = useState(player.backNumber);
	const [knvbNumber, setKnvbNumber] = useState(player.knvbNumber);
	const [size, setSize] = useState(player.size);
	const [bio, setBio] = useState(player.bio);
	const { t } = useTranslation();

	// Player position
	const [defence, setDefence] = useState(
		player.positions && player.positions.find((string) => string === 'DEFENCE') ? true : false,
	);
	const [attack, setAttack] = useState(
		player.positions && player.positions.find((string) => string === 'ATTACK') ? true : false,
	);
	const [keeper, setKeeper] = useState(
		player.positions && player.positions.find((string) => string === 'KEEPER') ? true : false,
	);
	const [middleGround, setMiddleGround] = useState(
		player.positions && player.positions.find((string) => string === 'MIDDLEGROUND') ? true : false,
	);
	// leg preference
	const [left, setLeft] = useState(
		player.legPreference && player.legPreference.find((string) => string === 'LEFT') ? true : false,
	);
	const [right, setRight] = useState(
		player.legPreference && player.legPreference.find((string) => string === 'RIGHT') ? true : false,
	);
	const [guest, setGuest] = useState(player._guestTeams.length > 0 ? true : false);
	// Team
	const [teamID, setTeamId] = useState<string | null>(
		player._guestTeams.length > 0
			? player._guestTeams[0].id
			: player._baseTeams.length > 0
			? player._baseTeams[0].id
			: null,
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const EditCurrentPlayer = (event: any) => {
		event.preventDefault();

		if (!firstName) {
			toast.error(t('portal.errors.emptyFirstName'));
			return;
		}
		if (!lastName) {
			toast.error(t('portal.errors.emptyLastName'));
			return;
		}
		if (knvbNumber && knvbNumber?.length > 7) {
			toast.error(t('portal.errors.KNVBTooLong'));
			return;
		}

		const updatePlayer = async () => {
			const positions: Array<'MIDDLEGROUND' | 'ATTACK' | 'DEFENCE' | 'KEEPER'> = [];
			if (middleGround) positions.push('MIDDLEGROUND');
			if (attack) positions.push('ATTACK');
			if (defence) positions.push('DEFENCE');
			if (keeper) positions.push('KEEPER');

			const legPreference: Array<'LEFT' | 'RIGHT'> = [];
			if (left) legPreference.push('LEFT');
			if (right) legPreference.push('RIGHT');

			let playerDoc: Player = {
				...player,
				firstName: firstName,
				middleName: middleName ? middleName : null,
				lastName: lastName,
				birthDate: birthDate ? firebase.firestore.Timestamp.fromDate(new Date(birthDate)) : null,
				backNumber: backNumber ? Number(backNumber) : null,
				knvbNumber: knvbNumber ? String(knvbNumber) : null,
				size: size ? Number(size) : null,
				positions,
				legPreference,
				bio: bio || null,
			};

			if (teamID)
				if (guest)
					playerDoc = {
						...playerDoc,
						_guestTeams: [fireStore.collection('teams').doc(teamID)],
						_baseTeams: [],
					};
				else
					playerDoc = {
						...playerDoc,
						_guestTeams: [],
						_baseTeams: [fireStore.collection('teams').doc(teamID)],
					};
			else
				playerDoc = {
					...playerDoc,
					_guestTeams: [],
					_baseTeams: [],
				};
			await fireStore.collection('players').doc(player.__id).update(playerDoc);

			// Remove from old teams
			const teams = await fireStore
				.collection('teams')
				.where('players', 'array-contains', fireStore.collection('players').doc(player.__id))
				.get();

			teams.forEach((team) => {
				fireStore
					.collection('teams')
					.doc(team.id)
					.update({
						players: firebase.firestore.FieldValue.arrayRemove(fireStore.collection('players').doc(player.__id)),
					});
			});
			if (teamID) {
				// Add to new teams
				await fireStore
					.collection('teams')
					.doc(teamID)
					.update({
						players: firebase.firestore.FieldValue.arrayUnion(fireStore.collection('players').doc(player.__id)),
					});
			}

			toast.info(`${player.firstName} ${t('portal.common.edited')}`);
			if (closeAction) closeAction();
		};
		updatePlayer();
	};

	return (
		<>
			<Helmet>
				<title>{t('portal.edit.player')}</title>
			</Helmet>
			{!frame && <PortalHeader />}
			<Styled.ModalHeading>{t('portal.edit.player')}</Styled.ModalHeading>

			<Styled.Center>
				<form onSubmit={EditCurrentPlayer}>
					<Styled.ButtonWrapper>
						<Input
							name="firstName"
							type="text"
							label={t('portal.common.firstName')}
							required
							defaultValue={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
						<Input
							name="middleName"
							type="text"
							label={t('portal.common.insertion')}
							defaultValue={middleName ? middleName : ''}
							onChange={(e) => setMiddleName(e.target.value)}
						/>
						<Input
							name="lastName"
							type="text"
							label={t('portal.common.lastName')}
							required
							defaultValue={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>
					</Styled.ButtonWrapper>
					<Input
						name="birthDate"
						type="date"
						label={t('portal.common.birthday')}
						defaultValue={birthDate}
						onChange={(e) => setBirthDate(e.target.value)}
					/>
					<Input
						name="backNumber"
						type="number"
						label={t('portal.common.backnumber')}
						defaultValue={backNumber ? backNumber : ''}
						onChange={(e) => setBackNumber(Number(e.target.value))}
					/>
					<Input
						name="knvbNumber"
						type="text"
						label={t('portal.common.KNVB')}
						defaultValue={knvbNumber ? knvbNumber : ''}
						onChange={(e) => setKnvbNumber(e.target.value.toUpperCase())}
						minLength={7}
						maxLength={7}
					/>
					<Input
						name="size"
						type="number"
						label={t('portal.common.length')}
						defaultValue={size ? size : ''}
						onChange={(e) => setSize(Number(e.target.value))}
					/>
					<br />
					<b>
						<label>{t('portal.common.position')}</label>
					</b>
					<br />
					<Checkbox
						name="attack"
						label={t('portal.common.attack')}
						checked={attack}
						onChange={(e) => setAttack(e.target.checked)}
					/>
					<Checkbox
						name="middleGround"
						label={t('portal.common.middleGround')}
						checked={middleGround}
						onChange={(e) => setMiddleGround(e.target.checked)}
					/>
					<Checkbox
						name="defence"
						label={t('portal.common.defence')}
						checked={defence}
						onChange={(e) => setDefence(e.target.checked)}
					/>
					<Checkbox
						name="keeper"
						label={t('portal.common.keeper')}
						checked={keeper}
						onChange={(e) => setKeeper(e.target.checked)}
					/>
					<br />
					<b>
						<label>{t('portal.common.leftOrRight')}</label> <br />
					</b>
					<Checkbox
						name="left"
						label={t('portal.common.left')}
						checked={left}
						onChange={(e) => setLeft(e.target.checked)}
					/>
					<Checkbox
						name="right"
						label={t('portal.common.right')}
						checked={right}
						onChange={(e) => setRight(e.target.checked)}
					/>
					<br />
					<Styled.SelectWrapper>
						<Styled.Select
							name="team"
							onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setTeamId(e.target.value)}
						>
							<option value="">{t('portal.empty.team')}</option>
							{teams !== undefined &&
								teams.map((team) => {
									if (team.__id === teamID)
										return (
											<option key={team.__id} value={team.__id} selected>
												{team.name}
											</option>
										);
									else
										return (
											<option key={team.__id} value={team.__id}>
												{team.name}
											</option>
										);
								})}
						</Styled.Select>
						<ChevronDown />
					</Styled.SelectWrapper>
					<br />
					<br />
					<Checkbox
						name="guest"
						label={t('portal.common.guestPlayer')}
						checked={guest}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGuest(e.target.checked)}
					/>
					<Input
						name="bio"
						type="text"
						label={t('portal.common.remarks')}
						defaultValue={bio ? bio : ''}
						onChange={(e) => setBio(e.target.value)}
					/>
					<Styled.ButtonWrapper>
						<div onClick={closeAction}>{t('portal.common.cancel')}</div>
						<Button>{t('portal.edit.player')}</Button>
					</Styled.ButtonWrapper>
				</form>
			</Styled.Center>
			{!frame && <Footer />}
		</>
	);
};

export default Edit;
