const remoteConfigDefaults = {
	WEB_PRICE_PER_TEAM: 4.99,
	IAP_PRICE_MONTH_1: 4.99,
	IAP_PRICE_MONTH_3: 13.99,
	IAP_PRICE_MONTH_6: 26.99,
	IAP_PRICE_MONTH_9: 36.99,
	IAP_PRICE_MONTH_12: 44.99,
	MAXIMUM_FREE_TEAMS: 1,
	UPCOMING_SEASON_YEARS_TO_DISPLAY: 2,
	START_OF_SEASON: '01-07',
};

export default {
	defaults: remoteConfigDefaults,
};
