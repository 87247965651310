export const LS_INTRO = `@APP/INTRO`;
export const LS_CURRENT_CLUB = `@APP/CURRENT_CLUB`;
export const LS_CURRENT_TEAM = `@APP/CURRENT_TEAM`;

export const INTRO_CHANGED = `@APP/INTRO_CHANGED`;
export const CURRENT_CLUB_CHANGED = `@APP/CURRENT_CLUB_CHANGED`;
export const CURRENT_TEAM_CHANGED = `@APP/CURRENT_TEAM_CHANGED`;

export type INTRO = 'SEEN' | 'UNSEEN';
export type CURRENT_CLUB = string | undefined;
export type CURRENT_TEAM = string | undefined;

export interface AppState {
	intro: INTRO;
	currentClub: CURRENT_CLUB;
	currentTeam: CURRENT_TEAM;
}

export interface ChangeIntroAction {
	type: typeof INTRO_CHANGED;
	intro: INTRO;
}

export interface ChangeCurrentClubAction {
	type: typeof CURRENT_CLUB_CHANGED;
	club: CURRENT_CLUB;
}

export interface ChangeCurrentTeamAction {
	type: typeof CURRENT_TEAM_CHANGED;
	team: CURRENT_TEAM;
}

export type AppActionsTypes = ChangeIntroAction | ChangeCurrentClubAction | ChangeCurrentTeamAction;
