/* eslint-disable import/no-duplicates */
import { default as firebase } from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/remote-config';
import 'firebase/analytics';

import { config } from 'src/config';

export const fireApp = firebase.initializeApp(config.firebase);
export const fireAuth = fireApp.auth();
export const fireFunctions = fireApp.functions(config.firebaseFunctions.region);
export const fireStore = fireApp.firestore();
export const fireRemoteConfig = fireApp.remoteConfig();

fireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// If you want, you can use the emulator. Uncomment the following if statement.
//if (config.environment === 'development') {
	// fireAuth.useEmulator('http://localhost:9099');
	// fireStore.useEmulator('localhost', 8080);
	// fireFunctions.useEmulator('localhost', 5001);
//}

firebase.analytics();

fireStore.enablePersistence({
	synchronizeTabs: true,
});

fireRemoteConfig.settings = {
	minimumFetchIntervalMillis: 43200000,
	fetchTimeoutMillis: 60000,
};
fireRemoteConfig.defaultConfig = config.firebaseRemoteConfig.defaults;
