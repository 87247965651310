import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ChevronDown } from 'css.gg';

import * as Styled from './styles';
import type { Props } from './types';

import { Input, Button, PortalHeader, Footer } from 'src/components';
import { fireStore } from 'src/utils';

export const EditTeam: React.FC<Props> = ({ frame = false, closeAction, team }: Props) => {
	const [name, setName] = useState(team.name);
	const [season, setSeason] = useState(team.season);
	const [matchPlayTime, setMatchPlayTime] = useState<number>(team.matchPlayTime);
	const years = season.split('-');
	const previousSeason = Number(years[0]) < new Date().getFullYear() - 1;
	const { t } = useTranslation();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const EditCurrentTeam = (event: any) => {
		event.preventDefault();
		if (!name) {
			toast.error(t('portal.errors.emptyName'));
			return;
		}
		if (!season) {
			toast.error(t('portal.errors.emptySeason'));
			return;
		}
		if (season.length !== 9) {
			toast.error(t('portal.errors.invalidSeason'));
			return;
		}
		// Get years from season string
		const years = season.split('-');
		if (years.length !== 2) {
			toast.error(t('portal.errors.invalidSeason'));
			return;
		}
		if (isNaN(Number(years[0])) || isNaN(Number(years[1]))) {
			toast.error(t('portal.errors.invalidYear'));
			return;
		}
		if (Number(years[0]) + 1 !== Number(years[1])) {
			toast.error(t('portal.errors.invalidSeasonLength'));
			return;
		}
		if (!matchPlayTime) {
			toast.error(t('portal.errors.emptyPlaytime'));
			return;
		}

		async function updateTeam() {
			await fireStore
				.collection('teams')
				.doc(team.__id)
				.update({
					name: name,
					season: season,
					matchPlayTime: Number(matchPlayTime),
				})
				.then(() => {
					toast.info(`${team.name} ${t('portal.common.edited')}`);
					if (closeAction) closeAction();
				})
				.catch(toast.error);
		}

		updateTeam();
	};

	return (
		<>
			<Helmet>
				<title>
					{team.name} {t('portal.common.edit')}
				</title>
			</Helmet>
			{!frame && <PortalHeader />}
			<Styled.ModalHeading>
				{team.name} {t('portal.common.edit')}
			</Styled.ModalHeading>

			<Styled.Center>
				<form onSubmit={EditCurrentTeam}>
					<Input
						name="name"
						type="text"
						label={t('portal.delete.teamPlaceholder')}
						required
						defaultValue={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<br />
					{t('portal.common.season')}
					<br />
					<Styled.SelectWrapper>
						<Styled.Select name="season" required defaultValue={season} onChange={(e) => setSeason(e.target.value)}>
							{previousSeason && <option value={season}>{season}</option>}
							<option value={`${new Date().getFullYear()}-${new Date().getFullYear() + 1}`}>
								{`${new Date().getFullYear()}-${new Date().getFullYear() + 1}`}
							</option>
							<option value={`${new Date().getFullYear() + 1}-${new Date().getFullYear() + 2}`}>
								{`${new Date().getFullYear() + 1}-${new Date().getFullYear() + 2}`}
							</option>
						</Styled.Select>
						<ChevronDown />
					</Styled.SelectWrapper>
					<br />
					<br />
					{t('portal.common.gamePlaytime')}
					<br />
					<Styled.SelectWrapper>
						<Styled.Select
							name="matchPlayTime"
							required
							defaultValue={matchPlayTime}
							onChange={(e) => setMatchPlayTime(Number(e.target.value))}
						>
							<option value="30">30</option>
							<option value="40">40</option>
							<option value="50">50</option>
							<option value="60">60</option>
							<option value="70">70</option>
							<option value="80">80</option>
							<option value="90">90</option>
						</Styled.Select>
						<ChevronDown />
					</Styled.SelectWrapper>
					<Styled.ButtonWrapper>
						<div onClick={closeAction}>{t('portal.common.cancel')}</div>
						<Button>{t('portal.common.save')}</Button>
					</Styled.ButtonWrapper>
				</form>
			</Styled.Center>
			{!frame && <Footer />}
		</>
	);
};

export default EditTeam;
