import React from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

import * as Styled from './styles';

import { headerImage } from 'src/assets';
import { Button } from 'src/components';

export const Landing: React.FC = () => {
	const { t } = useTranslation();

	return (
		<Styled.Container id="home">
			<div className="aos-init" data-aos="animate" data-aos-offset="100" data-aos-once="true">
				<Styled.Header>{t('website.landing.title')}</Styled.Header>
				<Styled.SubText>{t('website.landing.intro')}</Styled.SubText>
				<Button color="orange">
					<HashLink to="/#download">{t('website.landing.button')}</HashLink>
				</Button>
			</div>
			<Styled.PhonesContainer>
				<Styled.Phones>
					<Styled.Phone
						src={headerImage}
						alt="Telefoon"
						className="aos-init"
						data-aos="animate"
						data-aos-offset="100"
						data-aos-delay="100"
						data-aos-once="true"
					/>
				</Styled.Phones>
			</Styled.PhonesContainer>
		</Styled.Container>
	);
};

export default Landing;
