import { isSmallScreen } from './layout';

export const sizes = {
	normal: '1rem',
	medium: isSmallScreen ? '1.3rem' : '1.3rem',
	big: isSmallScreen ? '1.6rem' : '2.3rem',
	huge: isSmallScreen ? '2rem' : '2.8rem',
};

export const weights = {
	normal: 400,
	bold: 600,
	bolder: 800,
};

export default { weights, sizes };
