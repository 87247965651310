import styled from 'styled-components';

import type { StyledContainerProps, StyledDropdownProps } from './types';

export const Container = styled.div<StyledContainerProps>`
	color: ${({ theme: { colors } }) => colors.textColor};
	position: relative;
	display: flex;
`;

export const CurrentLanguage = styled.div`
	display: flex;
	cursor: pointer;

	@media ${({ theme: { layout } }) => layout.device.laptopM} {
		line-height: 24px;
	}
`;

export const Dropdown = styled.div<StyledDropdownProps>`
	display: ${({ open }) => (open ? 'block' : 'none')};
	position: absolute;
	left: -15px;
	z-index: 2;
	top: 50px;
	min-width: 60px;
	width: 100%;
	background: ${({ theme: { colors } }) => colors.background};
	box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);

	div,
	a {
		display: block;
		cursor: pointer;
		padding: 20px 20px;
		transition: 0.3s ease all;
		color: ${({ theme: { colors } }) => colors.textColor};
		text-decoration: none;

		&:hover {
			background: ${({ theme: { colors } }) => colors.grey};
		}
	}
`;

export const ChevronDown = styled.i`
	& {
		box-sizing: border-box;
		position: relative;
		display: block;
		transform: scale(var(--ggs, 0.8));
		width: 22px;
		height: 22px;
		border: 2px solid transparent;
		border-radius: 100px;
	}
	&::after {
		content: '';
		display: block;
		box-sizing: border-box;
		position: absolute;
		width: 10px;
		height: 10px;
		border-bottom: 2px solid;
		border-right: 2px solid;
		transform: rotate(45deg);
		left: 4px;
		top: 2px;
	}
`;

export const Divider = styled.div`
	background-color: rgb(35, 38, 39);
	width: 1px;
	height: 24px;
	opacity: 0.4;
	background-color: #293134;
	margin-left: 10px;

	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		display: none;
	}
`;
