import styled from 'styled-components';

export const Container = styled.div`
	height: 49px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	border-radius: 50px;
	background-color: ${({ theme: { colors } }) => colors.blue};
`;

export const Min = styled.div`
	color: #fff;
	padding: 12px;
	border-radius: 50px 0 0 50px;
	transition: 0.3s ease all;

	&:hover {
		background-color: ${({ theme: { colors } }) => colors.lightBlue};
		color: #fafafa;
		cursor: pointer;
	}
`;

export const Plus = styled.div`
	color: #fff;
	padding: 12px;
	border-radius: 0 50px 50px 0;
	transition: 0.3s ease all;

	&:hover {
		background-color: ${({ theme: { colors } }) => colors.lightBlue};
		color: #fafafa;
		cursor: pointer;
	}
`;

export const Input = styled.input`
	width: 30px;
	height: 40px;
	background-color: transparent;
	border: none;
	color: #fff;
	text-align: center;

	-moz-appearance: textfield;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;
