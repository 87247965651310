import React, { useState } from 'react';
import { default as firebase } from 'firebase/app';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import * as Styled from './styles';
import type { FirestoreConnectProps, Props, OwnProps, StateProps } from './types';

import { Link, Button, PortalHeader, Footer } from 'src/components';
import { fireStore } from 'src/utils';
import type { RootState } from 'src/redux';
import { Player, Team } from 'src/types';
import { table } from 'src/styles';

export const Select: React.FC<Props> = ({
	frame = false,
	closeAction,
	players,
	teams,
	club,
	teamName,
	originalPlayers,
	currentTeam,
}: Props) => {
	const [selectedRows, setSelectedRows] = useState<Array<Player>>([]);
	const { t } = useTranslation();

	const savePlayers = () => {
		const batch = fireStore.batch();

		if (currentTeam) {
			const rowsToAdd = selectedRows.filter(
				(_player: Player) => _player && _player._baseTeams && _player._baseTeams[0]?.__id !== currentTeam,
			);

			const rowsToRemove: Array<Player> = [];

			originalPlayers.forEach((_player: Player) => {
				if (!selectedRows.find((_sPlayer) => _sPlayer.__id === _player.__id)) rowsToRemove.push(_player);
			});

			rowsToAdd.forEach((_player) => {
				batch.update(fireStore.collection('players').doc(_player.__id), {
					_baseTeams: [fireStore.collection('teams').doc(currentTeam)],
				});

				batch.update(fireStore.collection('teams').doc(currentTeam), {
					players: firebase.firestore.FieldValue.arrayUnion(fireStore.collection('players').doc(_player.__id)),
				});
			});

			rowsToRemove.forEach((_player: Player) => {
				batch.update(fireStore.collection('players').doc(_player.__id), {
					_baseTeams: [],
				});

				batch.update(fireStore.collection('teams').doc(currentTeam), {
					players: firebase.firestore.FieldValue.arrayRemove(fireStore.collection('players').doc(_player.__id)),
				});
			});

			// upload to fireStore
			batch
				.commit()
				.then(() => {
					toast.info(t('portal.edit.editedPlayer'));
					if (closeAction) closeAction();
				})
				.catch((error) => {
					toast.error(error);
					if (closeAction) closeAction();
				});
		}

		if (closeAction) closeAction();
	};

	const columns = [
		{
			name: t('portal.common.firstName'),
			selector: 'firstName',
			sortable: true,
		},
		{
			name: t('portal.common.lastName'),
			selector: 'lastName',
			sortable: true,
		},
		{
			name: t('portal.common.team'),
			sortable: true,
			cell: (player: Player) =>
				teams &&
				(player && player._guestTeams && player._guestTeams.length > 0
					? player._guestTeams[0] &&
					  teams.find((_team: Team) => _team.__id === player._guestTeams[0].id)?.name + ' (GAST)'
					: player._baseTeams &&
					  player._baseTeams[0] &&
					  teams.find((_team: Team) => _team.__id === player._baseTeams[0].id)?.name),
		},
	];

	return (
		<>
			<Helmet>
				<title>{t('portal.common.selectPlayers')}</title>
			</Helmet>
			{!frame && <PortalHeader />}
			<Styled.ModalHeading>{t('portal.common.selectPlayers')}</Styled.ModalHeading>

			{players && players.length !== 0 ? (
				<>
					<p>
						{t('portal.common.selectPlayersToAdd')} <b>{teamName}</b>
					</p>
					<Styled.TableWrapper>
						<DataTable
							columns={columns}
							striped={true}
							defaultSortField={'firstName'}
							customStyles={table}
							data={players}
							keyField="__id"
							selectableRows={true}
							selectableRowsHighlight={true}
							selectableRowSelected={(player: Player) => {
								const selected =
									player._baseTeams.find((_team) => _team.id === currentTeam) ||
									player._guestTeams.find((_team) => _team.id === currentTeam);
								return selected ? true : false;
							}}
							selectableRowDisabled={(row) => {
								// return row._baseTeams.length > 0 ? true : false;
								return row._baseTeams.length > 0
									? row._baseTeams.find((_team) => _team.id === currentTeam)
										? false
										: true
									: false;
							}}
							onSelectedRowsChange={(row) => setSelectedRows(row.selectedRows)}
						/>
					</Styled.TableWrapper>
					<Styled.ButtonWrapper>
						<div onClick={closeAction}>{t('portal.common.cancel')}</div>
						<Button action={savePlayers}>{t('portal.common.save')}</Button>
					</Styled.ButtonWrapper>
				</>
			) : (
				<div>
					{t('portal.common.noPlayers')}
					<Link link={`/portal/club/${club.__id}/players`}>{t('portal.common.playersPage')}</Link>
				</div>
			)}
			{!frame && <Footer />}
		</>
	);
};

const mapStateToProps = (state: RootState): StateProps => ({
	currentClub: state.app.currentClub,
	currentTeam: state.app.currentTeam,
	club: state.firestore.data.currentClub,
	teams: state.firestore.ordered.teams,
	players: state.firestore.ordered.players,
});

export default compose(
	connect(mapStateToProps),
	firestoreConnect(({ currentClub }: FirestoreConnectProps) => {
		if (currentClub)
			return [
				{
					collection: 'clubs',
					storeAs: 'currentClub',
					doc: currentClub,
				},
				{
					collection: 'teams',
					storeAs: 'teams',
					where: [
						['__deleted', '==', false],
						['_club', '==', fireStore.doc(`clubs/${currentClub}`)],
					],
				},
				{
					collection: 'players',
					storeAs: 'players',
					where: [
						['__deleted', '==', false],
						['_clubs', 'array-contains', fireStore.doc(`clubs/${currentClub}`)],
					],
				},
			];
		else return [];
	}),
)(Select) as React.ComponentType<OwnProps & FirestoreConnectProps>;
