import styled from 'styled-components';

import type { StyledProps } from './types';

export const IconWrapper = styled.div`
	position: absolute;
	top: 30px;
	right: 30px;
	margin: auto;
	width: 22px;
	height: 16px;
	cursor: pointer;
	display: none;

	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		display: block;
	}
`;

export const Icon = styled.div<StyledProps>`
	position: relative;
	transform: translateY(6px);
	background: ${({ visible }) => (!visible ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)')};
	transition: all 0s;
	width: 22px;
	height: 2px;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 6px;
		background: ${({ visible }) => (!visible ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)')};
		transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
		width: 22px;
		height: 2px;
	}

	&::after {
		width: 22px;
		height: 2px;
		content: '';
		position: absolute;
		left: 0;
		top: 6px;
		background: ${({ visible }) => (!visible ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)')};
		transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
	}

	${({ open, theme: { colors } }) =>
		open &&
		`
	background: rgba(255, 255, 255, 0);

	&::before {
		background: ${colors.textColor};

		bottom: 0;
		transform: rotate(-45deg);
		transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
	}

	&::after {
		background: ${colors.textColor};
		top: 0;
		transform: rotate(45deg);
		transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
	}
`}
`;
