import React, { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
//import { Timestamp } from 'firebase/firestore'
//import firebase from 'firebase/app'


import * as Styled from './styles';
import type { Props, ParamsProps, OwnProps, StateProps } from './types';

import type { RootState } from 'src/redux';
import { fireApp, fireFunctions, fireStore } from 'src/utils';
import { Header, Footer } from 'src/components';

import { Permission } from 'src/types/permission.d';

import 'tippy.js/dist/tippy.css';

export const SignUp: React.FC<Props> = ({ isLoggedIn, frame = false }: Props) => {
	let myOnlyClubID='';
	const { push } = useHistory();
	const { userEmail } = useParams<ParamsProps>();
	const [emailValue, setEmailValue] = useState(userEmail ? userEmail : '');
	const { t } = useTranslation();

	const handleSignUp = useCallback(async (event) => {
		event.preventDefault();
		const { email, password, displayName } = event.target.elements;

		if (password.value.length < 8) {
			toast.error(t('portal.auth.8chars'));
			return;
		}
		if (!password.value.match(/[a-z]+/)) {
			toast.error(t('portal.auth.smallChars'));
			return;
		}
		if (!password.value.match(/[A-Z]+/)) {
			toast.error(t('portal.auth.capitalLetters'));
			return;
		}
		if (!password.value.match(/[0-9]+/)) {
			toast.error(t('portal.auth.numberRequired'));
			return;
		}
		if (!password.value.match(/[$@#&!]+/)) {
			toast.error(t('portal.auth.specialChar'));
			return;
		}

		try {
			const valueEmail = email.value.toLowerCase();
			const valuePassword = password.value;
			const valueName = displayName.value;

			await fireApp
				.auth()
				.createUserWithEmailAndPassword(valueEmail, valuePassword)
				.then((userCredentials: firebase.default.auth.UserCredential) => {
					if (!userCredentials) return;
					if (!userCredentials.user) return;

					userCredentials.user.updateProfile({
						displayName: valueName,
					});
					fireStore.collection('users').doc(userCredentials.user.uid).set({
						uid: userCredentials.user.uid,
						email: valueEmail,
						displayName: valueName,
					});
					
										
					fireFunctions.httpsCallable('createCustomer')({
						name: valueName,
						email: valueEmail,
						userID: userCredentials.user.uid,
					});


						///////////////////////////////////////////////////////
						const UID=userCredentials.user.uid;
						const userRef=fireStore.collection('users').doc(UID);
						myOnlyClubID=fireStore.collection('clubs').doc().id;
						fireStore .collection('clubs') .doc(myOnlyClubID)
						.set({
							__deleted: false,
							__id: myOnlyClubID,
							name: '',
							address: '',
							postalcode: '',
							city: '',
							phoneNumber: '',
								_permissionsUsers: [
								{
									type: Permission.OWNER,
									user: userRef,
								},
							],
							_users: [userRef],
							_trainers: [userRef],
						})
						.then(() => {
							console.log(`myOnlyClub ${t('portal.create.created')} ${myOnlyClubID}`);
							//toast.info(`myOnlyClub ${t('portal.create.created')} ${myOnlyClubID}`);
							//push(`/portal/club/${club.__id}/teams`);
							//return <Redirect to="/portal/club/`${club.__id}`/teams"/>;
						})
						.catch(toast.error);

						console.log(`******** USER CREATING: ${UID}`)
						///////////////////////////////////////////////////////





				})
				.catch((error) => {
					toast.error(error.message);
				});


		} catch (error) {
			alert(error);
		}
	}, []);

	useEffect(() => {
		//if (isLoggedIn) push('/portal/create-club');
		// (`/portal/club/${myOnlyClubID}/teams`);
		if (isLoggedIn) push(`/portal`)
	}, [isLoggedIn, push]);

	return (
		<>
			<Helmet>
				<title>{t('website.header.register')}</title>
			</Helmet>
			{!frame && <Header />}
			<Styled.Container frame={frame}>
				<h1>{t('portal.auth.createNewAccount')}</h1>
				<Styled.Form onSubmit={handleSignUp}>
					<Styled.Label>
						{t('portal.common.name')}
						<Styled.Input name="displayName" type="text" placeholder="John Doe" required />
					</Styled.Label>
					<Styled.Label>
						{t('portal.common.email')}
						<Styled.Input
							name="email"
							type="email"
							placeholder="john@example.com"
							value={emailValue}
							required
							onChange={(e) => setEmailValue(e.target.value)}
						/>
					</Styled.Label>
					<Tippy
						content={
							<>
								{t('portal.auth.requirements')}: <br />
								<ul>
									<li>{t('portal.auth.requirement1')}</li>
									<li>{t('portal.auth.requirement2')}</li>
									<li>{t('portal.auth.requirement3')}</li>
									<li>{t('portal.auth.requirement4')}</li>
								</ul>
							</>
						}
						interactive={true}
						placement="right"
						trigger="focus click"
					>
						<Styled.Label>
							{t('portal.auth.password')}
							<Styled.Input name="password" type="password" min={8} required />
						</Styled.Label>
					</Tippy>

					<Styled.Button type="submit">{t('website.header.register')}</Styled.Button>
				</Styled.Form>
				<Styled.Small>
					<p>
						{t('portal.auth.alreadyMember')}{' '}
						<Link to="/login">
							{' '}
							<Styled.Link>{t('portal.auth.login')}</Styled.Link>
						</Link>
					</p>
				</Styled.Small>
			</Styled.Container>
			{!frame && <Footer />}
		</>
	);
};

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
	...ownProps,
	isLoggedIn: !state.firebase.auth.isEmpty,
});

export default connect(mapStateToProps)(SignUp);
