import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Carousel, { Dots, autoplayPlugin } from '@brainhubeu/react-carousel';

import '@brainhubeu/react-carousel/lib/style.css';
import * as Styled from './styles';

import { Header, Footer, Download } from 'src/components';

export const About: React.FC = () => {
	const [carouselValue, setCarouselValue] = useState(0);

	const onChangeCarousel = (value: number) => setCarouselValue(value);

	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>{t('website.aboutUs.title')}</title>
			</Helmet>
			<Header />
			<Styled.Container>
				<Styled.Heading>{t('website.aboutUs.title')}</Styled.Heading>
				<Styled.Text>
					<p>{t('website.aboutUs.text')}</p>
				</Styled.Text>
				<Styled.Circle />
				<Styled.SmallHeading>{t('website.aboutUs.reviewTitle')}</Styled.SmallHeading>
				<Styled.CarouselWrapper>
					<Carousel
						value={carouselValue}
						onChange={onChangeCarousel}
						draggable={false}
						plugins={[
							{
								resolve: autoplayPlugin,
								options: {
									interval: 5000,
									stopAutoPlayOnHover: true,
								},
							},
						]}
					>
						<Styled.Testimonial>{t('website.aboutUs.reviews.1')}</Styled.Testimonial>
						<Styled.Testimonial>{t('website.aboutUs.reviews.2')}</Styled.Testimonial>
						<Styled.Testimonial>{t('website.aboutUs.reviews.3')}</Styled.Testimonial>
						<Styled.Testimonial>{t('website.aboutUs.reviews.4')}</Styled.Testimonial>
						<Styled.Testimonial>{t('website.aboutUs.reviews.5')}</Styled.Testimonial>
						<Styled.Testimonial>{t('website.aboutUs.reviews.6')}</Styled.Testimonial>
						<Styled.Testimonial>{t('website.aboutUs.reviews.7')}</Styled.Testimonial>
						<Styled.Testimonial>{t('website.aboutUs.reviews.8')}</Styled.Testimonial>
						<Styled.Testimonial>{t('website.aboutUs.reviews.9')}</Styled.Testimonial>
					</Carousel>
					<Dots value={carouselValue} onChange={onChangeCarousel} />
				</Styled.CarouselWrapper>
				<Styled.Spacer />
			</Styled.Container>
			<Download />
			<Footer background />
		</>
	);
};

export default About;
