import styled from 'styled-components';

export const LabelWrapper = styled.div``;
export const Checkbox = styled.input``;
export const Label = styled.label``;

// export const LabelWrapper = styled.label`
//   position: relative;
//   margin: auto;
//   width: 100%;
//   max-width: 280px;
//   border-radius: 3px;
//   overflow: hidden;
//   margin-top: 20px;
//   display: block;
// `;
// export const Label = styled.span`
//   position: absolute;
//   top: 20px;
//   left: 12px;
//   font-size: 16px;
//   color: rgba(#222, 0.5);
//   font-weight: 500;
//   transform-origin: 0 0;
//   transform: translate3d(0, 0, 0);
//   transition: all 0.2s ease;
//   pointer-events: none;
// `;
// export const FocusBg = styled.span`
//   position: absolute;
//   top: 0px;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(#222, 0.05);
//   z-index: -1;
//   transform: scaleX(0.3);
//   transform-origin: left;
// `;

// export const Input = styled.input`
//   -webkit-appearance: none;
//   appearance: none;
//   width: 100%;
//   border: 0;
//   font-family: inherit;
//   padding: 16px 12px 0 12px;
//   height: 56px;
//   font-size: 16px;
//   font-weight: 400;
//   background: rgba(#222, 0.02);
//   box-shadow: inset 0 -1px 0 rgba(#222, 0.3);
//   color: #222;
//   transition: all 0.15s ease;
//   box-shadow: inset 0 -1px 0 ${({ theme: { colors } }) => colors.blue};

//   &:hover {
//     background: rgba(#222, 0.04);
//     box-shadow: inset 0 -1px 0 rgba(#222, 0.5);
//   }

//   &:not(:placeholder-shown) {
//     + ${Label} {
//       color: rgba(#222, 0.5);
//       transform: translate3d(0, -16px, 0) scale(0.75);
//     }
//   }

//   &:focus {
//     background: rgba(#222, 0.05);
//     outline: none;
//     box-shadow: inset 0 -2px 0 ${({ theme: { colors } }) => colors.blue};
//     + ${Label} {
//       color: ${({ theme: { colors } }) => colors.blue};
//       transform: translate3d(0, -16px, 0) scale(0.75);
//       + ${FocusBg} {
//         transform: scaleX(1);
//         transition: all 0.1s ease;
//       }
//     }
//   }
// `;
