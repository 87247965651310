import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: calc(100vh - ${({ theme: { layout } }) => layout.footer.height}px);

	* {
		text-decoration: none;
	}
`;

export const Container = styled.div`
	width: 90%;
	max-width: 1200px;
	margin-top: 30px;
	margin-bottom: 30px;
	font-size: 0.9rem;
`;

export const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 75px;
	margin-bottom: 20px;
	align-items: center;

	@media ${({ theme: { layout } }) => layout.device.tablet} {
		flex-direction: column;
		align-items: flex-start;

		h1 {
			margin-bottom: 10px;
		}
		> div {
			margin-bottom: 10px;
		}
	}
`;

export const Heading = styled.h1`
	margin: 0;
	font-size: ${({ theme: { fonts } }) => fonts.sizes.big};
`;

export const Content = styled.div`
	display: flex;
	justify-content: center;

	@media ${({ theme: { layout } }) => layout.device.tablet} {
		flex-direction: column;
	}
`;

export const centerSection = styled.div`
	padding: 0 30px;
	width: 100%;
	max-width: 500px;

	/* @media ${({ theme: { layout } }) => layout.device.tablet} {
		width: 100%;
		> a {
			display: none;
		}
	} */
`;
export const LeftSection = styled.div`
	width: 25%;
	margin-right: 30px;

	@media ${({ theme: { layout } }) => layout.device.tablet} {
		width: 100%;
		> a {
			display: none;
		}
	}
`;

export const LeftSectionContent = styled.div`
	height: auto;
	background: ${({ theme: { colors } }) => colors.blue};
	border-radius: 15px;
	color: #fff;
	margin-bottom: 20px;
	padding: 30px;
	text-align: center;
	width: 100%;

	button {
		margin-top: 30px;
		width: 100%;
	}
	@media ${({ theme: { layout } }) => layout.device.mobileL} {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	@media ${({ theme: { layout } }) => layout.device.tablet} {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 20px;

		button {
			width: 100%;
		}
	}
`;

export const Divider = styled.div`
	width: 80%;
	height: 1px;
	background: ${({ theme: { colors } }) => colors.background};
	margin: 20px auto;
	opacity: 0.4;
	padding: 0 !important;

	@media ${({ theme: { layout } }) => layout.device.tablet} {
		width: 1px;
		height: 100px;
	}
	@media ${({ theme: { layout } }) => layout.device.mobileL} {
		width: 80%;
		height: 1px;
	}
`;

export const PremiumAmount = styled.div`
	font-size: 50px;
	font-weight: 600;
	padding-right: 5px;
	margin-bottom: 10px;
`;
export const Price = styled.div`
	font-size: 40px;
	font-weight: 600;
	span:nth-child(1) {
		font-weight: 400;
		font-size: 14px;
	}
	span:nth-child(2) {
		display: block;
		font-weight: 400;
		font-size: 14px;
	}
`;

export const TeamOverview = styled.div`
	width: 70%;

	@media ${({ theme: { layout } }) => layout.device.tablet} {
		margin-top: 20px;
		width: 100%;
	}
`;
