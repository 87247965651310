import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';
import type { Props } from './types';

export const Footer: React.FC<Props> = ({ background = false }: Props) => {
	const startYear = 2020;
	const currentYear = new Date().getFullYear();
	const { t } = useTranslation();
	const displayYear = (): string => {
		if (startYear === currentYear) return `${currentYear}`;
		else return `${startYear}-${currentYear}`;
	};

	return (
		<Styled.Container background={background}>
			<a href="https://www.facebook.com/sportassistantmanager/" target="_blank" rel="noreferrer">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z"
						fill="currentColor"
					/>
				</svg>
			</a>
			<Link to="/privacy" target="_blank">
				{t('portal.common.privacyStatement')}
			</Link>

			<span>v1.16 &copy; Copyright SAM {displayYear()}</span>
		</Styled.Container>
	);
};

export default Footer;
