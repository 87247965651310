/* eslint-disable react/display-name */
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { ImpulseSpinner } from 'react-spinners-kit';
import { Helmet } from 'react-helmet';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TrashEmpty } from 'css.gg';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router';

import * as Styled from './styles';
import type { FirestoreConnectProps, StateProps, Props } from './types';

import { fireStore } from 'src/utils';
import { colors, table } from 'src/styles';
import { changeCurrentClub, changeCurrentTeam, RootState, store } from 'src/redux';
import { PlayerCreate, PlayerEdit, PlayerImport } from 'src/pages';
import { Button, Modal, PortalHeader, Footer } from 'src/components';
import { Player, Team } from 'src/types';

export const Overview: React.FC<Props> = ({ currentClub, club, teams, players }: Props) => {
	const [modalVisibility, setModalVisibility] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState<string | null>(null);
	const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);
	const { t } = useTranslation();
	const { clubID, teamID } = useParams<{ clubID?: string; teamID?: string }>();
	store.dispatch(changeCurrentClub(clubID));
	store.dispatch(changeCurrentTeam(teamID));

	const OpenModal = (content: string) => {
		setModalVisibility(true);
		setModalContent(content);
	};

	const CloseModal = () => {
		setModalVisibility(false);
		setModalContent(null);
	};

	const deletePlayer = (player: Player) => {
		fireStore
			.collection('players')
			.doc(player.__id)
			.update({
				__deleted: true,
				_baseTeams: [],
			})
			.then(() => {
				let baseTeamID = '';

				if (player._baseTeams !== null && player._baseTeams[0] !== null) baseTeamID = player._baseTeams[0].id;

				if (baseTeamID) {
					fireStore
						.collection('teams')
						.doc(baseTeamID)
						.update({
							players: teams
								.filter((team: Team) => team.__id === baseTeamID)[0]
								.players.filter((_player: firebase.default.firestore.DocumentReference) => _player.id !== player.__id),
						})
						.catch(toast.error);
				}
			})
			.catch(toast.error);

		toast.info(t('portal.common.deletedPlayer'));
	};

	const columns = [
		{
			name: t('portal.common.firstName'),
			selector: 'firstName',
			sortable: true,
		},
		{
			name: t('portal.common.lastName'),
			selector: 'lastName',
			sortable: true,
		},
		{
			name: t('portal.common.team'),
			sortable: true,
			selector: 'teamName',
		},
		{
			name: t('portal.common.edit'),
			cell: (player: Player) => (
				<Button
					action={() => {
						setSelectedPlayer(player);
						OpenModal('PlayerEdit');
					}}
					small
				>
					{t('portal.common.edit')}
				</Button>
			),
		},
		{
			name: t('portal.common.delete'),
			sortable: false,
			cell: (player: Player) => (
				<Styled.IconButton
					onClick={() => {
						if (window.confirm(t('portal.delete.deleteContent', { name: player.firstName }))) deletePlayer(player);
					}}
				>
					<TrashEmpty />
				</Styled.IconButton>
			),
			minWidth: '100px',
			center: true,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];

	if (isLoaded(club) && isLoaded(teams) && isLoaded(players)) {
		if (isEmpty(club)) {
			toast.error(t('portal.errors.selectClubFirst'), {
				toastId: 'select-club',
			});
			return <Redirect to={`/portal`} />;
		}

		const tablePlayers = players.map((_player: Player) => {
			let guestTeamID = '',
				baseTeamID = '';

			if (_player._guestTeams && _player._guestTeams[0]) guestTeamID = _player._guestTeams[0].id;
			if (_player._baseTeams && _player._baseTeams[0]) baseTeamID = _player._baseTeams[0].id;

			if (guestTeamID && teams.filter((team: Team) => team.__id === guestTeamID)) {
				const team = teams.filter((team: Team) => team.__id === guestTeamID)[0];

				if (team && team.name) {
					return {
						..._player,
						teamName: team.name + ` (${t('portal.common.guest')})`,
					};
				}

				return {
					..._player,
					teamName: `${t('portal.common.deletedTeam')} (${t('portal.common.guest')})`,
				};
			}

			if (baseTeamID)
				if (teams.filter((team: Team) => team.__id === baseTeamID).length > 0)
					return {
						..._player,
						teamName: teams.filter((team: Team) => team.__id === baseTeamID)[0].name,
					};
				else
					return {
						..._player,
						teamName: t('portal.common.deletedTeam'),
					};

			return { ..._player, teamName: '-' };
		});

		return (
			<>
				<Helmet>
					<title>{t('portal.common.allPlayers')}:</title>
				</Helmet>
				<PortalHeader />
				<Styled.Wrapper>
					<Styled.Container>
						<Styled.HeaderWrapper>
							<Styled.Heading>{t('portal.common.allPlayers')}:</Styled.Heading>
							<Styled.ButtonWrapper>
								{/* <Button action={() => OpenModal('PlayerImport')} color="orange">
									Spelers importeren
								</Button> */}
								<Button action={() => OpenModal('PlayerCreate')} color="orange">
									{t('portal.create.player')}
								</Button>
							</Styled.ButtonWrapper>
						</Styled.HeaderWrapper>
						{players.length > 0 ? (
							<>
								<Styled.TableWrapper>
									<DataTable
										columns={columns}
										defaultSortField={'firstName'}
										striped={true}
										customStyles={table}
										data={tablePlayers}
										keyField="__id"
									/>
								</Styled.TableWrapper>
							</>
						) : (
							<div>{t('portal.empty.players')}</div>
						)}
					</Styled.Container>
				</Styled.Wrapper>
				<Modal modalVisibility={modalVisibility} closeAction={CloseModal}>
					{modalContent === 'PlayerCreate' && (
						<PlayerCreate currentClub={currentClub} teams={teams} closeAction={CloseModal} frame />
					)}
					{modalContent === 'PlayerImport' && <PlayerImport currentClub={currentClub} closeAction={CloseModal} frame />}
					{modalContent === 'PlayerEdit' && selectedPlayer && (
						<PlayerEdit
							currentClub={currentClub}
							teams={teams}
							closeAction={CloseModal}
							player={selectedPlayer}
							frame
						/>
					)}
				</Modal>
				<Footer />
			</>
		);
	} else
		return (
			<>
				<PortalHeader />
				<Styled.LoaderContainer>
					<ImpulseSpinner size={80} frontColor={colors.blue} backColor={colors.grey} />
				</Styled.LoaderContainer>
				<Footer />
			</>
		);
};

const mapStateToProps = (state: RootState): StateProps => ({
	uid: state.firebase.auth.uid,
	club: state.firestore.data.currentClub,
	user: state.firebase.profile,
	teams: state.firestore.ordered.teams,
	players: state.firestore.ordered.players,
	currentClub: state.app.currentClub,
});

export default compose(
	connect(mapStateToProps),
	firestoreConnect(({ uid, currentClub }: FirestoreConnectProps) => {
		if (currentClub)
			return [
				{
					collection: 'clubs',
					storeAs: 'currentClub',
					doc: currentClub,
				},
				{
					collection: 'teams',
					storeAs: 'teams',
					where: [
						['__deleted', '==', false],
						['_club', '==', fireStore.doc(`clubs/${currentClub}`)],
						['_users', 'array-contains', fireStore.doc(`users/${uid}`)],
						['tier', '==', 'PREMIUM'],
					],
				},
				{
					collection: 'players',
					storeAs: 'players',
					where: [
						['__deleted', '==', false],
						['_clubs', 'array-contains', fireStore.doc(`clubs/${currentClub}`)],
					],
				},
			];
		else return [];
	}),
)(Overview) as React.ComponentType;
