import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: calc(100vh - ${({ theme: { layout } }) => layout.footer.height}px);
`;

export const Container = styled.div`
	width: 90%;
	max-width: 1200px;
	margin-top: 30px;
	margin-bottom: 30px;
	font-size: 0.9rem;
`;

export const TableWrapper = styled.div`
	box-shadow: 0 0.7px 2.2px rgba(0, 0, 0, 0.001), 0 1.8px 5.3px rgba(0, 0, 0, 0.003), 0 3.4px 10px rgba(0, 0, 0, 0.007),
		0 6px 17.9px rgba(0, 0, 0, 0.014), 0 11.3px 33.4px rgba(0, 0, 0, 0.026), 0 27px 80px rgba(0, 0, 0, 0.07);
`;

export const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 75px;
	margin-bottom: 20px;
	align-items: center;

	@media ${({ theme: { layout } }) => layout.device.tablet} {
		flex-direction: column;
		align-items: flex-start;

		h1 {
			margin-bottom: 10px;
		}
		> div {
			margin-bottom: 10px;
		}
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	div,
	a,
	button {
		cursor: pointer;
		margin-right: 10px;
	}
`;

export const Heading = styled.h1`
	margin: 0;
	font-size: ${({ theme: { fonts } }) => fonts.sizes.big};
`;

export const LoaderContainer = styled.div`
	width: 100vw;
	height: calc(100vh - ${({ theme: { layout } }) => layout.footer.height}px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	div {
		margin-bottom: 30px;
	}
`;

export const LoaderContainerOverlay = styled.div`
	transition: 0.3s ease all;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	background: #ffffff;
	opacity: 0.7;

	div {
		margin-bottom: 30px;
	}
`;

export const TeamWrapper = styled.div`
	display: flex;
	flex-direction: column;

	> div {
		height: 48px;
		display: flex;
		align-items: center;
	}
`;

export const Small = styled.p`
	font-size: 0.85rem !important;
`;

export const IconButton = styled.div`
	cursor: pointer;
	transition: 0.3s ease all;
	&:hover {
		color: ${({ theme: { colors } }) => colors.textColorLight};
	}
`;
