import React from 'react';

import { StyledLabel, StyledFocusBg, StyledLabelWrapper, StyledInput } from './styles';
import type { Props } from './types';

const Input: React.FC<Props> = ({ name, type, label, required = false, placeholder, ...props }: Props) => {
	const edge = window.navigator.userAgent.indexOf('Edge') > -1;
	return (
		<StyledLabelWrapper>
			{required ? (
				<StyledInput
					{...props}
					name={name}
					type={type}
					placeholder={placeholder ? placeholder : ' '}
					required
					edge={edge}
				/>
			) : (
				<StyledInput {...props} name={name} type={type} placeholder={placeholder ? placeholder : ' '} edge={edge} />
			)}
			<StyledLabel>{label}</StyledLabel>
			<StyledFocusBg></StyledFocusBg>
		</StyledLabelWrapper>
	);
};

export default Input;
