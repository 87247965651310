/* eslint-disable react/display-name */
/*eslint prefer-const: "warn"*/

import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TrashEmpty, ChevronDown } from 'css.gg';
import { Helmet } from 'react-helmet';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { ImpulseSpinner } from 'react-spinners-kit';
import { useHistory, useParams } from 'react-router-dom';

import * as Styled from './styles';
import type { UserWithTeam, MatchParams } from './types';

import { colors, table } from 'src/styles';
import { Button, GreyedOut, Modal, PortalHeader, Footer } from 'src/components';
import { changeCurrentClub, changeCurrentTeam, RootState, store } from 'src/redux';
import type { Club, PermissionUser, Team, User } from 'src/types';
import { TrainerCreateGeneral, TrainerSelect } from 'src/pages';
import { Permission } from 'src/types/permission.d';
import { fireFunctions } from 'src/utils';
import { Tier } from 'src/types/tier.d';
//import { Tier } from 'src/types/tier';

export const TeamOverview: React.FC = () => {
	const [updating, setUpdating] = useState(false);
	const [trainers, setTrainers] = useState<Array<UserWithTeam>>();
	const [modalVisibility, setModalVisibility] = useState(false);
	const [modalContent, setModalContent] = useState<'TrainerCreateGeneral' | 'TrainerSelect' | null>(null);
	const { t } = useTranslation();
	const { push } = useHistory();
	const { clubID, teamID } = useParams<MatchParams>();
	store.dispatch(changeCurrentClub(clubID));
	store.dispatch(changeCurrentTeam(teamID));

	useFirestoreConnect([
		{
			collection: 'clubs',
			doc: clubID,
		},
		{
			collection: 'teams',
			doc: teamID,
		},
	]);
	const club: Club = useSelector(({ firestore: { data } }: RootState) => data.clubs && data.clubs[clubID]);
	let team: Team = useSelector(({ firestore: { data } }: RootState) => data.teams && data.teams[teamID]);

	if(!team){
		//team=[];
		team={
			name:'team',
			__deleted:false, 
			__id:'', 
			_club:'', 
			_permissionsUsers:[],
			_users:[], 
			matchPlayTime:90, 
			season: '', 
			tier: Tier.FREE,
			matches:[], 
			players:[], 
			trainings:[]
		};
	}
	

	//console.log('sssszzzzzzzzzzzzzzzzzzzzzzzzzz',team, teamID);

	useEffect(() => {
		let requests;

		if (isLoaded(team)) {
			if (isEmpty(team)) {
				push('/portal');
			} else {
				requests = team._permissionsUsers.map(
					async (_permissionsUser: PermissionUser) => await _permissionsUser.user.get(),
				);
			}
		}

		if (requests)
			Promise.all(requests).then((response) => {
				let users: Array<User> = response.map((_data) => _data.data() as User);

				users = Array.from(new Set(users));

				setTrainers(
					users.map((_user: User) => {
						return {
							..._user,
							type: team._permissionsUsers.find(
								(_permissionUser: PermissionUser) => _permissionUser.user.id === _user.uid,
							).type,
							team: team,
						};
					}),
				);
			});
	}, [team]);

	const OpenModal = (content: 'TrainerCreateGeneral' | 'TrainerSelect') => {
		setModalVisibility(true);
		setModalContent(content);
	};
	const CloseModal = () => {
		setModalVisibility(false);
		setModalContent(null);
	};

	const updateTrainer = (userID: string, teamID: string, type: Permission) => {
		setUpdating(true);

		fireFunctions
			.httpsCallable('updateUserAccess')({
				userID,
				teamID,
				type: type ? type : Permission.NONE,
			})
			.then(({ data: { status } }) => {
				switch (status) {
					case 200:
						if (trainers) {
							const tempTrainers = trainers;
							const row = tempTrainers.findIndex((_trainer) => _trainer.uid === userID);
							tempTrainers[row].type = type;
							setTrainers(tempTrainers);
							setUpdating(false);
							toast.info(t('portal.rights.edited'));
						}
						break;
					default:
						break;
				}
			});
	};

	const columns = [
		{
			name: t('portal.common.name'),
			selector: 'displayName',
			sortable: true,
		},
		{
			name: t('portal.common.email'),
			selector: 'email',
			sortable: true,
		},
		{
			name: t('portal.rights.title'),
			cell: (user: UserWithTeam) => {
				return (
					user.type && (
						<Styled.TeamWrapper>
							<div>
								{user.type !== Permission.OWNER ? (
									<Styled.SelectWrapper>
										<Styled.Select
											defaultValue={user.type ? user.type : ''}
											onChange={(event) => {
												const permissionValue = Permission[event.target.value];
												updateTrainer(user.uid, user.team.__id, permissionValue);
												user.type = permissionValue;
											}}
										>
											<option value="MANAGE">{t('portal.rights.portalApp')}</option>
											<option value="WRITE">{t('portal.rights.app')}</option>
											<option value="READ">{t('portal.rights.appRead')}</option>
										</Styled.Select>
										<ChevronDown />
									</Styled.SelectWrapper>
								) : (
									t('portal.rights.owner')
								)}
							</div>
						</Styled.TeamWrapper>
					)
				);
			},
			sortable: true,
		},
		{
			name: t('portal.common.delete'),
			sortable: false,
			cell: (user: UserWithTeam) =>
				user.type !== Permission.OWNER ? (
					<Styled.IconButton
						onClick={() => {
							if (window.confirm(t('portal.delete.deleteContent', { name: user.displayName })))
								updateTrainer(user.uid, user.team.__id, Permission.NONE);
						}}
					>
						<TrashEmpty />
					</Styled.IconButton>
				) : (
					<GreyedOut>
						<TrashEmpty />
					</GreyedOut>
				),

			minWidth: '100px',
			center: true,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];
	console.log('wwwwwwwwwwwwwwwwwwwwwwwwwwww',trainers);

	//return trainers ? (
		return trainers==trainers?(
		<>
			<Helmet>
				<title>
					{team.name} - {t('portal.common.trainersOverview')}
				</title>
			</Helmet>
			<PortalHeader />
			<Styled.Wrapper>
				<Styled.Container>
					<Styled.HeaderWrapper>
						<Styled.Heading>
							{team.name} - {t('portal.common.trainersOverview')}
						</Styled.Heading>
						<Styled.ButtonWrapper>
							<Button action={() => OpenModal('TrainerCreateGeneral')} color="orange">
								{t('portal.create.trainer')}
							</Button>
							<Button action={() => OpenModal('TrainerSelect')} color="orange">
								{t('portal.common.selectTrainers')}
							</Button>
						</Styled.ButtonWrapper>
					</Styled.HeaderWrapper>
					{trainers ? (
						<>
							<Styled.TableWrapper>
								<DataTable columns={columns} striped={true} customStyles={table} data={trainers} keyField="email" />
							</Styled.TableWrapper>
							<br />
							<p>
								<b>{t('portal.rights.title')}</b>
							</p>
							<Styled.Small>
								<b>{t('portal.rights.owner')}:</b> {t('portal.rights.ownerDescription')}
								<br />
								<b>{t('portal.rights.portalApp')}:</b> {t('portal.rights.portalAppDescription')}
								<br />
								<b>{t('portal.rights.app')}:</b> {t('portal.rights.appDescription')}
								<br />
								<b>{t('portal.rights.appRead')}:</b> {t('portal.rights.appReadDescription')}
							</Styled.Small>
						</>
					) : (
						<div>{t('portal.empty.teams')}</div>
					)}
				</Styled.Container>
			</Styled.Wrapper>
			{updating && (
				<Styled.LoaderContainerOverlay>
					<ImpulseSpinner size={80} frontColor={colors.blue} backColor={colors.grey} />
				</Styled.LoaderContainerOverlay>
			)}
			<Modal modalVisibility={modalVisibility} closeAction={CloseModal}>
				{modalContent === 'TrainerSelect' && (
					<TrainerSelect
						frame
						closeAction={CloseModal}
						originalTrainers={trainers}
						teamID={team.__id || ''}
						clubID={club.__id}
					/>
				)}
				{modalContent === 'TrainerCreateGeneral' && (
					<TrainerCreateGeneral frame closeAction={CloseModal} clubID={club.__id} />
				)}
			</Modal>
			<Footer />
		</>
	) : (
		<>
			<PortalHeader />
			<Styled.LoaderContainer>
				<ImpulseSpinner size={80} frontColor={colors.blue} backColor={colors.grey} />
			</Styled.LoaderContainer>
			<Footer />
		</>
	);
};

export default TeamOverview;
