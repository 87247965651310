import React, { useState } from 'react';
import { default as firebase } from 'firebase/app';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ChevronDown } from 'css.gg';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';
import type { Props } from './types';

import { Input, Checkbox, Button, PortalHeader, Footer } from 'src/components';
import { Player } from 'src/types';
import { fireStore } from 'src/utils';

export const Create: React.FC<Props> = ({ frame = false, closeAction, currentClub, teams }: Props) => {
	const [KNVB, setKNVB] = useState('');
	const [guest, setGuest] = useState(false);
	// Player position
	const [defence, setDefence] = useState(false);
	const [attack, setAttack] = useState(false);
	const [keeper, setKeeper] = useState(false);
	const [middleGround, setMiddleGround] = useState(false);
	// leg preference
	const [left, setLeft] = useState(false);
	const [right, setRight] = useState(false);
	// Team
	const [teamID, setTeamId] = useState<string | null>(null);
	const { t } = useTranslation();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const createPlayer = (event: any) => {
		event.preventDefault();
		const { firstName, middleName, lastName, birthDate, backNumber, knvbNumber, size, bio } = event.target.elements;

		if (!firstName.value) {
			toast.error(t('portal.errors.emptyFirstName'));
			return;
		}
		if (!lastName.value) {
			toast.error(t('portal.errors.emptyLastName'));
			return;
		}
		if (knvbNumber.value.length > 7) {
			toast.error(t('portal.errors.KNVBTooLong'));
			return;
		}

		const addPlayer = async () => {
			const playerRef = await fireStore.collection('players').doc();
			const playerID = playerRef.id;
			const positions: Array<'MIDDLEGROUND' | 'ATTACK' | 'DEFENCE' | 'KEEPER'> = [];
			if (middleGround) positions.push('MIDDLEGROUND');
			if (attack) positions.push('ATTACK');
			if (defence) positions.push('DEFENCE');
			if (keeper) positions.push('KEEPER');

			const legPreference: Array<'LEFT' | 'RIGHT'> = [];
			if (left) legPreference.push('LEFT');
			if (right) legPreference.push('RIGHT');

			if (currentClub) {
				let playerDoc: Player = {
					__deleted: false,
					__id: playerID,
					_clubs: [fireStore.collection('clubs').doc(currentClub)],
					_baseTeams: [],
					_guestTeams: [],
					firstName: firstName.value,
					middleName: middleName.value || null,
					lastName: lastName.value,
					birthDate: birthDate.value ? firebase.firestore.Timestamp.fromDate(new Date(birthDate.value)) : null,
					backNumber: Number(backNumber.value) || null,
					knvbNumber: String(knvbNumber.value) || null,
					size: Number(size.value) || null,
					positions,
					legPreference,
					bio: bio.value || null,
				};

				// If Guest player, add to guestTeams otherwise set baseTeam
				if (teamID)
					if (guest)
						playerDoc = {
							...playerDoc,
							_guestTeams: [fireStore.collection('teams').doc(teamID)],
						};
					else
						playerDoc = {
							...playerDoc,
							_baseTeams: [fireStore.collection('teams').doc(teamID)],
						};

				fireStore
					.collection('players')
					.doc(playerID)
					.set(playerDoc)
					.then(() => {
						if (teamID)
							fireStore
								.collection('teams')
								.doc(teamID)
								.update({
									players: firebase.firestore.FieldValue.arrayUnion(playerRef),
								})
								.then(() => {
									toast.info(t('portal.create.newPlayerCreated'));
									if (closeAction) closeAction();
								})
								.catch(toast.error);
						else {
							toast.info(t('portal.create.newPlayerCreated'));
							if (closeAction) closeAction();
						}
					});
			}
		};
		addPlayer();
	};

	return (
		<>
			<Helmet>
				<title>{t('portal.create.player')}</title>
			</Helmet>
			{!frame && <PortalHeader />}
			<Styled.ModalHeading>{t('portal.create.player')}</Styled.ModalHeading>

			<Styled.Center>
				<form onSubmit={createPlayer}>
					<Styled.ButtonWrapper>
						<Input name="firstName" type="text" label={t('portal.common.firstName')} required />
						<Input name="middleName" type="text" label={t('portal.common.insertion')} />
						<Input name="lastName" type="text" label={t('portal.common.lastName')} required />
					</Styled.ButtonWrapper>
					<Input name="birthDate" type="date" label={t('portal.common.birthday')} />
					<Input name="backNumber" type="number" label={t('portal.common.backnumber')} />
					<Input
						name="knvbNumber"
						type="text"
						label={t('portal.common.KNVB')}
						value={KNVB}
						onChange={(e) => setKNVB(e.target.value.toUpperCase())}
						minLength={7}
						maxLength={7}
					/>
					<Input name="size" type="number" label={t('portal.common.length')} />
					<br />
					<b>
						<label>{t('portal.common.position')}</label>
					</b>
					<br />
					<Checkbox
						name="attack"
						label={t('portal.common.attack')}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAttack(e.target.checked)}
					/>
					<Checkbox
						name="middleGround"
						label={t('portal.common.middleGround')}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMiddleGround(e.target.checked)}
					/>
					<Checkbox
						name="defence"
						label={t('portal.common.defence')}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDefence(e.target.checked)}
					/>
					<Checkbox
						name="keeper"
						label={t('portal.common.keeper')}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setKeeper(e.target.checked)}
					/>
					<br />
					<b>
						<label>{t('portal.common.leftOrRight')}</label> <br />
					</b>
					<Checkbox
						name="left"
						label={t('portal.common.left')}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLeft(e.target.checked)}
					/>
					<Checkbox
						name="right"
						label={t('portal.common.right')}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRight(e.target.checked)}
					/>
					<br />
					<b>
						<label>{t('portal.common.team')}</label> <br />
					</b>
					<Styled.SelectWrapper>
						<Styled.Select
							name="team"
							onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setTeamId(e.target.value)}
						>
							<option value="">{t('portal.empty.team')}</option>
							{teams !== undefined &&
								teams.map((team) => (
									<option key={team.__id} value={team.__id}>
										{team.name}
									</option>
								))}
						</Styled.Select>
						<ChevronDown />
					</Styled.SelectWrapper>
					<p>{t('portal.create.connectPlayer')}</p>
					<br />
					<br />
					<Checkbox
						name="guest"
						label={t('portal.common.guestPlayer')}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGuest(e.target.checked)}
					/>

					<Input name="bio" type="text" label={t('portal.common.remarks')} />
					<Styled.ButtonWrapper>
						<div onClick={closeAction}>{t('portal.common.cancel')}</div>
						<Button>{t('portal.create.player')}</Button>
					</Styled.ButtonWrapper>
				</form>
			</Styled.Center>
			{!frame && <Footer />}
		</>
	);
};

export default Create;
