import { colors } from './colors';

export const table = {
	header: {
		style: {
			display: 'none',
		},
	},
	headRow: {
		style: {
			backgroundColor: colors.blue,
			minHeight: '52px',
			borderRadius: '10px 10px 0 0',
		},
	},
	headCells: {
		style: {
			color: '#ffffff',
			paddingLeft: '20px', // override the cell padding for head cells
			paddingRight: '20px',
		},
		activeSortStyle: {
			color: '#c9c9c9',
			'&:focus': {
				outline: 'none',
			},
			'&:hover:not(:focus)': {
				color: '#c9c9c9',
			},
		},
		inactiveSortStyle: {
			content: '',
			'&:focus': {
				outline: 'none',
				color: '#ebebeb',
			},
			'&:hover': {
				color: '#ebebeb',
				cursor: 'pointer',
			},
		},
	},
	cells: {
		style: {
			paddingLeft: '20px', // override the cell padding for data cells
			paddingRight: '20px',
		},
	},
	rows: {
		// style: {
		// 	// minHeight: "72px", // override the row height
		// },
		stripedStyle: {
			content: '',
			'&:nth-of-type(odd)': {
				color: colors.textColor,
				backgroundColor: colors.background,
			},
		},
	},
};

export default table;
