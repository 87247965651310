import styled from 'styled-components';

import type { StyledProps } from './types';

export const StyledLabelWrapper = styled.label`
	position: relative;
	margin: auto;
	width: 100%;
	max-width: 280px;
	border-radius: 3px;
	overflow: hidden;
	margin-top: 20px;
	display: block;
	background: none;
`;

export const StyledLabel = styled.span`
	position: absolute;
	top: 20px;
	left: 12px;
	font-size: 16px;
	color: rgba(#222, 0.5);
	font-weight: 500;
	transform-origin: 0 0;
	transform: translate3d(0, 0, 0);
	transition: all 0.2s ease;
	pointer-events: none;
`;

export const StyledFocusBg = styled.span`
	position: absolute;
	top: 0px;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(#222, 0.05);
	z-index: -1;
	transform: scaleX(0.3);
	transform-origin: left;
`;

export const StyledInput = styled.input<StyledProps>`
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	border: 0;
	font-family: inherit;
	padding: 16px 12px 0 12px;
	height: 56px;
	font-size: 16px;
	font-weight: 400;
	background: none;
	box-shadow: inset 0 -1px 0 rgba(#222, 0.3);
	color: #222;
	transition: all 0.15s ease;
	box-shadow: inset 0 -1px 0 ${({ theme: { colors } }) => colors.blue};

	&:hover {
		background: rgba(#222, 0.04);
		box-shadow: inset 0 -1px 0 rgba(#222, 0.5);
	}

	::placeholder,
	::-webkit-input-placeholder {
		color: transparent;
	}
	:-ms-input-placeholder {
		color: transparent;
	}

	&:not(:placeholder-shown) {
		+ ${StyledLabel} {
			color: rgba(#222, 0.5);
			transform: translate3d(0, -16px, 0) scale(0.75);
		}
	}

	${({ edge }) =>
		edge &&
		`
	+ ${StyledLabel} {
		transform: translate3d(0, -16px, 0) scale(0.75);
	}
`}

	&:focus {
		background: rgba(#222, 0.05);
		outline: none;
		box-shadow: inset 0 -2px 0 ${({ theme: { colors } }) => colors.blue};
		+ ${StyledLabel} {
			color: ${({ theme: { colors } }) => colors.blue};
			transform: translate3d(0, -16px, 0) scale(0.75);
			+ ${StyledFocusBg} {
				transform: scaleX(1);
				transition: all 0.1s ease;
			}
		}

		::placeholder,
		::-webkit-input-placeholder {
			color: lightgrey;
		}
		:-ms-input-placeholder {
			color: lightgrey;
		}
	}
`;
