import styled from 'styled-components';

export const Container = styled.section`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 50px;
	position: relative;
	@media only screen and (max-width: ${({ theme: { layout } }) => layout.screenSizes.medium}) {
		margin-top: 0px;
	}
`;

export const Heading = styled.h1`
	font-size: ${({ theme: { fonts } }) => fonts.sizes.huge};
	font-weight: ${({ theme: { fonts } }) => fonts.weights.bolder};
	display: flex;
	justify-content: center;
	margin-bottom: ${({ theme: { layout } }) => layout.margins.sectionTitle};
	text-align: center;
`;

export const SmallHeading = styled.h2`
	margin-top: 50px;
`;

export const Text = styled.div`
	font-size: ${({ theme: { fonts } }) => fonts.sizes.normal};
	font-weight: ${({ theme: { fonts } }) => fonts.weights.normal};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 45%;
	@media only screen and (max-width: ${({ theme: { layout } }) => layout.screenSizes.big}) {
		max-width: 85%;
	}
`;

export const Circle = styled.div`
	position: absolute;
	bottom: 000px;
	left: -150px;
	background: transparent;
	width: 750px;
	height: 750px;
	border-radius: 500px;
	border: 150px solid rgba(41, 119, 204, 0.14);
	z-index: -1;

	@media only screen and (max-width: 610px) {
		width: 550px;
		height: 550px;
		border: 100px solid rgba(41, 119, 204, 0.14);
	}

	@media only screen and (max-width: 420px) {
		width: 350px;
		height: 350px;
		border: 70px solid rgba(41, 119, 204, 0.14);
	}
`;

export const Spacer = styled.div`
	margin-bottom: 150px;
`;

export const Testimonial = styled.div`
	margin-top: 20px;
	margin-bottom: 20px;
	max-width: 45%;
`;

export const CarouselWrapper = styled.div`
	overflow: hidden;
	width: 100%;

	.BrainhubCarousel__dots .BrainhubCarousel__dot {
		background: transparent;
		&.BrainhubCarousel__dot--selected {
			transform: scale(1.4);
		}
	}
`;
