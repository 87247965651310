import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';
import type { Props, StateProps } from './types';

import { logoWhite, logoBlue } from 'src/assets';
import { BurgerMenu, LanguageSwitcher, ProfileDropdown } from 'src/components';
import type { RootState } from 'src/redux';

export const Header: React.FC<Props> = ({ isLoggedIn }: Props) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [visible, setVisible] = useState(false);
	const [logo, setLogo] = useState(false);

	useEffect(() => {
		if (window.location.pathname.length <= 4) {
			window.addEventListener('scroll', handleScroll);
		} else {
			setVisible(true);
			setLogo(true);
		}
	}, []);

	const handleScroll = () => {
		const currentScrollPos = window.pageYOffset;
		let isVisible = false;
		window.screen.width <= 998 ? (isVisible = currentScrollPos > 500) : (isVisible = currentScrollPos > 100);
		setVisible(isVisible);
		setLogo(isVisible);
	};
	const closeMenu = () => {
		setOpen(false);
	};

	return (
		<header>
			<Styled.NavBar open={open} bg={visible}>
				{window.screen.width <= 992 ? (
					<Link to="/" onClick={() => window.scrollTo(0, 0)}>
						<Styled.Logo src={logoBlue} width="40px" open={open} />
					</Link>
				) : (
					<Link to="/" onClick={() => window.scrollTo(0, 0)}>
						<Styled.Logo src={logo ? logoBlue : logoWhite} width="40px" open={open} />
					</Link>
				)}

				<Styled.NavList open={open} bg={visible}>
					
					<li onClick={() => closeMenu()}>
						<HashLink to="/#home" className="active link">
							{t('website.header.home')}
						</HashLink>
					</li>
					<li onClick={() => closeMenu()}>
						<HashLink to="/#features" className="link">
							{t('website.header.features')}
						</HashLink>
					</li>
					<li onClick={() => closeMenu()}>
						<HashLink to="/#prijs" className="link">
							{t('website.header.price')}
						</HashLink>
					</li>
					<li onClick={() => closeMenu()}>
						<HashLink to="/#download" className="link">
							{t('website.header.download')}
						</HashLink>
					</li>
					<li onClick={() => closeMenu()}>
						<Link to="/about" className="link" onClick={() => window.scrollTo(0, 0)}>
							{t('website.header.aboutUs')}
						</Link>
					</li>
					<li>
						<a href="https://www.facebook.com/sportassistantmanager/" target="_blank" rel="noreferrer">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z"
									fill="currentColor"
								/>
							</svg>
						</a>
					</li>
					<Styled.LanguageWrapper bg={visible} open={open}>
						<Styled.Divider bg={visible} open={open} className={'leftDivider'} />
						<LanguageSwitcher />
					</Styled.LanguageWrapper>
				</Styled.NavList>
				<Styled.AccountButtons open={open} bg={visible}>
					{isLoggedIn ? (
						<Styled.ProfileContainer open={open} bg={visible}>
							<ProfileDropdown />
							<Link to="/portal" className="link">
								{t('website.header.portal')}
							</Link>
						</Styled.ProfileContainer>
					) : (
						<>
							{/*<Link to="/signup" className="link">
								{t('website.header.register')}
					</Link>*/}
							<Link to="/login" className="link">
								{t('website.header.login')}
							</Link>
						</>
					)}
				</Styled.AccountButtons>
				<BurgerMenu open={open} setOpen={setOpen} visible={visible}></BurgerMenu>
			</Styled.NavBar>
		</header>
	);
};

const mapStateToProps = (state: RootState): StateProps => ({
	isLoggedIn: !state.firebase.auth.isEmpty,
});

export default connect(mapStateToProps)(Header);
