import styled from 'styled-components';

import { borderBottom, headerBG } from 'src/assets';

export const Container = styled.section`
	background: linear-gradient(
		${({ theme: { colors } }) => colors.lightBlue},
		${({ theme: { colors } }) => colors.blue}
	); /* fallback */
	background-image: url(${headerBG}); /* fallback */
	background-image: linear-gradient(180deg, rgba(48, 99, 154, 1) 0%, rgba(48, 99, 154, 0.8) 100%), url(${headerBG});
	background-position: center;
	background-size: cover;
	position: relative;
	background-repeat: no-repeat;
	height: 106vh;
	width: 100%;
	display: flex;
	color: white;
	padding: 0 5% 0 5%;
	flex-direction: row;
	align-items: center;
	margin-bottom: ${({ theme: { layout } }) => layout.margins.section};

	&::after {
		content: '';
		position: absolute;
		bottom: -5px;
		left: 0;
		width: 100%;
		height: 25vh;
		background-image: url(${borderBottom});
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 0;
	}

	@media only screen and (max-width: ${({ theme: { layout } }) => layout.screenSizes.small}) {
		&::after {
			height: 120px;
		}
	}
	/* @media only screen and (max-width: ${({ theme: { layout } }) => layout.size.tabletL}) {
		height: 110vh !important;
		&::after {
			height: 260px !important;
		}
	} */
	@media only screen and (max-width: ${({ theme: { layout } }) => layout.size.tablet}) {
		height: unset;
		padding-top: 100px;
		padding-bottom: 50px;
		&::after {
			display: none;
		}
	}

	@media (orientation: landscape) and (max-height: 550px) {
		&::after {
			display: none;
		}
	}
`;

export const Header = styled.h1`
	font-size: ${({ theme: { fonts } }) => fonts.sizes.huge};
	font-weight: ${({ theme: { fonts } }) => fonts.weights.bolder};
	max-width: 700px;

	@media only screen and (max-width: ${({ theme: { layout } }) => layout.size.laptopXL}) {
		width: 80%;
		max-width: auto;
	}
`;

export const SubText = styled.p`
	max-width: 600px;
	font-size: ${({ theme: { fonts } }) => fonts.sizes.normal};

	@media only screen and (max-width: ${({ theme: { layout } }) => layout.size.laptopXL}) {
		width: 80%;
		max-width: auto;
	}
`;

export const PhonesContainer = styled.div`
	z-index: 1;
	position: absolute;
	width: 100%;
	height: 150%;
	top: 0;
	left: 0;
	overflow: hidden;
	user-select: none;
	-webkit-user-drag: none;
	pointer-events: none;

	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		display: none;
	}
`;

export const Phones = styled.div`
	z-index: 1;
	width: 85%;
	flex-grow: 0;
	flex-shrink: 0;
	position: absolute;
	right: 0;
	top: 175px;
	/* display: flex;
	justify-content: flex-end; */

	@media only screen and (max-height: 900px) {
		width: 75%;
		height: 70%;
	}
`;

export const Phone = styled.img`
	height: 100%;
	width: auto;
	margin-right: 0px;
`;
