import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';

import { PricingTable } from 'src/components';

const Price: React.FC = () => {
	const { t } = useTranslation();
	return (
		<Styled.Container id="prijs">
			<Styled.Heading
				className="aos-init"
				data-aos="animate"
				data-aos-offset="600"
				data-aos-duration="600"
				data-aos-once="true"
			>
				{t('website.price.title')}
			</Styled.Heading>
			<PricingTable />
			<Styled.Section>
				<Styled.Text>{t('website.price.description')}</Styled.Text>
			</Styled.Section>
		</Styled.Container>
	);
};

export default Price;
