/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TrashEmpty } from 'css.gg';
import { toast } from 'react-toastify';
import { ImpulseSpinner } from 'react-spinners-kit';
import Tippy from '@tippyjs/react';

import 'tippy.js/dist/tippy.css';
import * as Styled from './styles';
import type { Props, StateProps, FirestoreConnectProps } from './types';

import { Modal, GreyedOut, PortalHeader, Footer, Link, Button } from 'src/components';
import { table, colors } from 'src/styles';
import { fireRemoteConfig, fireStore } from 'src/utils';
import type { Team, PermissionUser } from 'src/types';
import { Tier } from 'src/types/tier.d';
import { TeamCreate, TeamEdit, TeamDelete } from 'src/pages';
import { changeCurrentClub, changeCurrentTeam, RootState, store } from 'src/redux';
import { config } from 'src/config';
import { Permission } from 'src/types/permission.d';
import { exit } from 'process';

// import firebase from 'firebase';

export const Overview: React.FC<Props> = ({ user, club, teams, emptyClubTeams, currentClub }: Props) => {
	const { t } = useTranslation();
	const [modalVisibility, setModalVisibility] = useState(false);
	const [modalContent, setModalContent] = useState<string | undefined>();
	const [selectedTeam, setSelectedTeam] = useState<Team | undefined>();
	const [maximumFreeTeams, setMaximumFreeTeams] = useState<number>(
		config.firebaseRemoteConfig.defaults.MAXIMUM_FREE_TEAMS,
	);
	let { clubID, teamID } = useParams<{ clubID?: string; teamID?: string }>();
	if(clubID=='Q2CKYOiXWon6cjxtt7Ii')clubID='RnscvMJ8GUm0Z4K1E2S1';
	if(!teamID)teamID=null;

	store.dispatch(changeCurrentClub(clubID));
	store.dispatch(changeCurrentTeam(teamID));

	const OpenModal = (content: string) => {
		setModalVisibility(true);
		setModalContent(content);
	};
	const CloseModal = () => {
		setModalVisibility(false);
		setModalContent(undefined);
	};

	useEffect(() => {
		let mounted = true;

		fireRemoteConfig.fetchAndActivate().then(() => {
			if (mounted) {
				setMaximumFreeTeams(fireRemoteConfig.getValue('MAXIMUM_FREE_TEAMS').asNumber());
			}
		});

		return () => {
			mounted = false;
		};
	}, []);

	const freeStyles = [
		{
			when: (team: Team) => team.tier === Tier.FREE,
			style: {
				backgroundColor: '#dedede',
				color: 'black',
			},
		},
	];
	
	const { i18n } = useTranslation();

	const columns = [
		{
			name: `${t('portal.common.name')}`,
			selector: 'name',
			sortable: true,
		},
		{
			name: `${t('portal.common.players')}`,
			selector: 'players',
			cell: (team: Team) => (
				<>
					<Styled.Spacer>{team.players.length}</Styled.Spacer>
					{/*{team.tier !== Tier.FREE ? (
						<Link link={`/portal/club/${club.__id}/teams/${team.__id}/players`} small>
							{t('portal.common.manage')}
						</Link>
					) : null}*/}
					
				</>
			),
			sortable: true,
		},
		{
			name: `${t('portal.common.trainers')}`,
			selector: 'users',
			cell: (team: Team) => (
				<>
					<Styled.Spacer>{team._users.length}</Styled.Spacer>
					{team.tier !== Tier.FREE ? (
						team._permissionsUsers.filter((item) => item.user.id === user.uid).length > 0 ? (
							<Link link={`/portal/club/${club.__id}/teams/${team.__id}/trainers`} small>
								{t('portal.common.manage')}
							</Link>
						) : null
					) : null}
				</>
			),
			sortable: true,
		},
		{
			name: `${t('portal.common.season')}`,
			selector: 'season',
			sortable: true,
		},
		{
			name: `${t('portal.common.type')}`,
			selector: 'tier',
			cell: (team: Team) => (
				<>{team.tier === Tier.FREE ? `${t('portal.common.free')}` : `${t('portal.common.premium')}`}</>
			),
			sortable: true,
		},
		{			
			name: `${t('portal.common.settings')}`,
			cell: (team: Team) =>
				team.tier === Tier.PREMIUM ? (
					<Button
						action={() => {
							setSelectedTeam(team);
							OpenModal('TeamEdit');
						}}
						small
					>
						{t('portal.common.settings')}
					</Button>
				) : (
					<a href={`https://ext.samonline.nl/upgradeTeam?WS&lang=${i18n.language}&teamID=${team.__id}`}>
					{/*<Link small link="https://ext.samonline.nl/upgradeTeam">*/}
						{t('portal.common.upgrade')}
					</a>
				),
		},
		{
			name: `${t('portal.common.delete')}`,
			sortable: false,
			cell: (team: Team) =>
				team._permissionsUsers.filter(
					(_permissionUser: PermissionUser) =>
						_permissionUser.user.id === user.uid && _permissionUser.type === Permission.OWNER,
				).length > 0 ? (
					<Styled.IconButton
						onClick={() => {
							setSelectedTeam(team);
							OpenModal('TeamDelete');
						}}
					>
						<TrashEmpty />
					</Styled.IconButton>
				) : (
					<Tippy content={t('portal.errors.notOwnerOfTeam')} interactive={true} placement={'left'}>
						<div>
							<GreyedOut>
								<TrashEmpty />
							</GreyedOut>
						</div>
					</Tippy>
				),
			minWidth: '100px',
			center: true,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];

console.log('******************************club:',club);
	if (isLoaded(club) && isLoaded(teams) && isLoaded(emptyClubTeams)) {
		if (isEmpty(club)) {
			toast.error(t('portal.errors.selectClubFirst'), {
				toastId: 'select-club',
			});
			return <Redirect to={`/portal`} />;
		}

		const freeTeams = teams.filter((team) => team.tier === Tier.FREE);
		const editableTeams: Array<Team> = [];
		const allTeams = teams.concat(emptyClubTeams);
		
				
		allTeams.forEach((_team: Team) => {
			_team._permissionsUsers.forEach((_user: PermissionUser) => {				
				//console.log('sssssssssssssssssssssssssssss1s',_user);
				if (_user.user!=null && _user.user.id === user.uid)
					if (_user.type === Permission.OWNER || _user.type === Permission.MANAGE) editableTeams.push(_team);
			})
		});

		// const fixUsers = async () => {
		// 	console.log('Fixing...');

		// 	const DRY_RUN = false;

		// 	const userIds = ['qTVgfsm90TMEbDOFC5vpsrbqbnC3', 'l2JC6P3YJLNliMrzzJFhAeNbx2d2', 'D79WYMuxrwhBUAEPblKe7Ot9z7V2'];

		// 	userIds.forEach(async (userId) => {
		// 		const getTeamsRef = fireStore
		// 			.collection('teams')
		// 			.where('_users', 'array-contains', fireStore.collection('users').doc(userId));

		// 		const getTeamsDocs = await getTeamsRef.get();

		// 		const teamIds = getTeamsDocs.docs.map((snapshot) => snapshot.id);

		// 		teamIds.forEach(async (teamId) => {
		// 			const teamRef = fireStore.collection('teams').doc(teamId);

		// 			const teamDoc = (await teamRef.get()).data();

		// 			if (!teamDoc) return;

		// 			const hasClub = !!teamDoc._club?.id;
		// 			const clubRef = hasClub ? fireStore.collection('clubs').doc(teamDoc._club.id) : undefined;

		// 			teamDoc.players.forEach(async (playerID: any) => {
		// 				const playerId = playerID.id;
		// 				const playerRef = fireStore.collection('players').doc(playerId);
		// 				const playerDoc = await playerRef.get();
		// 				const playerData = playerDoc.data();

		// 				if (!playerData) {
		// 					if (!DRY_RUN) {
		// 						teamRef.update({
		// 							players: firebase.firestore.FieldValue.arrayRemove(playerID),
		// 						});
		// 					} else {
		// 						console.log(`PlayerID: ${playerId} - No data/no doc`);
		// 					}

		// 					return;
		// 				}

		// 				const hasClubs = playerData._clubs !== null && playerData._clubs.length !== 0;
		// 				const hasBaseTeams = playerData._baseTeams !== null && playerData._baseTeams.length !== 0;
		// 				const hasGuestTeams = playerData._guestTeams !== null && playerData._guestTeams.length !== 0;

		// 				if (hasClub) {
		// 					if (!hasClubs && hasClub && !hasBaseTeams && !hasGuestTeams) {
		// 						// update base and club
		// 						if (!DRY_RUN) {
		// 							playerRef.update({
		// 								_clubs: [clubRef],
		// 								_baseTeams: [teamRef],
		// 							});
		// 						} else {
		// 							console.log(`PlayerID: ${playerId} - Update base and club`);
		// 						}
		// 					} else if (!hasClubs && hasClub && hasBaseTeams) {
		// 						// update club
		// 						if (!DRY_RUN) {
		// 							playerRef.update({
		// 								_clubs: [clubRef],
		// 							});
		// 						} else {
		// 							console.log(`PlayerID: ${playerId} - Update club`);
		// 						}
		// 					}
		// 				} else {
		// 					if (!hasBaseTeams && !hasGuestTeams) {
		// 						// update base
		// 						if (!DRY_RUN) {
		// 							playerRef.update({
		// 								_baseTeams: [teamRef],
		// 							});
		// 						} else {
		// 							console.log(`PlayerID: ${playerId} - Update base`);
		// 						}
		// 					}
		// 				}
		// 			});

		// 			teamDoc.matches.forEach(async (matchID: any) => {
		// 				const matchId = matchID.id;
		// 				const matchRef = fireStore.collection('matches').doc(matchId);
		// 				const matchDoc = await matchRef.get();
		// 				const matchData = matchDoc.data();

		// 				if (!matchData) {
		// 					if (!DRY_RUN) {
		// 						teamRef.update({
		// 							matches: firebase.firestore.FieldValue.arrayRemove(matchID),
		// 						});
		// 					} else {
		// 						console.log(`MatchID: ${matchId} - No data/no doc`);
		// 					}

		// 					return;
		// 				}

		// 				const hasTeams = matchData._teams !== null && matchData._teams.length !== 0;

		// 				if (!hasTeams) {
		// 					if (!DRY_RUN) {
		// 						matchRef.update({
		// 							__deleted: matchData.__deleted || false,
		// 							__id: matchId,
		// 							_teams: [teamRef],
		// 							// basePlayers: [],
		// 							// events: [],
		// 							// opponentChanges: [],
		// 							// playerChanges: [],
		// 							// substitutePlayers: [],
		// 						});
		// 					} else {
		// 						console.log(`MatchID: ${matchId} - Update teams`);
		// 					}
		// 				} else if (matchData._teams.length > 1) {
		// 					if (!DRY_RUN) {
		// 						matchRef.update({
		// 							__deleted: matchData.__deleted || false,
		// 							__id: matchId,
		// 							_teams: firebase.firestore.FieldValue.arrayUnion(teamRef),
		// 							// basePlayers: [],
		// 							// events: [],
		// 							// opponentChanges: [],
		// 							// playerChanges: [],
		// 							// substitutePlayers: [],
		// 						});
		// 					} else {
		// 						console.log(`MatchID: ${matchId} - Update teams`);
		// 					}
		// 				}

		// 				if (matchData.basePlayers === null) {
		// 					matchRef.update({
		// 						basePlayers: [],
		// 					});
		// 				}
		// 				if (matchData.events === null) {
		// 					matchRef.update({
		// 						events: [],
		// 					});
		// 				}
		// 				if (matchData.substitutePlayers === null) {
		// 					matchRef.update({
		// 						substitutePlayers: [],
		// 					});
		// 				}
		// 				if (matchData.playerChanges === null) {
		// 					matchRef.update({
		// 						playerChanges: [],
		// 					});
		// 				}
		// 				if (matchData.opponentChanges === null) {
		// 					matchRef.update({
		// 						opponentChanges: [],
		// 					});
		// 				}
		// 				if (matchData.dateTime === null) {
		// 					matchRef.update({
		// 						dateTime: new Date(),
		// 					});
		// 				}
		// 			});

		// 			teamDoc.trainings.forEach(async (trainingID: any) => {
		// 				const trainingId = trainingID.id;
		// 				const trainingRef = fireStore.collection('trainings').doc(trainingId);
		// 				const trainingDoc = await trainingRef.get();
		// 				const trainingData = trainingDoc.data();

		// 				if (!trainingData) {
		// 					if (!DRY_RUN) {
		// 						teamRef.update({
		// 							trainings: firebase.firestore.FieldValue.arrayRemove(trainingId),
		// 						});
		// 					} else {
		// 						console.log(`TrainingID: ${trainingId} - No data/no doc`);
		// 					}

		// 					return;
		// 				}

		// 				const hasTeams = trainingData._teams !== null && trainingData._teams.length !== 0;

		// 				if (!hasTeams) {
		// 					if (!DRY_RUN) {
		// 						trainingRef.update({
		// 							__deleted: trainingData.__deleted || false,
		// 							__id: trainingId,
		// 							_teams: [teamRef],
		// 							// presences: [],
		// 						});
		// 					} else {
		// 						console.log(`TrainingID: ${trainingId} - Update teams`);
		// 					}
		// 				} else if (trainingData._teams.length > 1) {
		// 					if (!DRY_RUN) {
		// 						trainingRef.update({
		// 							__deleted: trainingData.__deleted || false,
		// 							__id: trainingId,
		// 							_teams: firebase.firestore.FieldValue.arrayUnion(teamRef),
		// 							// presences: [],
		// 						});
		// 					} else {
		// 						console.log(`TrainingID: ${trainingId} - Update teams`);
		// 					}
		// 				}

		// 				if (trainingData.presences === null) {
		// 					trainingRef.update({
		// 						presences: [],
		// 					});
		// 				}
		// 				if (trainingData.dateTime === null) {
		// 					trainingRef.update({
		// 						dateTime: new Date(),
		// 					});
		// 				}
		// 			});
		// 		});
		// 	});
		// };

		return (
			<>
				<Helmet>
					<title>{t('portal.common.allTeams')}</title>
				</Helmet>
				<PortalHeader />
				<Styled.Wrapper>
					<Styled.Container>
						{editableTeams.length > 0 ? (
							<>
								<Styled.HeaderWrapper>
									<Styled.Heading>{t('portal.common.allTeams')}</Styled.Heading>
									{/*{club._permissionsUsers.find(
										(_user: PermissionUser) => _user.user.id === user.uid && _user.type === Permission.OWNER,
									) ? (
										<Styled.ButtonWrapper>
											{maximumFreeTeams > freeTeams.length ? (
												<Button action={() => OpenModal('TeamCreate')} color="orange">
													+{t('portal.create.team')}
											</Button>
											) : (
												<Tippy
													content={`${t('portal.errors.maximumFreeTeamsReached')} (${maximumFreeTeams})`}
													interactive={true}
													placement={'left'}
												>
													<div>
														<GreyedOut>
															<Button color="orange">{t('portal.create.team')}</Button>
														</GreyedOut>
													</div>
												</Tippy>
											)}
										</Styled.ButtonWrapper>
									) : (
										<Styled.ButtonWrapper>
											<Tippy content={t('portal.errors.teamCreateNotOwner')} interactive={true} placement={'left'}>
												<div>
													<GreyedOut>
														<Button color="orange">{t('portal.create.team')}</Button>
													</GreyedOut>
												</div>
											</Tippy>
										</Styled.ButtonWrapper>
									)}*/}
								</Styled.HeaderWrapper>

								<Styled.TableWrapper>
									{editableTeams !== null && (
										<DataTable
											columns={columns}
											striped={true}
											customStyles={table}
											data={editableTeams}
											keyField="__id"
											conditionalRowStyles={freeStyles}
										/>
									)}
								</Styled.TableWrapper>
							</>
						) : (
							<Styled.InformationContainer>
								{club.__id &&
								club._permissionsUsers.find(
									(_user) => _user.user.id === user.uid && _user.type === Permission.OWNER,
								) ? (
									<>
										<h1>{t('portal.empty.teams')}</h1>
										<p>{t('portal.empty.teamsSubtitle')}</p>
										{/*<Button action={() => OpenModal('TeamCreate')} color="orange">
											{t('portal.create.team')}
										</Button>*/}
										
									</>
								) : (
									<>
										<h1>{t('portal.empty.noTeamsAssigned')}</h1>
										<p>{t('portal.empty.askRights')}</p>
									</>
								)}
							</Styled.InformationContainer>
						)}

						{/* <button onClick={() => fixUsers()}>Crash the DB</button> */}
					</Styled.Container>
				</Styled.Wrapper>

				<Modal modalVisibility={modalVisibility} closeAction={CloseModal}>
					{modalContent === 'TeamCreate' && <TeamCreate closeAction={CloseModal} frame currentClub={currentClub} />}
					{modalContent === 'TeamEdit' && selectedTeam && (
						<TeamEdit team={selectedTeam} closeAction={CloseModal} frame />
					)}
					{modalContent === 'TeamDelete' && selectedTeam && (
						<TeamDelete team={selectedTeam} closeAction={CloseModal} frame />
					)}
				</Modal>
				<Footer />
			</>
		);
	} else
		return (
			<>
				<PortalHeader />
				<Styled.LoaderContainer>
					<ImpulseSpinner size={80} frontColor={colors.blue} backColor={colors.grey} />
				</Styled.LoaderContainer>
				<Footer />
			</>
		);
};

const mapStateToProps = (state: RootState): StateProps => ({
	currentClub: state.app.currentClub,
	uid: state.firebase.auth.uid,
	user: state.firebase.profile,
	club: state.firestore.data.currentClub,
	teams: state.firestore.ordered.teams,
	emptyClubTeams: state.firestore.ordered.emptyClubTeams,
});

export default compose(
	connect(mapStateToProps),
	firestoreConnect(({ uid, currentClub }: FirestoreConnectProps) => {
		if (currentClub)
			return [
				{
					collection: 'clubs',
					storeAs: 'currentClub',
					doc: currentClub,
				},
				{
					collection: 'teams',
					storeAs: 'teams',
					where: [
						['__deleted', '==', false],
						['_club', '==', fireStore.doc(`clubs/${currentClub}`)],
					],
				},
				{
					collection: 'teams',
					storeAs: 'emptyClubTeams',
					where: [
						['__deleted', '==', false],
						['_club', '==', null],
						['_users', 'array-contains', fireStore.doc(`users/${uid}`)],
					],
				},
			];
		else
			return [
				{
					collection: 'teams',
					storeAs: 'emptyClubTeams',
					where: [
						['__deleted', '==', false],
						['_club', '==', null],
						['_users', 'array-contains', fireStore.doc(`users/${uid}`)],
					],
				},
			];
	}),
)(Overview) as React.ComponentType;
