export const screenSizeSmall = 320;
export const screenSizeMedium = 768;
export const screenSizeLarge = 1200;

export const isSmallScreen = window.screen.width <= screenSizeSmall;
export const isMediumScreen = window.screen.width <= screenSizeMedium;
export const isLargeScreen = window.screen.width <= screenSizeLarge;

export const screenSizes = {
	small: `${screenSizeSmall}px`,
	medium: `${screenSizeMedium}px`,
	big: `${screenSizeLarge}px`,
};

export const margins = {
	section: isMediumScreen ? '60px' : '100px',
	feature: isMediumScreen ? '40px' : '80px',
	sectionTitle: isMediumScreen ? '20px' : '30px',
};

export const size = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	tabletL: '992px',
	laptop: '1024px',
	laptopM: '1250px',
	laptopL: '1440px',
	laptopXL: '1600px',
	desktop: '2560px',
};

export const device = {
	mobileS: `(max-width: ${size.mobileS})`,
	mobileM: `(max-width: ${size.mobileM})`,
	mobileL: `(max-width: ${size.mobileL})`,
	tablet: `(max-width: ${size.tablet})`,
	tabletL: `(max-width: ${size.tabletL})`,
	laptop: `(max-width: ${size.laptop})`,
	laptopM: `(max-width: ${size.laptopM})`,
	laptopL: `(max-width: ${size.laptopL})`,
	laptopXL: `(max-width: ${size.laptopXL})`,
	desktop: `(max-width: ${size.desktop})`,
};

export const footer = {
	height: 85,
};

export default {
	screenSizes,
	device,
	footer,
	size,
	margins,
	screenSizeLarge,
	screenSizeMedium,
	screenSizeSmall,
	isSmallScreen,
	isMediumScreen,
	isLargeScreen,
};
