import styled from 'styled-components';

import type { StyledProps } from './types';

export const Container = styled.div<StyledProps>`
	display: flex;
	width: 100%;
	height: ${({ frame, theme: { layout } }) => (frame ? 'auto' : `calc(100vh - ${layout.footer.height}px)`)};
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@media ${({ theme: { layout } }) => layout.device.mobileL} {
		padding: 20px;
	}
`;

export const Form = styled.form`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@media ${({ theme: { layout } }) => layout.device.mobileL} {
		display: block;
	}
`;

export const Link = styled.div`
	color: ${({ theme: { colors } }) => colors.textColor};
	font-weight: ${({ theme: { fonts } }) => fonts.weights.bold};
	text-decoration: underline;
`;

export const Label = styled.label`
	display: flex;
	flex-direction: column;

	@media ${({ theme: { layout } }) => layout.device.mobileL} {
		display: block;
	}
`;

export const Input = styled.input`
	padding: 12px 20px;
	border-radius: 50px;
	color: ${({ theme: { colors } }) => colors.textColor};
	outline: none;
	border: none;
	background: #ececec;
	margin: 5px 0 20px;
	transition: 0.3s ease all;
	width: 300px;

	@media ${({ theme: { layout } }) => layout.device.mobileL} {
		width: 100%;
	}
`;

export const Button = styled.button`
	padding: 12px 20px;
	background: ${({ theme: { colors } }) => colors.blue};
	border-radius: 50px;
	color: #fff;
	text-decoration: none;
	display: block;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 30px;
	border: none;
	font-size: ${({ theme: { fonts } }) => fonts.sizes.normal};
	transition: 0.3s ease all;
	min-width: 150px;
	cursor: pointer;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

	&:hover {
		background: ${({ theme: { colors } }) => colors.lightBlue};
	}
`;

export const Small = styled.div`
	font-size: 14px !important;
	text-align: center;
	p {
		font-size: 14px !important;
	}
`;
