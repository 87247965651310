import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import App from 'src/App';
import * as serviceWorker from 'src/serviceWorker';
import { fireApp, getTitleTemplate, history } from 'src/utils';
import { config } from 'src/config';
import { store } from 'src/redux';
import { Loader } from 'src/components';
import { theme, GlobalStyle } from 'src/styles';

import 'src/utils/i18n';
import 'aos/dist/aos.css';
import 'react-toastify/dist/ReactToastify.css';

// TODO: Translate metadata

render(
	<React.StrictMode>
		<Suspense fallback={<Loader />}>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<ReactReduxFirebaseProvider
						firebase={fireApp}
						config={config.reactReduxFirebase}
						dispatch={store.dispatch}
						createFirestoreInstance={createFirestoreInstance}
					>
						<ThemeProvider theme={theme}>
							<Helmet defaultTitle="SAM online" titleTemplate={getTitleTemplate()}>
								<meta
									name="description"
									content="SAM maakt het leven van een voetbaltrainer zoveel gemakkelijker! Papieren registratie is verleden tijd. De app houdt alles voor je bij, registreert en beheert. Alle info op één plek en gemakkelijk deelbaar. Efficiënt en super gebruiksvriendelijk. SAM is de digitale Sport Assistant Manager. En vanaf nu jouw onmisbare hulp in de voetbalsport."
								/>
							</Helmet>
							<ToastContainer />
							<GlobalStyle />
							<App />
						</ThemeProvider>
					</ReactReduxFirebaseProvider>
				</ConnectedRouter>
			</Provider>
		</Suspense>
	</React.StrictMode>,
	document.getElementById('root'),
);

serviceWorker.unregister();
