import React from 'react';
import { Redirect, RouteProps, Route } from 'react-router-dom';
import { ImpulseSpinner } from 'react-spinners-kit';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';

import * as Styled from './styles';

import { colors } from 'src/styles';
import { RootState } from 'src/redux';

export const PrivateRoute: React.FC<RouteProps> = ({ ...rest }: RouteProps) => {
	const auth = useSelector((state: RootState) => state.firebase.auth);

	if (!isLoaded(auth))
		return (
			<Styled.LoaderContainer>
				<ImpulseSpinner size={80} frontColor={colors.blue} backColor={colors.grey} />
			</Styled.LoaderContainer>
		);

	if (isEmpty(auth)) return <Redirect to="/login" />;

	return <Route {...rest} />;
};

export default PrivateRoute;
