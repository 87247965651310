import React, { useEffect } from 'react';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import AOS from 'aos';
import { useTranslation } from 'react-i18next';

import {
	Home,
	About,
	LogIn,
	SignUp,
	ForgotPassword,
	Portal,
	NotFound,
	ClubCreate,
	TeamOverview,
	PlayerOverview,
	TeamPlayersOverview,
	TrainerOverview,
	TrainerTeamOverview,
	PaymentOverview,
} from 'src/pages';
import { PrivateRoute } from 'src/components';

const RedirectToPrivacyPdf = () => {
	window.location.replace('/privacy-verklaring.pdf');

	return null;
};

const App: React.FC = () => {
	const { i18n } = useTranslation();

	const baseUrl = `/${i18n.language}`;

	AOS.init();

	useEffect(() => {
		AOS.refresh();
	});

	return (
		<BrowserRouter basename={baseUrl}>
			<Switch>
				{/* Home */}
				<Route exact path="/" component={Home} />

				{/* Privacy policy */}
				<Route path="/privacy" component={RedirectToPrivacyPdf} />
				<Route path="/privacy-verklaring" component={RedirectToPrivacyPdf} />
				<Route path="/privacyverklaring" component={RedirectToPrivacyPdf} />
				<Route path="/privacyverklaring.pdf" component={RedirectToPrivacyPdf} />
				<Route path="/Privacy-verklaring" component={RedirectToPrivacyPdf} />
				<Route path="/Privacy-verklaring.pdf" component={RedirectToPrivacyPdf} />
				<Route path="/Privacyverklaring" component={RedirectToPrivacyPdf} />
				<Route path="/Privacyverklaring.pdf" component={RedirectToPrivacyPdf} />
				<Route path="/privacy-policy" component={RedirectToPrivacyPdf} />
				<Route path="/privacypolicy" component={RedirectToPrivacyPdf} />
				<Route path="/privacypolicy.pdf" component={RedirectToPrivacyPdf} />
				<Route path="/Privacy-policy" component={RedirectToPrivacyPdf} />
				<Route path="/Privacy-policy.pdf" component={RedirectToPrivacyPdf} />
				<Route path="/Privacypolicy" component={RedirectToPrivacyPdf} />
				<Route path="/Privacypolicy.pdf" component={RedirectToPrivacyPdf} />

				{/* Login */}
				<Route path="/login/:key?/:userEmail?" component={LogIn} />
				<Redirect from="/log-in" to="/login" />
				<Redirect from="/inlog" to="/login" />
				<Redirect from="/inloggen" to="/login" />

				{/* Signup 				
				<Route path="/signup/:key?/:userEmail?" component={SignUp} />
				<Redirect from="/sign-up" to="/signup" />
				<Redirect from="/register" to="/signup" />
				<Redirect from="/registreren" to="/signup" />
				*/}

				
				<Redirect from="/sign-up" to="/signup" />
				<Redirect from="/register" to="/signup" />
				<Redirect from="/registreren" to="/signup" />
				
				<Route path="/signup" component={()=>{window.location.replace('https://ext.samonline.nl/signup'); return null;}} />
				<Route path="/:a/signup" component={()=>{window.location.replace('https://ext.samonline.nl/signup'); return null;}} />
				
				{/*}
				<Route path="/signup/:key?/:userEmail?" component={SignUp} />
				<PrivateRoute exact path="/signup" component={
					() => { 
						window.location.href = 'https://ext.samonline.nl/signup'; 
						return null;
				   }
				}/>

				<Route path="/signup/:key?/:userEmail?" component={SignUp} />
				*/}

				{/* Forgot password */}
				<Route path="/forgot-password" component={ForgotPassword} />
				<Redirect from="/forgotpassword" to="/forgot-password" />
				<Redirect from="/wachtwoord-vergeten" to="/forgot-password" />
				<Redirect from="/wachtwoordvergeten" to="/forgot-password" />

				{/* About */}
				<Route path="/about" component={About} />
				<Redirect from="/overons" to="/about" />
				<Redirect from="/over" to="/about" />
				<Redirect from="/over-ons" to="/about" />
				<Redirect from="/about-us" to="/about" />
				<Redirect from="/aboutus" to="/about" />

				{/* Portal */}
				<PrivateRoute exact path="/portal" component={Portal} />

				{/* Create club */}
				<PrivateRoute exact path="/portal/create-club" component={ClubCreate} />
				<Redirect from="/portal/clubCreate" to="/portal/create-club" />
				<Redirect from="/portal/createClub" to="/portal/create-club" />
				<Redirect from="/portal/makeClub" to="/portal/create-club" />
				<Redirect from="/portal/maakClub" to="/portal/create-club" />

				{/* Payments */}
				<PrivateRoute exact path="/portal/upgradeTeam" component={
					() => { 
						window.location.href = 'https://ext.samonline.nl/upgradeTeam'; 
						return null;
				   }
				} />

				<PrivateRoute exact path="/portal/payments" component={PaymentOverview} />
				{/*<PrivateRoute exact path="/portal/payments" component={
					() => { 
						window.location.href = 'https://ext.samonline.nl/upgradeTeam'; 
						return null;
				   }
				} />*/}
				<Redirect from="/portal/payment" to="/portal/payments" />
				<Redirect from="/portal/betalingen" to="/portal/payments" />
				<Redirect from="/portal/betaling" to="/portal/payments" />

				{/* Club routes 
				<PrivateRoute exact path="/portal/club/:clubID/teams" component={TeamOverview} />
				<PrivateRoute exact path="/portal/club/:clubID/players" component={PlayerOverview} />
				<PrivateRoute exact path="/portal/club/:clubID/teams/:teamID/players" component={TeamPlayersOverview} />
				<PrivateRoute exact path="/portal/club/:clubID/trainers" component={TrainerOverview} />
				<PrivateRoute exact path="/portal/club/:clubID/teams/:teamID/trainers" component={TrainerTeamOverview} />
				
				
				*/}
				<PrivateRoute exact path="/portal/club/:clubID/teams" component={TeamOverview} />
				<PrivateRoute exact path="/portal/club/:clubID/players" component={PlayerOverview} />
				<PrivateRoute exact path="/portal/club/:clubID/teams/:teamID/players" component={TeamPlayersOverview} />
				<PrivateRoute exact path="/portal/club/:clubID/trainers" component={TrainerOverview} />
				<PrivateRoute exact path="/portal/club/:clubID/teams/:teamID/trainers" component={TrainerTeamOverview} />

				{/* Not found */}
				<Route component={NotFound} />
			</Switch>
		</BrowserRouter>
	);
};

export default App;
