import React from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

import * as Styled from './styles';

import { img1Trophy, img2Ball } from 'src/assets';
import { Button } from 'src/components';

export const Features: React.FC = () => {
	const { t } = useTranslation();

	return (
		<Styled.Container id="features">
			<Styled.Heading
				className="aos-init"
				data-aos="animate"
				data-aos-offset="200"
				data-aos-duration="600"
				data-aos-once="true"
			>
				{t('website.features.title')}
			</Styled.Heading>
			<Styled.Feature
				className="aos-init"
				data-aos="animate"
				data-aos-offset="200"
				data-aos-duration="600"
				data-aos-once="true"
			>
				<Styled.FeatureImage>
					<img src={img1Trophy} alt="" />
				</Styled.FeatureImage>

				<Styled.FeatureInfo>
					<Styled.FeatureHeading>{t('website.features.section1.title')}</Styled.FeatureHeading>
					<p>{t('website.features.section1.description')}</p>
					<ul style={{ marginRight: 'auto' }}>
						<li>{t('website.features.section1.points.1')}</li>
						<li>{t('website.features.section1.points.2')}</li>
						<li>{t('website.features.section1.points.3')}</li>
						<li>{t('website.features.section1.points.4')}</li>
						<li>{t('website.features.section1.points.5')}</li>
						<li>{t('website.features.section1.points.6')}</li>
					</ul>
					<Button style={{ marginTop: '10px' }}>
						<HashLink to="/#prijs">{t('website.features.section1.button')}</HashLink>
					</Button>
				</Styled.FeatureInfo>
			</Styled.Feature>
			<Styled.Circle />
			<Styled.Feature
				reversed
				className="aos-init"
				data-aos="animate"
				data-aos-offset="600"
				data-aos-duration="600"
				data-aos-once="true"
			>
				<Styled.FeatureImage>
					<img src={img2Ball} alt="" />
				</Styled.FeatureImage>

				<Styled.FeatureInfo>
					<Styled.FeatureHeading>{t('website.features.section2.title')}</Styled.FeatureHeading>
					<ul style={{ marginRight: 'auto' }}>
						<li>{t('website.features.section2.points.1')}</li>
						<li>{t('website.features.section2.points.2')}</li>
						<li>{t('website.features.section2.points.3')}</li>
					</ul>
				</Styled.FeatureInfo>
			</Styled.Feature>
		</Styled.Container>
	);
};

export default Features;
