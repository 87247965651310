import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Styled from './styles';
import type { StateProps, Props, DispatchProps } from './types';

import { RootState, AppActionsTypes, INTRO, changeIntro } from 'src/redux';
import { fireApp } from 'src/utils';

export const ProfileDropdown: React.FC<Props> = ({ user, startsWithPortal, setIntro }: Props) => {
	const { t } = useTranslation();
	const [dropdownState, setDropdownState] = useState(false);
	const node = useRef();

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);
		return () => document.removeEventListener('mousedown', handleClick);
	}, []);

	const handleClick = (e: unknown) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (node.current.contains(e.target)) return;

		setDropdownState(false);
	};

	const changePasswordHandler = async () => {
		console.log('test');
		if (user.email) {
			try {
				await fireApp.auth().sendPasswordResetEmail(user.email);
			} catch {
				toast.error(t('portal.errors.general'));
			}
			toast.info(t('portal.auth.changePasswordSent'));
		} else {
			toast.error(t('portal.errors.general'));
		}
	};

	return (
		<Styled.Container ref={node}>
			<Styled.User onClick={() => setDropdownState(!dropdownState)}>
				{user.displayName || 'User'}
				<Styled.ChevronDown />
			</Styled.User>
			<Styled.Dropdown open={dropdownState}>
				{/*<Link to="/portal/payments">{t('website.header.payments')}</Link>*/}
				{startsWithPortal && <div onClick={() => setIntro('UNSEEN')}>{t('website.header.showIntro')}</div>}
				<div onClick={() => window.confirm(t('portal.auth.changePasswordConfirm')) && changePasswordHandler()}>
					{t('portal.auth.changePassword')}
				</div>
				<div
					onClick={() => {
						fireApp.auth().signOut();
						window.location.reload();
					}}
				>
					{t('website.header.logout')}
				</div>
			</Styled.Dropdown>
		</Styled.Container>
	);
};

const mapStateToProps = (state: RootState): StateProps => ({
	user: state.firebase.profile,
	startsWithPortal: state.router.location.pathname.startsWith('/portal'),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	setIntro: (value: INTRO): AppActionsTypes => dispatch(changeIntro(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown);
