import { default as i18n } from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from '../locales/en/translation.json';
import translationNL from '../locales/nl/translation.json';
import translationDE from '../locales/de/translation.json';
import translationFR from '../locales/fr/translation.json';

const resources = {
	en: {
		translation: translationEN,
	},
	nl: {
		translation: translationNL,
	},
	de: {
		translation: translationDE,
	},
	fr: {
		translation: translationFR,
	},
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: 'en',
		supportedLngs: ['en', 'nl', 'de', 'fr'],
		detection: {
			order: ['path', 'queryString', 'navigator', 'cookie'],
			lookupLocalStorage: 'i18nextLng',
			lookupQuerystring: 'lng',
			lookupCookie: 'i18next',
			cache: ['path', 'queryString', 'cookie'],
			lookupFromPathIndex: 0,
			checkWhitelist: true,
		},
		interpolation: {
			escapeValue: false,
		},
		react: {
			wait: false,
		},
	});

export default i18n;
