import { config } from 'src/config';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getUniqueListBy = (arr: any[], key: string): any => {
	return new Map(arr.map((item: { [x: string]: any }) => [item[key], item])).values();
};

export const truncate = (str: string, n: number): string => {
	return str.length > n ? str.substr(0, n - 1) + `…` : str;
};

export const splitName = (name: string): { first: string; middle: string | null; last: string } => {
	const firstComma = name.lastIndexOf(',');
	let first;
	let last;
	let middle;

	if (name.split(' ').length > 2) {
		const firstSpace = name.indexOf(' ', firstComma + 2);
		first = name.substring(firstComma + 2, firstSpace);
		last = name.substring(0, firstComma);
		middle = name.substring(firstSpace + 1);
	} else {
		first = name.substring(firstComma + 2);
		last = name.substring(0, firstComma);
		middle = null;
	}
	return {
		first,
		last,
		middle,
	};
};

export const getTitleTemplate = (): string => {
	switch (config.environment) {
		case 'development':
			return 'DEV | %s | SAM online';
		case 'staging':
			return 'Staging | %s | SAM online';
		case 'production':
		default:
			return '%s | SAM online';
	}
};
