import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';
import type { Props } from './types';

import { table } from 'src/styles';
import { Button } from 'src/components';
import { Confirm, GreyedOut } from 'src/components/global';
import { Team } from 'src/types';
import { config } from 'src/config';
import { fireRemoteConfig } from 'src/utils';

export const PaymentsDowngrade: React.FC<Props> = ({
	teams,
	clubs,
	user,
	currentPremiumAmount,
	confirmAction,
	serverStatus,
	...props
}: Props) => {
	const [selectedRows, setSelectedRows] = useState<Array<Team>>([]);
	const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);
	const [clearSelectedRows, setClearSelectedRows] = useState(false);
	const [price, setPrice] = useState<number>(config.firebaseRemoteConfig.defaults.WEB_PRICE_PER_TEAM);
	const { t } = useTranslation();

	useEffect(() => {
		fireRemoteConfig
			.fetchAndActivate()
			.then(() => setPrice(fireRemoteConfig.getValue('WEB_PRICE_PER_TEAM').asNumber()));

		if (serverStatus === 'COMPLETED_DOWNGRADE') {
			setConfirmModalVisibility(false);
			toast.info(
				`${selectedRows.length} ${selectedRows.length > 1 ? t('portal.common.teams') : t('portal.common.team')} ${t(
					'portal.payments.downgraded',
				)}`,
			);
			setSelectedRows([]);
			setClearSelectedRows(true);
		}
	}, [serverStatus]);

	const downgradeTeams = () => confirmAction('downgradeTeams', selectedRows);

	const columns = [
		{
			name: t('portal.common.teams'),
			sortable: true,
			selector: 'name',
		},
		{
			name: t('portal.common.club'),
			sortable: true,
			selector: 'club',
			cell: (team: Team) => clubs.find((club) => club.__id === team._club?.id)?.name,
		},
		{
			name: t('portal.common.information'),
			sortable: false,
			// eslint-disable-next-line react/display-name
			cell: (team: Team) =>
				team?._iapPremiumUntil ? (
					t('portal.payments.prePaid', {
						expirationDate: `${team._iapPremiumUntil.toDate().getDate()}-${
							team._iapPremiumUntil.toDate().getMonth() + 1
						}-${team._iapPremiumUntil.toDate().getFullYear()}`,
					})
				) : (
					<Styled.Column>{t('portal.payments.monthly')}</Styled.Column>
				),
		},
	];

	return (
		<Styled.Wrapper>
			<Styled.HeaderWrapper>
				<h3>{t('portal.common.premiumTeams')}</h3>
				<div>
					{selectedRows.length <= 0 ? (
						<GreyedOut>
							<Button small marginBottom="10px" disabled>
								{t('portal.common.downgradeTeams')}
							</Button>
						</GreyedOut>
					) : (
						<Button small marginBottom="10px" action={() => setConfirmModalVisibility(true)}>
							{t('portal.common.downgrade')} {selectedRows.length > 0 && selectedRows.length} {t('portal.common.team')}
							{selectedRows.length > 1 && 's'}
						</Button>
					)}
				</div>
			</Styled.HeaderWrapper>
			{user.stripe?._trialUsedOn &&
				user?.stripe?._trialUsedOn.toDate() > new Date(new Date().setDate(new Date().getDate() - 30)) && (
					<Styled.Message>
						<div>
							{t('portal.trail.trailRemaining')}:{' '}
							{31 -
								Math.ceil((new Date().getTime() - user?.stripe?._trialUsedOn.toDate().getTime()) / (1000 * 3600 * 24))}
						</div>
					</Styled.Message>
				)}
			<DataTable
				columns={columns}
				defaultSortField={'Team'}
				striped={true}
				customStyles={table}
				data={teams}
				keyField="id"
				selectableRows
				selectableRowsHighlight
				{...props}
				onSelectedRowsChange={(row) => {
					setSelectedRows(row.selectedRows);
					setClearSelectedRows(false);
				}}
				selectableRowDisabled={(team) => !team?._stripeSubscription}
				clearSelectedRows={clearSelectedRows}
			/>
			<Confirm
				visibility={confirmModalVisibility}
				closeAction={() => setConfirmModalVisibility(false)}
				proceedText={t('portal.common.confirm')}
				proceedAction={() => downgradeTeams()}
			>
				<h2>{t('portal.payments.downgradeConfirmation')}</h2>
				<ul>
					{selectedRows.map((team: Team) => {
						return <li key={team.__id}>{team.name}</li>;
					})}
				</ul>
				<p>
					{t('portal.payments.downgradeConfirmationContent')}{' '}
					<b>&euro;{Math.round(((currentPremiumAmount - selectedRows.length) * price + Number.EPSILON) * 100) / 100}</b>{' '}
					{t('portal.payments.perMonth') + ' ' + t('portal.payments.incTax')}
				</p>
			</Confirm>
		</Styled.Wrapper>
	);
};

export default PaymentsDowngrade;
