import styled from 'styled-components';

import type { StyledContainerProps, StyledProps } from './types';

export const Container = styled.div<StyledContainerProps>`
	color: ${({ theme: { colors } }) => colors.textColor};
	position: relative;
	min-width: 125px;

	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		position: absolute;
		top: 30px;
		left: 4%;
	}
`;

export const Club = styled.div`
	margin-left: 10px;
	display: flex;
	cursor: pointer;
	justify-content: space-between;
	text-decoration: none;
`;

export const Dropdown = styled.div<StyledProps>`
	display: ${({ open }) => (open ? 'block' : 'none')};
	position: absolute;
	top: 50px;
	width: 130%;
	background: ${({ theme: { colors } }) => colors.background};
	box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
	a {
		cursor: pointer;
		padding: 20px 20px;
		transition: 0.3s ease all;
		display: block !important;
		text-decoration: none;
		color: black;

		&:hover {
			background: ${({ theme: { colors } }) => colors.grey};
			&:before {
				display: none;
			}
		}
	}
`;

export const ChevronDown = styled.i`
	& {
		box-sizing: border-box;
		position: relative;
		display: block;
		transform: scale(var(--ggs, 0.8));
		width: 22px;
		height: 22px;
		border: 2px solid transparent;
		border-radius: 100px;
	}
	&::after {
		content: '';
		display: block;
		box-sizing: border-box;
		position: absolute;
		width: 10px;
		height: 10px;
		border-bottom: 2px solid;
		border-right: 2px solid;
		transform: rotate(45deg);
		left: 4px;
		top: 2px;
	}
`;
