import styled from 'styled-components';

import type { StyledProps } from './types';

export const Container = styled.section`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: ${({ theme: { layout } }) => layout.margins.section};
	position: relative;
`;

export const Heading = styled.h1`
	font-size: ${({ theme: { fonts } }) => fonts.sizes.huge};
	font-weight: ${({ theme: { fonts } }) => fonts.weights.bolder};
	display: flex;
	justify-content: center;
	text-align: center;
`;

export const Feature = styled.div<StyledProps>`
	display: flex;
	justify-content: space-around;
	flex-direction: ${({ reversed }) => (reversed ? 'row-reverse' : 'row')};
	margin-bottom: ${({ theme: { layout } }) => layout.margins.feature};
	width: 80%;

	&:last-child {
		margin-bottom: 0;
	}

	@media only screen and (max-width: ${({ theme: { layout } }) => layout.screenSizes.medium}) {
		flex-direction: column-reverse;
	}
`;

export const FeatureImage = styled.div`
	position: relative;

	img {
		width: 520px;
		max-width: 100%;
		height: auto;
		user-select: none;
		-webkit-user-drag: none;
		pointer-events: none;
	}
	.award {
		position: absolute;
		bottom: -30px;
		left: -80px;
		max-width: 100%;
		height: auto;
		width: 140px;
		box-shadow: none;

		@media ${({ theme: { layout } }) => layout.device.tablet} {
			left: 0;
		}
	}
	.ball {
		position: absolute;
		bottom: 0px;
		right: -20px;
		width: 75px;
		box-shadow: none;

		@media ${({ theme: { layout } }) => layout.device.tablet} {
			transform: translateX(-100%);
		}
	}

	@media ${({ theme: { layout } }) => layout.device.laptop} {
		max-width: 50%;
	}

	@media ${({ theme: { layout } }) => layout.device.tablet} {
		flex-direction: column;
		align-items: center;
		max-width: initial;
		display: flex;

		> * {
			margin-left: 0;
			margin-top: 30px;
		}
	}
`;

export const FeatureInfo = styled.div`
	max-width: 40%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@media ${({ theme: { layout } }) => layout.device.tablet} {
		max-width: 100%;
		padding-bottom: 10px;
	}
`;

export const FeatureHeading = styled.h2`
	font-size: ${({ theme: { fonts } }) => fonts.sizes.big};
	font-weight: ${({ theme: { fonts } }) => fonts.weights.bolder};

	@media ${({ theme: { layout } }) => layout.device.tablet} {
		text-align: center;
	}
`;

export const Circle = styled.div`
	position: absolute;
	top: 500px;
	left: -150px;
	background: transparent;
	width: 750px;
	height: 750px;
	border-radius: 500px;
	border: 150px solid rgba(41, 119, 204, 0.14);
	z-index: -1;

	@media only screen and (max-width: 610px) {
		width: 550px;
		height: 550px;
		border: 100px solid rgba(41, 119, 204, 0.14);
	}

	@media only screen and (max-width: 420px) {
		width: 350px;
		height: 350px;
		border: 70px solid rgba(41, 119, 204, 0.14);
	}
`;
