import { colors } from './colors';
import fonts from './fonts';
import layout from './layout';

export const theme = {
	fonts,
	colors,
	layout,
};

export default theme;
