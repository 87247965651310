import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dispatch } from 'redux';

import PortalIntro from '../portal-intro';

import * as Styled from './styles';
import type { DispatchProps, Props, StateProps } from './types';

import { logoBlue } from 'src/assets';
import { LanguageSwitcher,  ProfileDropdown, /*ClubDropdown,*/ BurgerMenu } from 'src/components';
import { AppActionsTypes, changeIntro, INTRO, RootState } from 'src/redux';

export const PortalHeader: React.FC<Props> = ({ intro, setIntro, currentClub }: Props) => {
	console.log('*************************e1');
	const { t } = useTranslation();
	const [open, setOpen] = useState<boolean>(false);
	const closeMenu = () => setOpen(false);

	const logoLink = currentClub ? `/portal/club/${currentClub}/teams` : `/portal`;

	const _renderItems = () => {
		return (
			<>
				<li onClick={closeMenu} className="link">
					<Link to={`/portal/club/${currentClub}/teams`}>{t('portal.common.teams')}</Link>
				</li>
				<li onClick={closeMenu} className="link">
					<Link to={`/portal/club/${currentClub}/trainers`}>{t('portal.common.allTrainers')}</Link>
				</li>
				{/*<li onClick={closeMenu} className="link">
					<Link to={`/portal/club/${currentClub}/players`}>{t('portal.common.allPlayers')}</Link>
				</li>*/}
				<Styled.Divider />
				<Styled.Li>
					<LanguageSwitcher />
				</Styled.Li>
				<Styled.Li>
					<ProfileDropdown />
				</Styled.Li>
			</>
		);
	};

	return (
		<>
			<header>
				<Styled.NavBar open={open} bg>
					<Link to={logoLink}>
						<Styled.Logo src={logoBlue} width="40px" open={open} />
					</Link>
					{/*<ClubDropdown />*/}

					<Link to='#' onClick={(e) => {	window.location.href = 'mailto:info@samonline.nl'; e.preventDefault();	}}	> info@samonline.nl	</Link>

					<Styled.NavList open={open}>{_renderItems()}</Styled.NavList>
					<BurgerMenu open={open} setOpen={setOpen} visible></BurgerMenu>
				</Styled.NavBar>
			</header>
			{intro === 'UNSEEN' && <PortalIntro setIntro={setIntro} />}
		</>
	);
};

const mapStateToProps = (state: RootState): StateProps => ({
	currentClub: state.app.currentClub,
	intro: state.app.intro,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	setIntro: (value: INTRO): AppActionsTypes => dispatch(changeIntro(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PortalHeader) as React.ComponentType;
