/* eslint-disable react/display-name */
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { Helmet } from 'react-helmet';
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TrashEmpty } from 'css.gg';
import { toast } from 'react-toastify';
import { ImpulseSpinner } from 'react-spinners-kit';
import { Redirect, useParams } from 'react-router';

import * as Styled from './styles';
import type { FirestoreConnectProps, Props, StateProps } from './types';

import { colors, table } from 'src/styles';
import { Button, Modal, PortalHeader, Footer } from 'src/components';
import { PlayerSelect, PlayerEdit, PlayerCreate } from 'src/pages';
import { fireStore } from 'src/utils';
import { changeCurrentClub, changeCurrentTeam, RootState, store } from 'src/redux';
import type { Player } from 'src/types';

export const PlayersOverview: React.FC<Props> = ({
	frame = false,
	team,
	teams,
	basePlayers,
	guestPlayers,
	currentClub,
	currentTeam,
}: Props) => {
	const [modalVisibility, setModalVisibility] = useState(false);
	const [modalContent, setModalContent] = useState<string | null>(null);
	const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);
	const { t } = useTranslation();
	const { clubID, teamID } = useParams<{ clubID?: string; teamID?: string }>();
	store.dispatch(changeCurrentClub(clubID));
	store.dispatch(changeCurrentTeam(teamID));

	const OpenModal = (content: string) => {
		setModalVisibility(true);
		setModalContent(content);
	};
	const CloseModal = () => {
		setModalVisibility(false);
		setModalContent(null);
	};
	function deletePlayer(player: Player) {
		//const player = _player;
		//player.__deleted = true;

		fireStore.collection('players').doc(player.__id).update({ __deleted: true });
		if (currentTeam)
			fireStore
				.collection('teams')
				.doc(currentTeam)
				.update({
					players: team.players.filter((_player) => _player.id !== player.__id),
				})
				.then(() => toast.info(`${player.firstName} ${t('portal.common.deleted')}`))
				.catch(toast.error);
	}

	const columns = [
		{
			name: t('portal.common.firstName'),
			selector: 'firstName',
			sortable: true,
		},
		{
			name: t('portal.common.lastName'),
			selector: 'lastName',
			sortable: true,
		},
		{
			name: t('portal.common.backnumber'),
			selector: 'backNumber',
			sortable: true,
		},
		{
			name: t('portal.common.legged'),
			sortable: true,
			cell: (player: Player) => {
				return player.legPreference.map((preference, i) => {
					//const name = t(`portal.common.${preference.toLowerCase()}`).charAt(0);
					const name = t(`portal.common.${preference.toLowerCase()}`).charAt(0);
					return i !== 0 ? `, ${name}` : name;
				});
			},
		},
		{
			name: t('portal.common.position'),
			sortable: true,
			cell: (player: Player) => {
				return player.positions.map((position, i) => {
					let name='';
					switch(position){
						case 'DEFENCE': name=t(`portal.common.defence`);break;
						case 'MIDDLEGROUND': name=t(`portal.common.middleGround`);break;
						case 'KEEPER': name=t(`portal.common.keeper`);break; 
						case 'ATTACK': name=t(`portal.common.attack`);break;
					}
					name=name.charAt(0);
					//const name = t(`portal.common.middleGround`)//.charAt(0);
					//const name = t(`portal.common.${position}`)//.charAt(0);
					//const name = t(`${position.toLowerCase()}`)//.charAt(0);
					return i !== 0 ? `, ${name}` : name;
				});
			},
		},
		{
			name: t('portal.common.edit'),
			cell: (player: Player) => (
				<Button
					action={() => {
						setSelectedPlayer(player);
						OpenModal('PlayerEdit');
					}}
					small
				>
					{t('portal.common.edit')}
				</Button>
			),
		},
		{
			name: t('portal.common.delete'),
			sortable: false,
			cell: (player: Player) => (
				<Styled.IconButton
					onClick={() => {
						if (window.confirm(t('portal.delete.deleteContent', { name: player.firstName }))) deletePlayer(player);
					}}
				>
					<TrashEmpty />
				</Styled.IconButton>
			),
			minWidth: '100px',
			center: true,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];

	if (isLoaded(team) && isEmpty(team)) {
		return <Redirect to="/portal" />;
	}

	if (isLoaded(team) && isLoaded(basePlayers) && isLoaded(guestPlayers)) {
		const players = [...basePlayers, ...guestPlayers];

		return (
			<>
				<Helmet>
					<title>
						{team?.name}
						{team && ' - '}
						{t('portal.common.playerOverview')}
					</title>
				</Helmet>
				{!frame && <PortalHeader />}

				<Styled.Wrapper>
					<Styled.Container>
						<Styled.HeaderWrapper>
							<Styled.Heading>
								{team?.name}
								{team && ' - '}
								{t('portal.common.playerOverview')}
							</Styled.Heading>
							<Styled.ButtonWrapper>
								<Button action={() => OpenModal('PlayerCreate')} color="orange">
									{t('portal.create.player')}
								</Button>
								<Button action={() => OpenModal('PlayerSelect')} color="orange">
									{t('portal.common.selectPlayers')}
								</Button>
							</Styled.ButtonWrapper>
						</Styled.HeaderWrapper>
						{players.length > 0 ? (
							<>
								<Styled.TableWrapper>
									<DataTable
										columns={columns}
										defaultSortField={'firstName'}
										striped={true}
										customStyles={table}
										data={players}
										keyField="__id"
									/>
								</Styled.TableWrapper>
								<br />
								<p>
									<b>{t('portal.common.legged')}: </b> <br />
									<b>{t('portal.common.left').charAt(0)}: </b> {t('portal.common.left')} <br />
									<b>{t('portal.common.right').charAt(0)}: </b> {t('portal.common.right')} <br />
									<br />
									<b>{t('portal.common.position')}: </b> <br />
									<b>{t('portal.common.attack').charAt(0)}: </b> {t('portal.common.attack')} <br />
									<b>{t('portal.common.middleGround').charAt(0)}: </b> {t('portal.common.middleGround')} <br />
									<b>{t('portal.common.defence').charAt(0)}: </b> {t('portal.common.defence')} <br />
									<b>{t('portal.common.keeper').charAt(0)}: </b> {t('portal.common.keeper')} <br />
									<br />
								</p>
							</>
						) : (
							<div>{t('portal.empty.players')}</div>
						)}
					</Styled.Container>
				</Styled.Wrapper>
				<Modal modalVisibility={modalVisibility} closeAction={CloseModal}>
					{modalContent === 'PlayerCreate' && (
						<PlayerCreate currentClub={currentClub} teams={teams} closeAction={CloseModal} frame />
					)}
					{modalContent === 'PlayerSelect' && (
						<PlayerSelect
							currentClub={currentClub}
							originalPlayers={players}
							teamName={team.name}
							closeAction={CloseModal}
							frame
						/>
					)}
					{modalContent === 'PlayerEdit' && selectedPlayer && (
						<PlayerEdit currentClub={currentClub} player={selectedPlayer} closeAction={CloseModal} frame />
					)}
				</Modal>

				{!frame && <Footer />}
			</>
		);
	} else
		return (
			<>
				{!frame && <PortalHeader />}
				<Styled.LoaderContainer>
					<ImpulseSpinner size={80} frontColor={colors.blue} backColor={colors.grey} />
				</Styled.LoaderContainer>
				{!frame && <Footer />}
			</>
		);
};

const mapStateToProps = (state: RootState): StateProps => {
	return {
		uid: state.firebase.auth.uid,
		club: state.firestore.data.currentClub,
		team: state.firestore.data.currentTeam,
		teams: state.firestore.ordered.teams,
		basePlayers: state.firestore.ordered.basePlayers,
		guestPlayers: state.firestore.ordered.guestPlayers,
		currentClub: state.app.currentClub,
		currentTeam: state.app.currentTeam,
	};
};

export default compose(
	connect(mapStateToProps),
	firestoreConnect(({ currentTeam, currentClub, uid }: FirestoreConnectProps) => {
		if (currentClub && currentTeam)
			return [
				{
					collection: 'clubs',
					storeAs: 'currentClub',
					doc: currentClub,
				},
				{
					collection: 'teams',
					storeAs: 'currentTeam',
					doc: currentTeam,
				},
				{
					collection: 'teams',
					storeAs: 'teams',
					where: [
						['__deleted', '==', false],
						['_club', '==', fireStore.doc(`clubs/${currentClub}`)],
						['_users', 'array-contains', fireStore.doc(`users/${uid}`)],
						['tier', '==', 'PREMIUM'],
					],
				},
				{
					collection: 'players',
					storeAs: 'basePlayers',
					where: [
						['__deleted', '==', false],
						['_baseTeams', 'array-contains', fireStore.doc(`teams/${currentTeam}`)],
					],
				},
				{
					collection: 'players',
					storeAs: 'guestPlayers',
					where: [
						['__deleted', '==', false],
						['_guestTeams', 'array-contains', fireStore.doc(`teams/${currentTeam}`)],
					],
				},
			];
		else return [];
	}),
)(PlayersOverview) as React.ComponentType<Props>;
