import styled from 'styled-components';

import type { StyledProps } from './types';

export const IntroWrapper = styled.div`
	padding: 0 40px 40px;
`;

export const H1 = styled.h1`
	margin-top: 0;
	text-align: center;
`;

export const Text = styled.p`
	text-align: center;
`;

export const ChevronLeft = styled.div`
	position: absolute;
	left: 0px;
	top: 50%;
	transform: translateY(-50%);
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

export const ChevronRight = styled.div`
	position: absolute;
	right: 0px;
	top: 50%;
	transform: translateY(-50%);
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

export const Dots = styled.ul`
	display: flex;
	justify-content: flex-start;
	list-style-type: none;
	position: absolute;
	left: 50%;
	bottom: 30px;
	transform: translateX(-50%);
	margin: 0;
	padding: 0;
`;

export const Dot = styled.li<StyledProps>`
	background: ${({ active, theme: { colors } }) => (active ? colors.blue : colors.darkGrey)};
	width: 12px;
	height: 12px;
	border-radius: 10px;
	margin: 0 5px;
	cursor: pointer;
	transition: 0.3s ease-in-out all;

	&:hover {
		background: ${({ active, theme: { colors } }) => (active ? colors.lightBlue : colors.darkerGrey)};
	}
`;

export const Center = styled.div`
	display: flex;
	justify-content: center;
	margin: 40px 0 20px;
`;
