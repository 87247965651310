import configFirebase from './firebase';
import configFirebaseFunctions from './firebaseFunctions';
import configFirebaseRemoteConfig from './firebaseRemoteConfig';
import configReactReduxFirebase from './reactReduxFirebase';
import configStripe from './stripe';
import configOther from './other';

export { default as configFirebase } from './firebase';
export { default as configFirebaseFunctions } from './firebaseFunctions';
export { default as configFirebaseRemoteConfig } from './firebaseRemoteConfig';
export { default as configReactReduxFirebase } from './reactReduxFirebase';
export { default as configStripe } from './stripe';
export { default as configOther } from './other';

export const config = {
	firebase: configFirebase,
	firebaseFunctions: configFirebaseFunctions,
	firebaseRemoteConfig: configFirebaseRemoteConfig,
	reactReduxFirebase: configReactReduxFirebase,
	stripe: configStripe,
	...configOther,
};

export default config;
