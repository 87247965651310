import { getCurrentClub, getCurrentTeam, getIntro } from './functions';
import { AppState, AppActionsTypes, INTRO_CHANGED, CURRENT_CLUB_CHANGED, CURRENT_TEAM_CHANGED } from './types';

export const initialAppState: AppState = {
	intro: getIntro(),
	currentClub: getCurrentClub(),
	currentTeam: getCurrentTeam(),
};

export const appReducer = (state = initialAppState, action: AppActionsTypes): AppState => {
	switch (action.type) {
		case INTRO_CHANGED:
			return {
				...state,
				intro: action.intro,
			};
		case CURRENT_CLUB_CHANGED:
			return {
				...state,
				currentClub: action.club,
			};
		case CURRENT_TEAM_CHANGED:
			return {
				...state,
				currentTeam: action.team,
			};
		default:
			return state;
	}
};

export default appReducer;
