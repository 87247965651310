import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';

import { android, ios, imgFooter } from 'src/assets';

export const Download: React.FC = () => {
	const { t } = useTranslation();

	return (
		<Styled.Container id="download">
			<Styled.PhonesContainer>
				<Styled.Phones>
					<Styled.Phone
						src={imgFooter}
						alt=""
						className="aos-init"
						data-aos="animate"
						data-aos-offset="100"
						data-aos-delay="100"
						data-aos-once="true"
					/>
				</Styled.Phones>
			</Styled.PhonesContainer>

			<Styled.FeatureInfo>
				<Styled.FeatureHeading
					className="aos-init"
					data-aos="animate"
					data-aos-offset="200"
					data-aos-delay="0"
					data-aos-duration="200"
					data-aos-once="true"
				>
					{t('website.download.title')}
				</Styled.FeatureHeading>
				<p
					className="aos-init"
					data-aos="animate"
					data-aos-offset="200"
					data-aos-delay="200"
					data-aos-duration="200"
					data-aos-once="true"
				>
					{t('website.download.description')}
				</p>
				<Styled.DownloadImages>
					<a
						href="https://play.google.com/store/apps/details?id=nl.samonline.samapp"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Styled.DownloadImage
							src={android}
							alt="Download for Android"
							className="aos-init"
							data-aos="animate"
							data-aos-offset="200"
							data-aos-delay="300"
							data-aos-duration="600"
							data-aos-once="true"
						/>
					</a>
					<a href="https://apps.apple.com/us/app/id1525890964" target="_blank" rel="noopener noreferrer">
						<Styled.DownloadImage
							src={ios}
							alt="Download for iOS"
							className="aos-init"
							data-aos="animate"
							data-aos-offset="200"
							data-aos-delay="300"
							data-aos-duration="600"
							data-aos-once="true"
						/>
					</a>
				</Styled.DownloadImages>
			</Styled.FeatureInfo>
			<Styled.CircleContainer>
				<Styled.Circle />
			</Styled.CircleContainer>
		</Styled.Container>
	);
};

export default Download;
