import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Header, Landing, Features, Price, Download, Footer } from 'src/components';

export const Home: React.FC = () => {
	const { t } = useTranslation();

	return (
		<>
			<Helmet titleTemplate="%s">
				<title>⁣SAM online | {t('website.global.slogan')}</title>
			</Helmet>
			<Header />
			<Landing />
			<Features />
			<Price />
			<Download />
			<Footer background />
		</>
	);
};

export default Home;
