import styled from 'styled-components';

export const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
	width: 100%;
	height: 100%;
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Content = styled.div`
	position: relative;
	background: #fff;
	width: 60vw;
	height: auto;
	max-height: 80vh;
	overflow-x: auto;
	padding: 30px;
	border-radius: 10px;
	@media ${({ theme: { layout } }) => layout.device.laptop} {
		width: calc(100vw - 40px);
	}
`;

export const CloseButton = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;
	display: block;
	width: 22px;
	height: 22px;
	border: 2px solid transparent;
	border-radius: 40px;
	cursor: pointer;
	transition: 0.3s ease all;

	&::after,
	&::before {
		content: '';
		display: block;
		box-sizing: border-box;
		position: absolute;
		width: 16px;
		height: 2px;
		background: ${({ theme: { colors } }) => colors.textColor};
		transform: rotate(45deg);
		border-radius: 5px;
		top: 8px;
		left: 1px;
		transition: 0.3s ease all;
	}
	&::after {
		transform: rotate(-45deg);
	}

	&:hover {
		transform: rotate(90deg);
		&::after,
		&::before {
			background: ${({ theme: { colors } }) => colors.textColorLight};
		}
	}
`;
