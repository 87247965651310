import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';
import type { Props } from './types';

import { Button } from 'src/components';

export const Confirm: React.FC<Props> = ({ children, visibility, closeAction, proceedText, proceedAction }: Props) => {
	const { t } = useTranslation();

	if (visibility)
		return (
			<Styled.Wrapper onClick={(event) => event.target === event.currentTarget && closeAction()}>
				<Styled.Content>
					<Styled.CloseButton onClick={(event) => event.target === event.currentTarget && closeAction()} />
					{children}
					<Styled.ButtonWrapper>
						<div onClick={() => closeAction()}>{t('portal.common.cancel')}</div>
						<Button action={proceedAction}>{proceedText}</Button>
					</Styled.ButtonWrapper>
				</Styled.Content>
			</Styled.Wrapper>
		);

	return <></>;
};

export default Confirm;
