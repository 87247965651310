import React from 'react';

import * as Styled from './styles';
import type { Props } from './types';

export const GreyedOut: React.FC<Props> = ({ children }: Props) => {
	return <Styled.Wrapper>{children}</Styled.Wrapper>;
};

export default GreyedOut;
