/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { compose } from 'redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { ImpulseSpinner } from 'react-spinners-kit';
import { Helmet } from 'react-helmet';
import Tippy from '@tippyjs/react';
import * as firebase from 'firebase/app';
import 'tippy.js/dist/tippy.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TrashEmpty } from 'css.gg';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';

import * as Styled from './styles';
import type { Props, UserWithTeams, StateProps, FirestoreConnectProps } from './types';

import { changeCurrentClub, changeCurrentTeam, RootState, store } from 'src/redux';
import { Permission } from 'src/types/permission.d';
import type { PermissionUser, Team, User } from 'src/types';
import { Button, Modal, PortalHeader, Footer, GreyedOut } from 'src/components';
import { colors, table } from 'src/styles';
import { TrainerCreateGeneral } from 'src/pages';
import { fireStore } from 'src/utils';

export const Overview: React.FC<Props> = ({ frame = false, club, teams }: Props) => {
	const [modalVisibility, setModalVisibility] = useState(false);
	const [modalContent, setModalContent] = useState<string | null>(null);
	const [trainers, setTrainers] = useState<Array<UserWithTeams>>();
	const { t } = useTranslation();
	const { clubID, teamID } = useParams<{ clubID?: string; teamID?: string }>();
	store.dispatch(changeCurrentClub(clubID));
	store.dispatch(changeCurrentTeam(teamID));

	useEffect(() => {
		const userIDs: Set<string> = new Set();

		if (isLoaded(club) && isLoaded(teams)) {
			teams.forEach((_team: Team) => {
				_team._permissionsUsers.forEach((_permissionsUser: PermissionUser)=>{
					if(_permissionsUser.user!=null)userIDs.add(_permissionsUser.user.id);
				})
			});
			club._trainers.forEach((_trainer) => {
				userIDs.add(_trainer.id);
			});
		}

		const requests = Array.from(userIDs).map(async (_userID: string) => await fireStore.doc(`users/${_userID}`).get());

		Promise.all(requests).then((response) => {
			const users: Array<User> = response.map((_data) => _data.data() as User);

			setTrainers(
				users.map((_user: User) => {
					return {
						..._user,
						teams: teams.filter((_team: Team) =>
							_team._permissionsUsers.find((_permissionUser: PermissionUser) => 
								_permissionUser.user!=null && _permissionUser.user.id === _user.uid),
						),
					};
				}),
			);
		});
	}, [club, teams]);

	const OpenModal = (content: string) => {
		setModalVisibility(true);
		setModalContent(content);
	};
	const CloseModal = () => {
		setModalVisibility(false);
		setModalContent(null);
	};

	const languageStringRoles = (role: Permission) => {
		switch (role) {
			case Permission.OWNER:
				return t('portal.rights.owner');
			case Permission.MANAGE:
				return t('portal.rights.portalApp');
			case Permission.WRITE:
				return t('portal.rights.app');
			case Permission.READ:
				return t('portal.rights.appRead');
			case Permission.NONE:
			default:
				return 'Geen';
		}
	};

	const deleteTrainer = (user: UserWithTeams) => {
		if (user.teams.length > 0) {
			fireStore
				.collection('teams')
				.where('__deleted', '==', false)
				.where('_users', 'array-contains', fireStore.collection('users').doc(user.uid))
				.get()
				.then(
					(_teams) =>
						_teams.docs.length > 0 &&
						_teams.forEach((team) => {
							const _team = team.data();
							fireStore
								.collection('teams')
								.doc(team.id)
								.update({
									_permissionsUsers: _team._permissionsUsers.filter(
										(permissionUser: PermissionUser) => permissionUser.user.id !== user.uid,
									),
									_users: firebase.default.firestore.FieldValue.arrayRemove(
										fireStore.collection('users').doc(user.uid),
									),
								});
						}),
				);
		}

		fireStore
			.collection('clubs')
			.doc(club.__id)
			.update({
				_trainers: firebase.default.firestore.FieldValue.arrayRemove(fireStore.collection('users').doc(user.uid)),
			})
			.then(() => toast.info(`${user.displayName || 'Trainer'} ${t('portal.common.deleted')}`))
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.error(error);
				toast.error(t('portal.errors.general'));
			});
	};

	const columns = [
		{
			name: t('portal.common.name'),
			selector: 'displayName',
			sortable: true,
			sortFunction: (_userA: UserWithTeams, _userB: UserWithTeams) => {
				const _nameA = _userA.displayName ? _userA.displayName : _userA.email ? _userA.email : '',
					_nameB = _userB.displayName ? _userB.displayName : _userB.email ? _userB.email : '';

				return _nameA.toLowerCase() < _nameB.toLowerCase() ? -1 : _nameA.toLowerCase() > _nameB.toLowerCase() ? 1 : 0;
			},
		},
		{
			name: t('portal.common.email'),
			selector: 'email',
			sortable: true,
		},
		{
			name: t('portal.common.teams'),
			cell: (user: UserWithTeams) => (
				<Styled.TeamWrapper>
					{user.teams.map((_team: Team) => {
						const teamRole: PermissionUser = _team._permissionsUsers.find(
							(_permissionUser: PermissionUser) => _permissionUser.user.id === user.uid,
						);
						return (
							teamRole && (
								<div key={_team.__id}>
									{_team.name} - {languageStringRoles(teamRole.type)}
								</div>
							)
						);
					})}
				</Styled.TeamWrapper>
			),
			sortable: true,
		},
		{
			name: t('portal.common.delete'),
			sortable: false,
			cell: (user: UserWithTeams) => {
				let isAdmin = false;
				user.teams.forEach((_team: Team) => {
					isAdmin =
						_team._permissionsUsers.find((_permissionUser: PermissionUser) => _permissionUser.user.id === user.uid)
							?.type === 'OWNER';
				});
				return isAdmin ? (
					<Tippy content={t('portal.errors.cannotDeleteOwner')} interactive={true} placement={'left'}>
						<div>
							<GreyedOut>
								<TrashEmpty />
							</GreyedOut>
						</div>
					</Tippy>
				) : (
					<Styled.IconButton
						onClick={() => {
							if (
								window.confirm(
									t('portal.delete.deleteContent', { user: user.displayName || t('portal.common.thisUser') }),
								)
							)
								deleteTrainer(user);
						}}
					>
						<TrashEmpty />
					</Styled.IconButton>
				);
			},

			minWidth: '100px',
			center: true,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];

	console.log('ssssssssswwwwwww',teams);
	return trainers ? (
		<>
			<Helmet>
				<title>{t('portal.common.trainersOverview')}</title>
			</Helmet>
			{!frame && <PortalHeader />}
			<Styled.Wrapper>
				<Styled.Container>
					<Styled.HeaderWrapper>
						<Styled.Heading>{t('portal.common.trainersOverview')}</Styled.Heading>
						<Styled.ButtonWrapper>
							{teams?.length>=1 ? (
								<Button action={() => OpenModal('TrainerNewGeneral')} color="orange">
									{t('portal.create.trainer')}
								</Button>
							) : (
								<Tippy content={t('portal.errors.noPremiumTeams')} interactive={true} placement={'left'}>
									<div>
										<GreyedOut>
											<Button disabled color="orange">
												{t('portal.create.trainer')}
											</Button>
										</GreyedOut>
									</div>
								</Tippy>
							)}
						</Styled.ButtonWrapper>
					</Styled.HeaderWrapper>
					{trainers ? (
						<>
							<Styled.TableWrapper>
								<DataTable
									columns={columns}
									striped={true}
									customStyles={table}
									data={trainers}
									defaultSortField="displayName"
									keyField="key"
								/>
							</Styled.TableWrapper>
							<br />
							<p>
								<b>{t('portal.rights.title')}</b>
							</p>
							<Styled.Small>
								<b>{t('portal.rights.owner')}:</b> {t('portal.rights.ownerDescription')}
								<br />
								<b>{t('portal.rights.portalApp')}:</b> {t('portal.rights.portalAppDescription')}
								<br />
								<b>{t('portal.rights.app')}:</b> {t('portal.rights.appDescription')}
								<br />
								<b>{t('portal.rights.appRead')}:</b> {t('portal.rights.appReadDescription')}
							</Styled.Small>
						</>
					) : (
						<div>{t('portal.empty.teams')}</div>
					)}
				</Styled.Container>
			</Styled.Wrapper>
			<Modal modalVisibility={modalVisibility} closeAction={CloseModal}>
				{modalContent === 'TrainerNewGeneral' && (
					<TrainerCreateGeneral frame closeAction={CloseModal} clubID={club.__id} />
				)}
			</Modal>
		</>
	) : (
		<>
			{!frame && <PortalHeader />}
			<Styled.LoaderContainer>
				<ImpulseSpinner size={80} frontColor={colors.blue} backColor={colors.grey} />
			</Styled.LoaderContainer>
			{!frame && <Footer />}
		</>
	);
};

const mapStateToProps = (state: RootState): StateProps => ({
	uid: state.firebase.auth.uid,
	club: state.firestore.data.currentClub,
	teams: state.firestore.ordered.teams,
	currentClub: state.app.currentClub,
});

export default compose(
	connect(mapStateToProps),
	firestoreConnect(({ currentClub }: FirestoreConnectProps) => {
		if (currentClub)
			return [
				{
					collection: 'clubs',
					storeAs: 'currentClub',
					doc: currentClub,
				},
				{
					collection: 'teams',
					storeAs: 'teams',
					where: [
						['__deleted', '==', false],
						['tier', '==', 'PREMIUM'],
						['_club', '==', fireStore.doc(`clubs/${currentClub}`)],
					],
				},
			];
		else return [];
	}),
)(Overview) as React.ComponentType;
