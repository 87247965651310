/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import * as Styled from './styles';
import type { FirestoreConnectProps, Props, StateProps } from './types';

import { Link, PortalHeader, PaymentsUpgrade, PaymentsDowngrade } from 'src/components';
import type { RootState } from 'src/redux';
import { fireFunctions, fireRemoteConfig, fireStore } from 'src/utils';
import { Button, Loader } from 'src/components/global';
import { Team, StripeProfile } from 'src/types';
import { Tier } from 'src/types/tier.d';
import { config } from 'src/config';

const stripePromise = loadStripe(config.stripe.publishableKey);

// https://stripe.com/docs/billing/subscriptions/per-seat

// 4242424242424242	Succeeds and immediately creates an active subscription.
// 4000002500003155	Requires authentication, with the payment_intent value of requires_action. The confirmCardPayment() triggers a modal that asks the customer to authenticate. When the user confirms, the subscription is active. See manage payment authentication.
// 4000008260003178	Always fails with a decline code of insufficient_funds. To handle this error on the backend, see create subscription.
// 4000000000000341	Succeeds when first attached to the customer object, but fails on first payment of the subscription, with the payment_intent value of requires_payment_method. See manage subscription payment failure.

export const PaymentOverview: React.FC<Props> = ({ clubs, teams, user, currentClub }: Props) => {
	const [serverStatus, setServerStatus] = useState<
		'FETCHING' | 'COMPLETED' | 'COMPLETED_UPGRADE' | 'COMPLETED_DOWNGRADE' | null
	>(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [serverResponse, setServerResponse] = useState<{
		status?: number;
		message?: string;
	} | null>(null);
	const [price, setPrice] = useState<number>(config.firebaseRemoteConfig.defaults.WEB_PRICE_PER_TEAM);
	const { t } = useTranslation();

	useEffect(() => {
		fireRemoteConfig
			.fetchAndActivate()
			.then(() => setPrice(fireRemoteConfig.getValue('WEB_PRICE_PER_TEAM').asNumber()));
	}, [user]);
	const { email, displayName } = user;

	const createBillingPortal = (customer: StripeProfile) => {
		setServerStatus('FETCHING');

		fireFunctions
			.httpsCallable('createBillingPortal')({
				customerID: customer.__id,
				returnURL: `${config.url}/portal/payments`,
			})
			.then(
				({
					data: {
						data: { url },
					},
				}) => {
					window.location = url;
					setServerStatus('COMPLETED');
				},
			)
			.catch((error) => {
				setServerStatus('COMPLETED');
				setServerResponse(error);

				console.error(error);
				toast.error(error);
			});
	};

	const createCheckoutSetup = (customerID: string) => {
		setServerStatus('FETCHING');

		fireFunctions
			.httpsCallable('createCheckoutSetup')({
				customerID,
				returnURL: `${config.url}/portal/payments`,
			})
			.then(
				async ({
					data: {
						data: { id },
					},
				}: {
					data: { data: { id: string } };
				}) => {
					const stripe = await stripePromise;
					if (stripe) {
						const { error } = await stripe?.redirectToCheckout({
							sessionId: id,
						});
						setServerStatus('COMPLETED');
						if (error) {
							setServerResponse(error);

							console.error(error);
							toast.error(error);
						}
					} else setServerStatus('COMPLETED');
				},
			)
			.catch((error) => {
				setServerStatus('COMPLETED');
				setServerResponse(error);

				console.error(error);
				toast.error(error);
			});
	};

	const createCustomer = ({ name, email }: { name: string; email: string }) => {
		setServerStatus('FETCHING');

		fireFunctions
			.httpsCallable('createCustomer')({
				name,
				email,
			})
			.then(
				async ({
					data: {
						data: { customerID },
					},
				}) => {
					createCheckoutSetup(customerID);
				},
			)
			.catch((error) => {
				setServerStatus('COMPLETED');
				setServerResponse(error);

				console.error(error);
				toast.error(error);
			});
	};

	const confirmAction = (action: 'upgradeTeams' | 'downgradeTeams', teams: Team[]) => {
		setServerStatus('FETCHING');
		fireFunctions
			.httpsCallable(action)({
				teams: teams.map((team) => team.__id),
			})
			.then(({ data }) => {
				if (action === 'upgradeTeams') setServerStatus('COMPLETED_UPGRADE');
				else if (action === 'downgradeTeams') setServerStatus('COMPLETED_DOWNGRADE');
				else setServerStatus('COMPLETED');
				setServerResponse(data);
			})
			.catch((error) => {
				setServerStatus('COMPLETED');
				setServerResponse(error);

				console.error(error);
				toast.error(error);
			});
	};

	if (!isLoaded(clubs) || !isLoaded(teams) || !isLoaded(user)) return <Loader />;

	const premiumTeamAmount = teams.filter((_team: Team): boolean => _team.tier === Tier.PREMIUM).length;
	const subscriptionPremiumTeamAmount = teams.filter(
		(_team: Team): boolean => _team.tier === Tier.PREMIUM && !!_team?._stripeSubscription,
	).length;

	return (
		<>
			<Helmet>
				<title>{t('portal.payments.title')}</title>
			</Helmet>
			<PortalHeader />
			<Styled.Wrapper>
				<Styled.Container>
					<Styled.HeaderWrapper>
						<Styled.Heading>{t('portal.payments.title')}</Styled.Heading>
					</Styled.HeaderWrapper>

					<Styled.Content>
						{user.stripe ? (
							user.stripe.paymentMethod ? (
								<Styled.LeftSection>
									<Styled.LeftSectionContent>
										<div>
											<Styled.PremiumAmount>{premiumTeamAmount}</Styled.PremiumAmount>
											{t('portal.payments.activeTeams')}
										</div>
										<Styled.Divider />
										<Styled.Price>
											&euro;{new Number(subscriptionPremiumTeamAmount * price).toFixed(2)}{' '}
											<span>{t('portal.payments.perMonth')}</span>
											<span>{t('portal.payments.incTax')}</span>
										</Styled.Price>
										{user.stripe ? (
											user.stripe.paymentMethod ? (
												<Button
													color="white"
													onClick={() => (user.stripe !== undefined ? createBillingPortal(user.stripe) : null)}
												>
													{t('portal.payments.changePaymentAccount')}
												</Button>
											) : (
												<Button
													color="white"
													onClick={() => (user.stripe !== undefined ? createCheckoutSetup(user.stripe.__id) : null)}
												>
													{t('portal.payments.addPaymentAccount')}
												</Button>
											)
										) : (
											displayName &&
											email && (
												<Button
													onClick={() =>
														createCustomer({
															email: email,
															name: displayName,
														})
													}
													color="white"
												>
													{t('portal.payments.addPaymentAccount')}
												</Button>
											)
										)}
									</Styled.LeftSectionContent>
									<Link link={currentClub ? `/portal/club/${currentClub}/teams` : '/portal'}>
										{t('portal.common.back')}
									</Link>
								</Styled.LeftSection>
							) : (
								<Styled.centerSection>
									<div>
										<Styled.LeftSectionContent>
											{t('portal.payments.addPaymentAccountFirst')}
											<Button
												color="white"
												onClick={() => (user.stripe !== undefined ? createCheckoutSetup(user.stripe.__id) : null)}
											>
												{t('portal.payments.addPaymentAccount')}
											</Button>
										</Styled.LeftSectionContent>
									</div>
								</Styled.centerSection>
							)
						) : (
							<Styled.centerSection>
								<div>
									<Styled.LeftSectionContent>
										{t('portal.payments.addPaymentAccountFirst')}

										{displayName && email && (
											<Button
												onClick={() =>
													createCustomer({
														email: email,
														name: displayName,
													})
												}
												color="white"
											>
												{t('portal.payments.addPaymentAccount')}
											</Button>
										)}
									</Styled.LeftSectionContent>
								</div>
							</Styled.centerSection>
						)}

						{user.stripe?.paymentMethod && (
							<Styled.TeamOverview>
								<>
									<PaymentsUpgrade
										teams={teams.filter((team) => team.tier === Tier.FREE)}
										clubs={clubs}
										currentPremiumAmount={subscriptionPremiumTeamAmount}
										confirmAction={confirmAction}
										serverStatus={serverStatus}
										user={user}
									/>
									<PaymentsDowngrade
										teams={teams.filter((team) => team.tier === Tier.PREMIUM)}
										clubs={clubs}
										user={user}
										currentPremiumAmount={subscriptionPremiumTeamAmount}
										confirmAction={confirmAction}
										serverStatus={serverStatus}
									/>
								</>
							</Styled.TeamOverview>
						)}
					</Styled.Content>
				</Styled.Container>
			</Styled.Wrapper>
			{serverStatus === 'FETCHING' && <Loader overlay />}
		</>
	);
};

const mapStateToProps = (state: RootState): StateProps => ({
	currentClub: state.app.currentClub,
	user: state.firebase.profile,
	teams: state.firestore.ordered.teams,
	clubs: state.firestore.ordered.clubs,
	uid: state.firebase.auth.uid,
});

export default compose(
	connect(mapStateToProps),
	firestoreConnect(({ uid }: FirestoreConnectProps) => [
		{
			collection: 'clubs',
			storeAs: 'clubs',
			where: [
				['__deleted', '==', false],
				['_users', 'array-contains', fireStore.collection('users').doc(uid)],
			],
		},
		{
			collection: 'teams',
			storeAs: 'teams',
			where: [
				['__deleted', '==', false],
				['_users', 'array-contains', fireStore.collection('users').doc(uid)],
			],
		},
	]),
)(PaymentOverview) as React.ComponentType;
