import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: calc(100vh - ${({ theme: { layout } }) => layout.footer.height}px);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;
