import styled from 'styled-components';

import type { StyledOpenBackgroundProps, StyledOpenProps } from './types';

export const NavBar = styled.nav<StyledOpenBackgroundProps>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100px;
	padding: 10px 5vw 10px 5vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 10;
	transition: 0.3s ease all;

	a {
		display: block;
	}

	${({ bg, theme: { colors, layout } }) =>
		bg &&
		`
		height: 80px;
		padding: 0 5vw 0 5vw;
		background: ${colors.background};
		box-shadow: 0 5px 8px rgba(0,0,0, 0.1);
		@media ${layout.device.tabletL} {
			background: transparent;
			box-shadow: none;
		}

		a.link{
			color: ${colors.textColor}!important;

			&:before{
				background-color: ${colors.textColor}!important;
			}
		}
	`}

	a.link {
		color: #fff;
		text-decoration: none;
		display: inline-block;
		position: relative;
		transition: 0.3s all ease-in-out;
		font-size: ${({ theme: { fonts } }) => fonts.sizes.normal};

		&.active {
			/* &::before {
			content: '';
			position: absolute;
			bottom: 0;
			width: 0;
			left: auto;
			right: 0;
			background-color: #fff;
			height: 1px;
			width: 100%;
			transition: 0.3s all ease-in-out;
		} */
		}

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			width: 0;
			left: auto;
			right: 0;
			background-color: #fff;
			height: 1px;
			width: 0%;
			transition: 0.3s all ease-in-out;
		}

		&:hover {
			&::before {
				width: 100%;
				right: auto;
				left: 0;
			}
		}
	}

	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		height: 75px;
		${({ open, theme: { colors } }) =>
			open &&
			`
		background: #fff;
		display: block;
		height: 100%;

		a.link{
			color: ${colors.textColor}!important;
		}
	`}
	}

	@media ${({ theme: { layout } }) => layout.device.laptopM} {
		padding: 10px 20px;

		* {
			font-size: 0.8rem;
		}
	}
`;

export const NavList = styled.ul<StyledOpenBackgroundProps>`
	display: flex;
	justify-content: space-between;
	list-style: none;
	margin-left: auto;

	li {
		margin-right: 30px;

		svg,
		span,
		i {
			color: #fff;
			transition: 0.3s all ease-in-out;

			${({ bg, theme: { colors } }) =>
				bg &&
				`
					color: ${colors.textColor};
			`}
			${({ open, theme: { colors } }) =>
				open &&
				`
					color: ${colors.textColor};
			`}
		}

		.divider {
			background-color: #fff;
			transition: 0.3s all ease-in-out;

			${({ bg, theme: { colors } }) =>
				bg &&
				`
				background-color: ${colors.textColor};
			`}
			${({ open }) =>
				open &&
				`
				display: none;
			`}
		}

		&:last-child {
			margin-left: 10px;
			margin-right: 0;
		}
	}

	@media ${({ theme: { layout } }) => layout.device.laptopM} {
		li:nth-last-child(2) {
			margin-right: 0;
		}
	}

	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		${({ open }) =>
			!open &&
			`
		display: none;
	`}

		width: 100%;
		margin: 0;
		padding: 0;
		flex-direction: column;

		li {
			padding: 15px 0;
			margin-right: 0;
			text-align: center;
			a {
				color: ${({ theme: { colors } }) => colors.textColor};
			}
		}
	}

	@media ${({ theme: { layout } }) => layout.device.mobileS} {
		${({ open }) =>
			!open &&
			`
		display: none;
	`}

		width: 100%;
		margin: 0;
		padding: 0;
		flex-direction: column;

		li {
			padding: 10px 0;
			margin-right: 0;
			text-align: center;
			a {
				color: ${({ theme: { colors } }) => colors.textColor};
			}
		}
	}
`;

export const Logo = styled.img<StyledOpenProps>`
	margin: 10px;
	user-select: none;
	-webkit-user-drag: none;
	cursor: pointer;

	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		width: 30px;
		display: ${({ open }) => (open ? 'block' : 'none')};
	}
`;

export const AccountButtons = styled.div<StyledOpenBackgroundProps>`
	margin-left: 30px;

	a:last-child {
		padding: 10px 55px 12px;
		background-color: #fff;
		border-radius: 50px;
		color: #222;
		transition: 0.3s all ease-in-out;
		margin-left: 30px;

		&:hover {
			background-color: #ebebeb;
			&::before {
				width: 0%;
			}
		}
		${({ bg, theme: { colors } }) =>
			bg &&
			`
		background-color: ${colors.blue};
		color: #fff!important;

		&:hover {
			background-color: ${colors.lightBlue};
		}
	`}

		&:only-child {
			margin-left: 0;
		}
	}

	@media ${({ theme: { layout } }) => layout.device.tabletL} {
		position: absolute;
		bottom: 30px;
		left: 0;
		width: 100%;
		margin-left: 0px;

		flex-direction: column;
		display: flex;

		${({ open }) =>
			!open &&
			`
		display: none;
	`}

		a {
			text-align: center;
			color: ${({ theme: { colors } }) => colors.textColor};
			padding: 15px 0;

			&:last-child {
				background-color: ${({ theme: { colors } }) => colors.blue};
				color: #fff !important;
				margin-right: 30px;
				margin-top: 10px;
			}
		}
	}
`;

export const ProfileContainer = styled.div<StyledOpenBackgroundProps>`
	display: flex;
	align-items: center;

	${({ open }) =>
		open &&
		`
	> div > div {
		color: #000!important;
	}
	`}

	${({ bg }) =>
		bg
			? `
> div > div {
	color: #000;
}
`
			: `
	> div > div {
	color: #fff;
	> div {
		color: #000;
	}
}
	`}

@media ${({ theme: { layout } }) => layout.device.tabletL} {
		flex-direction: column;

		> div > div {
			margin-bottom: 40px;

			> div {
				background: transparent;
			}
		}
	}
`;

export const LanguageWrapper = styled.li<StyledOpenBackgroundProps>`
	display: flex;

	.leftDivider {
		margin-right: 20px;
		background-color: #fff;

		${({ open }) =>
			open &&
			`
			display: none;
		`}

		${({ bg, theme: { colors } }) =>
			bg &&
			`
			background-color: ${colors.textColor};
		`}
	}

	${({ open }) =>
		open &&
		`
			margin-top: 20px;
			display: flex;
			justify-content: center;
	`}
`;

export const Divider = styled.div<StyledOpenBackgroundProps>`
	background-color: rgb(35, 38, 39);
	width: 1px;
	height: 24px;
	opacity: 0.4;
	background-color: #293134;
	margin-left: 10px;
`;
