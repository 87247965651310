import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Crown } from 'css.gg';

import * as Styled from './styles';

import { Link } from 'src/components/global';
import { checkMark } from 'src/assets';

export const PricingTable: React.FC = () => {
	const { t } = useTranslation();

	return (
		<Styled.Container>
			<Styled.Headers>
				<div>
					<b>{t('website.price.columns.overview')}</b>
				</div>
				<div>
					<b>{t('website.price.columns.free')}</b>
				</div>
				<div>
					<Crown/>
					
					<b>{t('website.price.columns.premium')}</b>
				</div>
			</Styled.Headers>
			<Styled.Content>
				<div>
					<div>{t('website.price.items.1')}</div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
				</div>
				<div>
					<div>{t('website.price.items.2')}</div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
				</div>
				<div>
					<div>{t('website.price.items.3')}</div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
				</div>
				<div>
					<div>{t('website.price.items.4')}</div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
				</div>
				<div>
					<div>{t('website.price.items.5')}</div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
				</div>
				<div>
					<div>{t('website.price.items.6')}</div>
					<div></div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
				</div>
				<div>
					<div>{t('website.price.items.7')}</div>
					<div></div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
				</div>
				<div>
					<div>{t('website.price.items.8')}</div>
					<div></div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
				</div>
				<div>
					<div>{t('website.price.items.9')}</div>
					<div></div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
				</div>
				<div>
					<div>{t('website.price.items.10')}</div>
					<div></div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
				</div>
				<div>
					<div>{t('website.price.items.11')}</div>
					<div></div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
				</div>
				<div>
					<div>{t('website.price.items.12')}</div>
					<div></div>
					<div>
						<img src={checkMark} alt="Feature available" />
					</div>
				</div>
				
				<Styled.DownloadRow>
					<div></div>
					<div>
						<Styled.HashLink to="#download">{t('website.price.buttons.free')}</Styled.HashLink>
					</div>
				</Styled.DownloadRow>
				<Styled.Column>
					<style dangerouslySetInnerHTML={{__html: "table.a22{margin-left:auto;margin-right:auto;} table.a22 td{text-align:left;}"}} />
					
					<p dangerouslySetInnerHTML={{__html: t('website.price.priceAfterTrail') }} />
				</Styled.Column>
				
			</Styled.Content>
		</Styled.Container>
	);
};
/*
<Styled.Column>
						<Link link="/register">{t('website.price.buttons.premium')}</Link>
						<p>{t('website.price.priceAfterTrail')}</p>
					</Styled.Column>
*/

export default PricingTable;
