import React from 'react';
import { ImpulseSpinner } from 'react-spinners-kit';

import * as Styled from './styles';
import type { Props } from './types';

import { colors } from 'src/styles/colors';

export const Loader: React.FC<Props> = ({ overlay = false }: Props) => {
	return (
		<Styled.Wrapper>
			{overlay && <Styled.Background />}
			<Styled.Container>
				<ImpulseSpinner size={80} frontColor={colors.blue} backColor={colors.grey} />
			</Styled.Container>
		</Styled.Wrapper>
	);
};

export default Loader;
