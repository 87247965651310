import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	html {
		box-sizing: border-box;
		scroll-behavior: smooth;
	}
	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}

	body {
		background-color: ${({ theme: { colors } }) => colors.background};
		margin: 0;
		padding: 0;
		font-family: 'Poppins';
		scroll-behavior: smooth;
	}

	body * {
		font-family: 'Poppins';
		scroll-behavior: smooth;
	}

	[data-aos='animate'].aos-animate {
		transform: translateY(0px);
		opacity: 1;
		transition: opacity 5s, transform 5s;
	}
	[data-aos='animate'] {
		opacity: 0;
		transform: translateY(20px);
		transition: opacity 0.5s 0s, transform 0.5s 0s;
	}

	@media only screen and (max-width: 768px) {
		[data-aos='animate'] {
			opacity: 1;
			transform: translateY(0px);
		}
	}

	section {
		padding-top: 60px;
		margin-top: -60px;
	}

	p {
		font-size: ${({ theme: { fonts } }) => fonts.sizes.normal};
	}

	.rdt_TableHead {
		text-transform: capitalize;
	}
`;

export default GlobalStyle;
