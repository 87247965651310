import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import * as Styled from './styles';
import type { Props } from './types';

export const Link: React.FC<Props> = ({
	children,
	link,
	internal = true,
	target = '_self',
	marginBottom = '0px',
	marginTop = '0px',
	small = false,
	color = 'blue',
}: Props) => {
	if (internal || link.startsWith('/'))
		return (
			<RouterLink to={link} target={target}>
				<Styled.SpanLink marginBottom={marginBottom} marginTop={marginTop} small={small} color={color}>
					{children}
				</Styled.SpanLink>
			</RouterLink>
		);
	else
		return (
			<Styled.Link
				href={link}
				target={target}
				marginBottom={marginBottom}
				marginTop={marginTop}
				small={small}
				color={color}
			>
				{children}
			</Styled.Link>
		);
};

export default Link;
