import React from 'react';

import * as Styled from './styles';
import type { Props } from './types';

export const Modal: React.FC<Props> = ({ children, modalVisibility, closeAction }: Props) => {
	if (modalVisibility)
		return (
			<Styled.Wrapper
				onMouseDown={(event) => {
					event.target === event.currentTarget && closeAction();
				}}
			>
				<Styled.Content>
					<Styled.CloseButton onClick={(event) => event.target === event.currentTarget && closeAction()} />
					{children}
				</Styled.Content>
			</Styled.Wrapper>
		);
	else return <></>;
};

export default Modal;
