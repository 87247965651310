import styled from 'styled-components';

export const Container = styled.section`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: 150px;
`;

export const Heading = styled.h1`
	font-size: ${({ theme: { fonts } }) => fonts.sizes.huge};
	font-weight: ${({ theme: { fonts } }) => fonts.weights.bolder};
	display: flex;
	justify-content: center;
	margin-bottom: ${({ theme: { layout } }) => layout.margins.sectionTitle};
	text-align: center;
`;

export const Text = styled.div`
	font-size: ${({ theme: { fonts } }) => fonts.sizes.normal};
	font-weight: ${({ theme: { fonts } }) => fonts.weights.normal};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 50%;
	@media only screen and (max-width: ${({ theme: { layout } }) => layout.screenSizes.medium}) {
		max-width: 85%;
	}
`;
