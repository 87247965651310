import React from 'react';

import * as Styled from './styles';
import type { Props } from './types';

export const Checkbox: React.FC<Props> = ({ name, label, required = false, ...props }: Props) => {
	return (
		<Styled.LabelWrapper>
			{required ? (
				<Styled.Checkbox id={name} type="checkbox" required {...props} />
			) : (
				<Styled.Checkbox id={name} type="checkbox" {...props} />
			)}
			<Styled.Label htmlFor={name}>{label}</Styled.Label>
		</Styled.LabelWrapper>
	);
};

export default Checkbox;
