import styled from 'styled-components';

export const Wrapper = styled.div`
	position: relative;
`;

export const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Highlight = styled.div`
	background-color: ${({ theme: { colors } }) => colors.grey};
	color: ${({ theme: { colors } }) => colors.textColor};
	border-radius: 25px;
	padding: 15px 20px;
`;
