import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';

export const LanguageSwitcher: React.FC = () => {
	const { i18n } = useTranslation();
	const [dropdownState, setDropdownState] = useState(false);
	const node = useRef();

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);
		return () => document.removeEventListener('mousedown', handleClick);
	}, []);

	const handleClick = (e: unknown) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (node.current.contains(e.target)) return;

		setDropdownState(false);
	};

	const languages = ['en', 'nl', 'de', 'fr'];

	const changeLanguage = (language: string) => {
		i18n.changeLanguage(language);

		window.location.replace('/' + language + window.location.pathname.substring(3));

		setDropdownState(false);
	};

	return (
		<Styled.Container ref={node}>
			<Styled.CurrentLanguage onClick={() => setDropdownState(!dropdownState)}>
				<span>{i18n.language.toUpperCase()}</span>
				<Styled.ChevronDown />
			</Styled.CurrentLanguage>
			<Styled.Dropdown open={dropdownState}>
				{languages.map(
					(lang) =>
						lang !== i18n.language && (
							<div key={lang} onClick={() => changeLanguage(lang)}>
								{lang.toUpperCase()}
							</div>
						),
				)}
			</Styled.Dropdown>
			<Styled.Divider className={'divider'} />
		</Styled.Container>
	);
};

export default LanguageSwitcher;
