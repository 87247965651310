import styled from 'styled-components';
import { HashLink as RouterHashLink } from 'react-router-hash-link';

export const Container = styled.div`
	width: 50%;
	max-width: 1000px;
	margin-top: 75px;
	margin-bottom: 30px;
	box-shadow: 0 0 16px 0 rgba(55, 55, 55, 0.12);
	border-radius: 10px;
	font-size: 0.9rem;
	@media ${({ theme: { layout } }) => layout.device.laptopM} {
		width: 80%;
	}
	@media ${({ theme: { layout } }) => layout.device.tablet} {
		width: 95%;
	}
`;

export const Headers = styled.div`
	display: flex;
	border-radius: 10px 10px 0 0;
	color: ${({ theme: { colors } }) => colors.textColor};

	i,
	i:before,
	i:after {
		color: #ffa500;
	}

	> div {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-basis: 33.4%;
		flex-grow: 1;
		flex-shrink: 1;
		text-align: center;
		padding: 20px 0;
		border: 1px solid ${({ theme: { colors } }) => colors.grey};
		background: ${({ theme: { colors } }) => colors.background};

		@media ${({ theme: { layout } }) => layout.device.tablet} {
			flex-basis: 33.33334%;
			flex-grow: 0;

			&:first-child {
				flex-grow: 1;
				text-align: left;
				padding-left: 30px;
			}
		}
		:nth-child(1) {
			border-radius: 10px 0 0 0;
		}
		:nth-child(3) {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			border-radius: 0 10px 0 0;

			i {
				margin-bottom: 15px;
			}
		}
		@media ${({ theme: { layout } }) => layout.device.mobileL} {
			flex-basis: 33.333%;
			flex-grow: 0;

			&:first-child {
				flex-grow: 1;
				text-align: left;
				padding-left: 30px;
			}
		}
		@media (max-width: 550px) {
			&:first-child {
				padding: 20px 15px !important;
			}
		}
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	> div {
		display: flex;
		text-align: center;
		> div {
			flex-basis: 33.4%;
			flex-grow: 1;
			flex-shrink: 1;
			padding: 10px 20px;
			border-left: 1px solid ${({ theme: { colors } }) => colors.grey};
			display: flex;
			justify-content: center;
			align-items: center;

			&:nth-child(1),
			&:nth-child(3) {
				background: rgba(239, 239, 239, 0.3);
			}

			@media (max-width: 550px) {
				padding: 10px 15px !important;
				font-size: 0.8rem;
			}
			@media (max-width: 420px) {
				padding: 10px 5px;
				font-size: 0.8rem;
			}
			@media ${({ theme: { layout } }) => layout.device.tablet} {
				flex-basis: 33.4%;
				width: 33.4%;
				flex-grow: 0;
				flex-shrink: 0;

				&:first-child {
					flex-grow: 1;
					text-align: left;
					padding-left: 30px;
				}
			}
			@media ${({ theme: { layout } }) => layout.device.mobileL} {
				flex-basis: 33.4%;
				width: 33.4%;
				flex-grow: 0;
				flex-shrink: 0;

				&:first-child {
					flex-grow: 1;
					text-align: left;
					padding-left: 30px;
				}
			}

			&:first-child {
				display: block;
				text-align: left;
			}
			img {
				height: 17px;
				user-select: none;
				-webkit-user-drag: none;
			}
		}
		&:nth-child(even) {
			background: white;
		}
		&:nth-child(odd) {
			background: ${({ theme: { colors } }) => colors.background};
		}
		&:last-child {
			border-radius: 0 0 10px 10px;
		}
	}
`;

export const HashLink = styled(RouterHashLink)`
	padding: 12px 20px;
	background: ${({ theme: { colors } }) => colors.blue};
	border-radius: 50px;
	color: ${({ theme: { colors } }) => colors.grey};
	text-decoration: none;
	display: block;
	text-align: center;
	margin: 0;
	transition: 0.3s ease all;
	cursor: pointer;
	word-break: keep-all;
	text-decoration: none;
	box-shadow: 0 0.4px 2.2px rgba(0, 0, 0, 0.025), 0 1px 5.3px rgba(0, 0, 0, 0.036), 0 1.9px 10px rgba(0, 0, 0, 0.045),
		0 3.4px 17.9px rgba(0, 0, 0, 0.054), 0 6.3px 33.4px rgba(0, 0, 0, 0.065);
`;

export const DownloadRow = styled.div`
	div {
		padding: 25px 20px !important;
		@media (max-width: 550px) {
			display: none !important;
		}
	}
	div:nth-child(3) {
		a {
			text-decoration: none;
		}

		@media (max-width: 550px) {
			display: flex !important;
			flex-basis: 100%;
			width: 100%;
			justify-content: center;
		}
	}
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
`;
