import styled from 'styled-components';

export const SelectWrapper = styled.div`
	display: inline-block;
	position: relative;
	i {
		pointer-events: none;
		position: absolute;
		top: 8px;
		right: 4px;
		width: 30px;
		height: 36px;
		transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
	}
	&:hover i {
		transition: color 0.3s ease-in-out;
		color: #ffffff;
	}
`;
export const Select = styled.select`
	width: 100%;
	height: 40px;
	cursor: pointer;
	border-radius: 0;
	background-color: transparent;
	border: 2px solid ${({ theme: { colors } }) => colors.blue};
	border-radius: 50px;
	color: ${({ theme: { colors } }) => colors.textColor};
	padding: 0 35px 0 15px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	transition: 0.3s ease-in-out all;

	&::-ms-expand {
		display: none;
	}

	option {
		background-color: #fff;
		color: ${({ theme: { colors } }) => colors.textColor};
	}

	&:hover {
		background-color: ${({ theme: { colors } }) => colors.blue};
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;
