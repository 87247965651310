import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import { ImpulseSpinner } from 'react-spinners-kit';

import type { Props, StateProps, FirestoreConnectProps } from './types';
import * as Styled from './styles';

import { fireStore } from 'src/utils';
import { RootState } from 'src/redux';
import { colors } from 'src/styles';

export const Portal: React.FC<Props> = ({ isLoggedIn, clubs }: Props) => {
	const { push } = useHistory();

	useEffect(() => {
		if (!isLoggedIn) push('/login');
	}, [isLoggedIn]);

	if (clubs && clubs.length > 0) {
		return <Redirect to={`/portal/club/${clubs[0].__id}/teams`} />;
	}

	if (clubs && clubs.length === 0) return <Redirect to="/portal/create-club" />;

	return (
		<Styled.LoaderContainer>
			<ImpulseSpinner size={80} frontColor={colors.blue} backColor={colors.grey} />
		</Styled.LoaderContainer>
	);
};

const mapStateToProps = (state: RootState): StateProps => ({
	isLoggedIn: !state.firebase.auth.isEmpty,
	uid: state.firebase.auth.uid,
	clubs: state.firestore.ordered.clubs,
	currentClub: state.app.currentClub,
});

export default compose(
	connect(mapStateToProps),
	firestoreConnect(({ uid }: FirestoreConnectProps) => [
		{
			collection: 'clubs',
			where: [
				['__deleted', '==', false],
				['_trainers', 'array-contains', fireStore.doc(`users/${uid}`)],
			],
		},
	]),
)(Portal) as React.ComponentType;
