import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';
import type { Props } from './types';

import { table } from 'src/styles';
import { Button } from 'src/components';
import { Confirm, GreyedOut } from 'src/components/global';
import { Team } from 'src/types';
import { fireRemoteConfig } from 'src/utils';
import { config } from 'src/config';

export const PaymentsUpgrade: React.FC<Props> = ({
	teams,
	clubs,
	currentPremiumAmount,
	confirmAction,
	serverStatus,
	user,
	...props
}: Props) => {
	const [selectedRows, setSelectedRows] = useState<Array<Team>>([]);
	const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);
	const [clearSelectedRows, setClearSelectedRows] = useState(false);
	const [price, setPrice] = useState<number>(config.firebaseRemoteConfig.defaults.WEB_PRICE_PER_TEAM);
	const { t } = useTranslation();

	useEffect(() => {
		fireRemoteConfig
			.fetchAndActivate()
			.then(() => setPrice(fireRemoteConfig.getValue('WEB_PRICE_PER_TEAM').asNumber()));

		if (serverStatus === 'COMPLETED_UPGRADE') {
			setConfirmModalVisibility(false);
			toast.info(
				`${selectedRows.length} ${selectedRows.length > 1 ? t('portal.common.teams') : t('portal.common.team')} ${t(
					'portal.payments.upgraded',
				)}`,
			);
			setSelectedRows([]);
			setClearSelectedRows(true);
		}
	}, [serverStatus]);

	const upgradeTeams = () => confirmAction('upgradeTeams', selectedRows);

	const columns = [
		{
			name: t('portal.common.teams'),
			sortable: true,
			selector: 'name',
		},
		{
			name: t('portal.common.club'),
			sortable: true,
			selector: 'club',
			cell: (team: Team) => clubs.find((club) => club.__id === team._club?.id)?.name,
		},
	];

	return (
		<Styled.Wrapper>
			<Styled.HeaderWrapper>
				<h3>{t('portal.common.freeTeams')}</h3>
				<div>
					{selectedRows.length <= 0 ? (
						<GreyedOut>
							<Button small marginBottom="10px" disabled>
								{t('portal.common.upgradeTeams')}
							</Button>
						</GreyedOut>
					) : (
						<Button small marginBottom="10px" action={() => setConfirmModalVisibility(true)}>
							{t('portal.common.upgrade')} {selectedRows.length > 0 && selectedRows.length} {t('portal.common.team')}
							{selectedRows.length > 1 && 's'}
						</Button>
					)}
				</div>
			</Styled.HeaderWrapper>
			<DataTable
				columns={columns}
				defaultSortField={'Team'}
				striped={true}
				customStyles={table}
				data={teams}
				keyField="id"
				selectableRows
				selectableRowsHighlight
				{...props}
				onSelectedRowsChange={(row) => {
					setSelectedRows(row.selectedRows);
					setClearSelectedRows(false);
				}}
				clearSelectedRows={clearSelectedRows}
			/>
			<Confirm
				visibility={confirmModalVisibility}
				closeAction={() => setConfirmModalVisibility(false)}
				proceedText={t('portal.common.confirm')}
				proceedAction={() => upgradeTeams()}
			>
				<h2>{t('portal.payments.upgradeConfirmation')}</h2>
				<ul>
					{selectedRows.map((team: Team) => {
						return <li key={team.__id}>{team.name}</li>;
					})}
				</ul>
				{user?.stripe?._trialUsed ? (
					<p>
						{t('portal.payments.upgradeConfirmationContent')}{' '}
						<b>
							&euro;{Math.round(((selectedRows.length + currentPremiumAmount) * price + Number.EPSILON) * 100) / 100}
						</b>{' '}
						{t('portal.payments.perMonth') + ' ' + t('portal.payments.incTax')}
					</p>
				) : (
					<p>
						<br />
						<Styled.Highlight>{t('portal.trail.30days')}</Styled.Highlight>
					</p>
				)}
			</Confirm>
		</Styled.Wrapper>
	);
};

export default PaymentsUpgrade;
