import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ImpulseSpinner } from 'react-spinners-kit';
import { isLoaded, isEmpty, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import * as Styled from './styles';
import type { Props, InternalProps } from './types';

import { colors } from 'src/styles';
import { RootState, store, changeCurrentClub, changeCurrentTeam } from 'src/redux';
import type { Club, PermissionUser } from 'src/types';
import { Permission } from 'src/types/permission.d';

export const PrivatePortalRoute: React.FC<Props> = ({ component, ...props }: Props) => (
	<Route {...props} render={(routeProps) => <PrivatePortalRouteInternal {...routeProps} component={component} />} />
);

export default PrivatePortalRoute;

const PrivatePortalRouteInternal: React.FC<InternalProps> = ({ component: Component, ...props }: InternalProps) => {
	const { clubID, teamID } = props.match.params;

console.log('*************************ee');


	useFirestoreConnect([
		{
			collection: 'clubs',
			storeAs: 'currentClub',
			doc: clubID,
		},
		{
			collection: 'teams',
			storeAs: 'currentTeam',
			doc: teamID,
		},
	]);
	const club: Club = useSelector(({ firestore: { data } }: RootState) => data.currentClub);
	let team = useSelector(({ firestore: { data } }: RootState) => data.currentTeam);
	const auth = useSelector((state: RootState) => state.firebase.auth);

	if (!isLoaded(auth))
		return (
			<Styled.LoaderContainer>
				<ImpulseSpinner size={80} frontColor={colors.blue} backColor={colors.grey} />
			</Styled.LoaderContainer>
		);

	if (isEmpty(auth)) return <Redirect to="/login" />;

	if (!isLoaded(club))
		return (
			<Styled.LoaderContainer>
				<ImpulseSpinner size={80} frontColor={colors.blue} backColor={colors.grey} />
			</Styled.LoaderContainer>
		);

	if (!clubID || isEmpty(club) || !club) {
		store.dispatch(changeCurrentClub(undefined));

		return <Redirect to="/portal/create-club" />;
	}

	const clubTrainer = club._trainers.find((_trainer) => _trainer.id === auth.uid);

	if (!clubTrainer) {
		store.dispatch(changeCurrentClub(undefined));
		return <Redirect to="/portal" />;
	}

	store.dispatch(changeCurrentClub(clubID));

	if (teamID === undefined) {
		store.dispatch(changeCurrentTeam(undefined));
		return <Component {...props} auth={auth} currentClub={club} />;
	}

	if (!isLoaded(team))
		return (
			<Styled.LoaderContainer>
				<ImpulseSpinner size={80} frontColor={colors.blue} backColor={colors.grey} />
			</Styled.LoaderContainer>
		);

	if (!teamID || isEmpty(team) || !team) {
		store.dispatch(changeCurrentTeam(undefined));

		return <Redirect to={`/portal/club/${clubID}/teams`} />;
	}

	if (!team.__id) team = Object.values(team).find((_team: any) => _team.__id === teamID); // eslint-disable-line @typescript-eslint/no-explicit-any

	const teamPermissionUser: PermissionUser = team._permissionsUsers.find(
		(_permissionUser: PermissionUser) => _permissionUser.user.id === auth.uid,
	);

	if (!teamPermissionUser) {
		store.dispatch(changeCurrentTeam(undefined));

		return <Redirect to={`/portal/club/${clubID}/teams`} />;
	}

	if (teamPermissionUser.type !== Permission.OWNER && teamPermissionUser.type !== Permission.MANAGE) {
		store.dispatch(changeCurrentTeam(undefined));

		return <Redirect to={`/portal/club/${clubID}/teams`} />;
	}

	store.dispatch(changeCurrentTeam(teamID));

	return <Component {...props} auth={auth} currentClub={club} currentTeam={team} />;
};
