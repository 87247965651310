import styled from 'styled-components';

export const ModalHeading = styled.h2`
	font-size: ${({ theme: { fonts } }) => fonts.sizes.big};
	margin: 0 0 20px 0;
`;

export const TableWrapper = styled.div`
	box-shadow: 0 0.7px 2.2px rgba(0, 0, 0, 0.001), 0 1.8px 5.3px rgba(0, 0, 0, 0.003), 0 3.4px 10px rgba(0, 0, 0, 0.007),
		0 6px 17.9px rgba(0, 0, 0, 0.014), 0 11.3px 33.4px rgba(0, 0, 0, 0.026), 0 27px 80px rgba(0, 0, 0, 0.07);
`;

export const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 40px 0 10px;

	* {
		text-decoration: none;
	}

	div {
		cursor: pointer;
		margin-right: 20px;
	}
`;

export const LoaderContainer = styled.div`
	width: 100%;
	height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	div {
		margin-bottom: 30px;
	}
`;

export const NothingFound = styled.div`
	text-decoration: none;
	* {
		text-decoration: none;
	}
`;
