import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { Props, StateProps } from './types';

import type { RootState } from 'src/redux';
import * as Styled from 'src/components/portal/portal-intro/styles';

export const Start: React.FC<Props> = ({ user }: Props) => {
	const { t } = useTranslation();

	return (
		<>
			<Styled.H1>
				{t('portal.intro.start.greeting')} {user?.displayName}
				{t('portal.intro.start.title')}
			</Styled.H1>
			<Styled.Text>{t('portal.intro.start.intro')}</Styled.Text>
		</>
	);
};

const mapStateToProps = (state: RootState): StateProps => ({
	user: state.firebase.profile,
});

export default connect(mapStateToProps)(Start);
