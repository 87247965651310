export const colors = {
	background: '#FAFBFD',
	grey: '#F2F2F2',
	darkGrey: '#DADADA',
	darkerGrey: '#C1C1C1',
	blue: '#30639a',
	lightBlue: '#2977CC',
	textColor: '#293134',
	textColorLight: '#5E5E5E',
	orange: '#f2a51d ',
	lightOrange: '#ffb300',
};

export default colors;
