import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { ImpulseSpinner } from 'react-spinners-kit';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';

import * as Styled from './styles';
import type { FirestoreConnectProps, OwnProps, Props, StateProps, UserWithTeams } from './types';

import { colors, table } from 'src/styles';
import { Link, Button, PortalHeader, Footer } from 'src/components';
import { fireFunctions, fireStore } from 'src/utils';
import type { RootState } from 'src/redux';
import type { PermissionUser, Team, User } from 'src/types';
import { Permission } from 'src/types/permission.d';

export const Select: React.FC<Props> = ({
	closeAction,
	originalTrainers,
	frame = false,
	club,
	teams,
	teamID = '',
}: Props) => {
	const [trainers, setTrainers] = useState<Array<UserWithTeams>>();
	const [selectedRows, setSelectedRows] = useState<Array<UserWithTeams> | null>(null);
	const { t } = useTranslation();

	useEffect(() => {
		const userIDs: Set<string> = new Set();

		if (isLoaded(club))
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			club?._trainers.forEach((_trainer: any) => {
				userIDs.add(_trainer?.id);
			});

		if (isLoaded(teams))
			teams.forEach((_team: Team) => {
				_team._permissionsUsers.forEach((_permissionsUser: PermissionUser) => userIDs.add(_permissionsUser.user.id));
			});

		const requests = Array.from(userIDs).map(async (_userID: string) => await fireStore.doc(`users/${_userID}`).get());

		Promise.all(requests).then((response) => {
			let users: Array<User> = response.map((_data) => _data.data() as User);

			users = Array.from(new Set(users));

			if (teams)
				setTrainers(
					users.map((_user: User) => ({
						..._user,
						teams: teams.filter((_team: Team) =>
							_team._permissionsUsers.find((_permissionUser: PermissionUser) => _permissionUser.user.id === _user.uid),
						),
					})),
				);
		});
	}, [club, teams]);

	const saveTrainers = async () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const rowsToAdd: any[] = [];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const rowsToRemove: any[] = [];

		if (selectedRows)
			selectedRows.forEach((_trainer) => {
				if (originalTrainers)
					!originalTrainers.find((trainer) => trainer.uid === _trainer.uid) && rowsToAdd.push(_trainer);
			});

		if (originalTrainers)
			originalTrainers.forEach((_trainer) => {
				if (selectedRows) !selectedRows.find((trainer) => trainer.uid === _trainer.uid) && rowsToRemove.push(_trainer);
			});

		const requests = [
			...rowsToAdd.map(async (trainer) => await updateTrainer(trainer.uid, Permission.READ, teamID)),
			...rowsToRemove.map(async (trainer) => await updateTrainer(trainer.uid, Permission.NONE, teamID)),
		];

		Promise.all(requests).then(() => {
			if (closeAction) closeAction();
		});
	};

	const updateTrainer = async (userID: string, type: Permission, teamID: string) => {
		await fireFunctions.httpsCallable('updateUserAccess')({
			userID,
			type: type ? type : Permission.NONE,
			teamID,
		});
	};

	const columns = [
		{
			name: t('portal.common.name'),
			selector: 'displayName',
			sortable: true,
		},
	];

	return !trainers ? (
		<>
			{!frame && <PortalHeader />}
			<Styled.LoaderContainer>
				<ImpulseSpinner size={80} frontColor={colors.blue} backColor={colors.grey} />
			</Styled.LoaderContainer>
			{!frame && <Footer />}
		</>
	) : (
		<>
			{!frame && <PortalHeader />}
			<Styled.ModalHeading>{t('portal.common.selectTrainers')}</Styled.ModalHeading>

			{trainers && trainers.length !== 0 ? (
				<>
					<p>{t('portal.common.selectTrainersToAdd')}</p>
					<Styled.TableWrapper>
						<DataTable
							columns={columns}
							striped={true}
							defaultSortField={'name'}
							customStyles={table}
							data={trainers}
							keyField="uid"
							selectableRows={true}
							selectableRowsHighlight={true}
							selectableRowSelected={(user: UserWithTeams) => {
								if (originalTrainers) {
									const selected = originalTrainers.find((_trainer) => _trainer.uid === user.uid);
									return selected ? true : false;
								} else return false;
							}}
							selectableRowDisabled={(user: UserWithTeams) => {
								if (originalTrainers)
									return originalTrainers.find(
										(_trainer) => _trainer.uid === user.uid && _trainer.type === Permission.OWNER,
									)
										? true
										: false;
								else return false;
							}}
							onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
						/>
					</Styled.TableWrapper>
					<Styled.ButtonWrapper>
						<div
							onClick={() => {
								if (closeAction) closeAction();
							}}
						>
							{t('portal.common.cancel')}
						</div>
						<Button action={() => saveTrainers()}>{t('portal.common.save')}</Button>
					</Styled.ButtonWrapper>
				</>
			) : (
				<Styled.NothingFound>
					{t('portal.common.noPlayers')}{' '}
					{club && <Link link={`/portal/club/${club.__id}/trainers`}>{t('portal.common.trainersPage')}</Link>}
				</Styled.NothingFound>
			)}
			{!frame && <Footer />}
		</>
	);
};

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
	...props,
	club: state.firestore.data.currentClub,
	teams: state.firestore.ordered.teams,
});

export default compose(
	connect(mapStateToProps),
	firestoreConnect(({ clubID }: FirestoreConnectProps) => [
		{
			collection: 'clubs',
			storeAs: 'currentClub',
			doc: clubID,
		},
		{
			collection: 'teams',
			storeAs: 'teams',
			where: [
				['__deleted', '==', false],
				['tier', '==', 'PREMIUM'],
				['_club', '==', fireStore.doc(`clubs/${clubID}`)],
			],
		},
	]),
)(Select) as React.ComponentType<Props>;
