import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';

import { Header, Footer } from 'src/components';

export const NotFound: React.FC = () => {
	const { push } = useHistory();
	const [time, setTime] = useState(5000);
	const { t } = useTranslation();

	useEffect(() => {
		if (time === 0) push('/');
		const timer = setTimeout(() => setTime(time - 1000), 1000);
		return () => clearTimeout(timer);
	}, [push, time]);

	const getTimeInSeconds = (seconds: number) => Math.floor(seconds / 1000);

	return (
		<>
			<Helmet>
				<title>{t('portal.404.title')}</title>
			</Helmet>
			<Header />
			<Styled.Container>
				<h1>{t('portal.404.title')}</h1>
				<p>
					{t('portal.404.description1')} <Link to="/">{t('portal.404.homepage')}</Link> {t('portal.404.description2')}{' '}
					{getTimeInSeconds(time)} {t('portal.404.description3')}
					{getTimeInSeconds(time) > 1 && t('portal.404.description4')}.
				</p>
			</Styled.Container>

			<Footer />
		</>
	);
};

export default NotFound;
