import { Store, createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { routerMiddleware, connectRouter, RouterState } from 'connected-react-router';
import { getFirebase, firebaseReducer, UserProfile, FirestoreReducer, FirebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import thunk from 'redux-thunk';

import { history } from 'src/utils';
import { AppState, appReducer } from 'src/redux/app';

export interface RootState {
	app: AppState;
	router: RouterState;
	firebase: FirebaseReducer.Reducer<UserProfile>;
	firestore: FirestoreReducer.Reducer;
}

export const configureStore = (initialState: RootState): Store => {
	const composeEnhancer: typeof compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const store = createStore(
		combineReducers({
			router: connectRouter(history),
			app: appReducer,
			firebase: firebaseReducer,
			firestore: firestoreReducer,
		}),
		initialState,
		composeEnhancer(applyMiddleware(routerMiddleware(history), thunk.withExtraArgument(getFirebase))),
	);

	return store;
};
