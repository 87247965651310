import styled from 'styled-components';

import type { StyledProps } from './types';

export const Button = styled.button<StyledProps>`
	padding: ${({ small }) => (small === true ? '6px 10px' : '12px 20px')};
	background: ${({ color, theme: { colors } }) =>
		color === 'blue' ? colors.blue : color === 'orange' ? colors.orange : colors.background};
	border: none;
	border-radius: 50px;
	color: ${({ color, theme: { colors } }) => (color === 'blue' ? colors.grey : colors.textColor)};
	color: ${({ color, theme: { colors } }) =>
		color === 'blue' ? colors.grey : color === 'orange' ? colors.textColor : colors.textColor};
	text-decoration: none;
	display: block;
	text-align: center;
	margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
	transition: 0.3s ease all;
	word-break: keep-all;
	cursor: pointer;
	text-decoration: none;
	box-shadow: 0 0.4px 2.2px rgba(0, 0, 0, 0.025), 0 1px 5.3px rgba(0, 0, 0, 0.036), 0 1.9px 10px rgba(0, 0, 0, 0.045),
		0 3.4px 17.9px rgba(0, 0, 0, 0.054), 0 6.3px 33.4px rgba(0, 0, 0, 0.065), 0 15px 80px rgba(0, 0, 0, 0.09);
	text-decoration: none;

	&:hover {
		background: ${({ color, theme: { colors } }) =>
			color === 'blue' ? colors.lightBlue : color === 'orange' ? colors.lightOrange : colors.darkGrey};
	}

	a {
		color: inherit;
		text-decoration: none;
	}
`;
