// WARNING
// FILE NO LONGER IN USE

import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import readXlsxFile from 'read-excel-file';
import { Helmet } from 'react-helmet';
import { default as firebase } from 'firebase/app';
import { toast } from 'react-toastify';

import * as Styled from './styles';
import type { Props } from './types';

import { Button, PortalHeader, Footer } from 'src/components';
import { fireStore, splitName } from 'src/utils';

export const Import: React.FC<Props> = ({ frame = false, currentClub, closeAction }: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const uploadFile = (event: any) => {
		event.preventDefault();

		if (event.target.elements.file.files.length === 0) {
			toast.error('Selecteer een bestand');
			return;
		}

		readXlsxFile(event.target.elements.file.files[0]).then(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			async (rows: any) => {
				const batch = fireStore.batch();
				let validatedPlayers = 0;

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				await rows.map(async (row: any, index: number) => {
					if (index > 1) {
						if (!row[0] || !row[2] || !row[4]) {
							toast.error(`Rij ${index + 1} gefaald`);
						} else {
							validatedPlayers++;
							const ref = await fireStore.collection('players').doc();
							const bDate = row[4].split('-');
							const name = splitName(row[2]);

							if (currentClub)
								batch.set(ref, {
									__deleted: false,
									__id: ref.id,
									_clubs: [fireStore.collection('clubs').doc(currentClub)],
									_baseTeams: [],
									_guestTeams: [],
									firstName: name.first,
									middleName: name.middle,
									lastName: name.last,
									birthDate: firebase.firestore.Timestamp.fromDate(new Date(`${bDate[1]}-${bDate[0]}-${bDate[2]}`)),
									backNumber: null,
									knvbNumber: row[0],
									size: null,
									positions: [],
									legPreference: [],
									bio: null,
								});
						}
					}
				});

				// upload to fireStore
				batch
					.commit()
					.then(() => {
						toast.info(`${validatedPlayers} spelers toegevoegd`);
						if (closeAction) closeAction();
					})
					.catch((error) => {
						toast.error(error);
						if (closeAction) closeAction();
					});
			},
		);
	};

	return (
		<>
			<Helmet>
				<title>Spelers importeren</title>
			</Helmet>
			{!frame && <PortalHeader />}
			<Styled.ModalHeading>Spelers importeren uit sportlink</Styled.ModalHeading>
			<p>Volg de volgende stappen om spelers te importeren uit sportlink</p>
			<ul>
				<li>
					Maak een export in sportlink
					<Styled.Small>
						Navigeer in SportLink naar personen, zoek de juiste personen en klik op zoeken. Klik vervolgens op exporteer{' '}
						{'>'} zichtbare kolommen {'>'} bewaar en opslaan op PC.
					</Styled.Small>
				</li>
				<li>Upload het bestand hieronder</li>
				<li>Wij verwerken de informatie en spelers worden automatisch toegevoegd</li>
			</ul>
			<form onSubmit={uploadFile}>
				<h2>Bestand kiezen</h2>
				<input type="file" id="file" name="file" />
				<br />
				<br />
				<Button type="submit">Toevoegen</Button>
			</form>
			{!frame && <Footer />}
		</>
	);
};

export default Import;
