import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';
import type { Props, OwnProps, StateProps } from './types';

import { Input, Button, Footer, PortalHeader } from 'src/components';
import { fireStore } from 'src/utils';
import type { RootState } from 'src/redux';
import { Club } from 'src/types';
import { Permission } from 'src/types/permission.d';

export const Create: React.FC<Props> = ({ frame = false, closeAction }: Props) => {
	const { push } = useHistory();
	const user = useSelector((state: RootState) => state.firebase.auth);
	const { t } = useTranslation();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	/*
	const createNewClub = (event: any) => {
		event.preventDefault();
		const { name, address, postalcode, city, phoneNumber } = event.target.elements;

		if (!name.value) {
			toast.error(t('portal.errors.emptyName'));
			return;
		}
		if (!address.value) {
			toast.error(t('portal.errors.emptyAddress'));
			return;
		}
		if (!postalcode.value) {
			toast.error(t('portal.errors.emptyAddress'));
			return;
		}
		if (!city.value) {
			toast.error(t('portal.errors.emptyCity'));
			toast.error('');
			return;
		}
		if (!phoneNumber.value) {
			toast.error(t('portal.errors.emptyPhone'));
			return;
		}


		const club = {
			__deleted: false,
			__id: fireStore.collection('clubs').doc().id,
			name: name.value,
			address: address.value,
			postalcode: postalcode.value,
			city: city.value,
			phoneNumber: phoneNumber.value,
			_permissionsUsers: [],
			_users: [],
			_trainers: [],
		};
*/
/*		const club = {
			__deleted: false,
			__id: fireStore.collection('clubs').doc().id,
			name: name.value,
			address: address.value,
			postalcode: postalcode.value,
			city: city.value,
			phoneNumber: phoneNumber.value,
			_permissionsUsers: [],
			_users: [],
			_trainers: [],
		};
*/
//		createClubInFirestore(club);
//	};

	const createClubInFirestore = async (club: Club) => {
		const exClub =await fireStore.collection('clubs');

		await fireStore .collection('clubs') .doc(club.__id)
			.set({
				...club,
				_permissionsUsers: [
					{
						type: Permission.OWNER,
						user: fireStore.collection('users').doc(user.uid),
					},
				],
				_users: [fireStore.collection('users').doc(user.uid)],
				_trainers: [fireStore.collection('users').doc(user.uid)],
			})
			.then(() => {
				toast.info(`${club.name} ${t('portal.create.created')} ${club.__id}`);
				//push(`/portal/club/${club.__id}/teams`);
				//return <Redirect to="/portal/club/`${club.__id}`/teams"/>;
			})
			.catch(toast.error);
	};


	const myOnlyClub_CheckCreate = async () => {
		
		/*await fireStore.collection('clubs')
		//.where('__deleted', '==', false)
		//.where('_users', 'array-contains', fireStore.doc(`users/${user.uid}`)) //fireStore.collection('users').doc(user.uid))
		.get()
		.then((a) =>{
			console.log(`aaaaaaaaaaaaaa`,a)
		})

		return ;*/
		let i=0;
		//let myOnlyClub;
		let myOnlyClubID='';
		//console.log(`USER: ${user.uid}`,user)
		//console.log(fireStore.doc(`users/${user.uid}`))
		const exClubs= await fireStore.collection('clubs')
			.where('_users', 'array-contains', fireStore.doc(`users/${user.uid}`))//fireStore.doc(`users/${user.uid}`))
			.get();
				
		console.log(`******** USER: ${user.uid}`,user)
		exClubs.forEach(async (_c) => {
			//console.log(`s1sssssssss ${i} ${_c.data().__id}`,_c.data());
			//fireStore.collection('clubs').doc(`${_c.data().__id}`);//.update({__deleted:true})//delete();
			const cID=_c.data().__id;
			if(i++==0){
				await fireStore.collection('clubs').doc(`${cID}`).update({__deleted:false});		
				myOnlyClubID=cID;
				console.log(`*********** using first myOnlyClub ${cID}`,_c.data());
			}else{
				await fireStore.collection('clubs').doc(`${cID}`).update({__deleted:true});
				await fireStore.collection('clubs').doc(`${cID}`).delete();//doc(`clubs/${_c.data().__id}`).delete()
				console.log(`*********** deleting extra club ${cID}`,_c.data());
			}
		});
console.log(`*************** ${i} clubs`)
		if(!i){
			const userRef=fireStore.collection('users').doc(user.uid);
			myOnlyClubID=fireStore.collection('clubs').doc().id;
			await fireStore .collection('clubs') .doc(myOnlyClubID)
			.set({
				__deleted: false,
				__id: myOnlyClubID,
				name: '',
				address: '',
				postalcode: '',
				city: '',
				phoneNumber: '',
					_permissionsUsers: [
					{
						type: Permission.OWNER,
						user: userRef,
					},
				],
				_users: [userRef],
				_trainers: [userRef],
			})
			.then(() => {
				toast.info(`myOnlyClub ${t('portal.create.created')} ${myOnlyClubID}`);
				//push(`/portal/club/${club.__id}/teams`);
				//return <Redirect to="/portal/club/`${club.__id}`/teams"/>;
			})
			.catch(toast.error);
		}

		//sendEmail("vhserv@hotmail.com", `myOnlyClub ${t('portal.create.created')} ${myOnlyClubID}`, "#SIGMA89dr712");
		
		push(`/portal/club/${myOnlyClubID}/teams`);


		
		//console.log('ssssssssss',exClub);
		return <></>;
		

/*
		const club = {
			__deleted: false,
			__id: fireStore.collection('clubs').doc().id,
			name: '',
			address: '',
			postalcode: '',
			city: '',
			phoneNumber: '',
			_permissionsUsers: [],
			_users: [],
			_trainers: [],
		};
	
		await fireStore .collection('clubs') .doc(club.__id)
			.set({
				...club,
				_permissionsUsers: [
					{
						type: Permission.OWNER,
						user: fireStore.collection('users').doc(user.uid),
					},
				],
				_users: [fireStore.collection('users').doc(user.uid)],
				_trainers: [fireStore.collection('users').doc(user.uid)],
			})
			.then(() => {
				toast.info(`${club.name} ${t('portal.create.created')} ${club.__id}`);
				//push(`/portal/club/${club.__id}/teams`);
				//return <Redirect to="/portal/club/`${club.__id}`/teams"/>;
			})
			.catch(toast.error);
*/

	};




	//createClubInFirestore(club);
	//return <Redirect to="/portal/club/`${club.__id}`/teams"/>;	
	myOnlyClub_CheckCreate();
	
	return <></>;

	
	/* return (
		<>
			<Helmet>
				<title>{t('portal.create.club')}</title>
			</Helmet>
			{!frame && <PortalHeader />}
			{!frame && <Styled.Spacing />}
			<Styled.Wrapper>
				<Styled.InformationContainer>
					<Styled.Center>
						<Styled.HeaderWrapper>
							<Styled.Heading>{t('portal.create.club')}</Styled.Heading>
						</Styled.HeaderWrapper>
					</Styled.Center>
					<Styled.Form onSubmit={createNewClub}>
						<Input name="name" type="text" label={t('portal.club.name')} placeholder="Mijn club" required />
						<Input name="address" type="text" label={t('portal.club.street')} placeholder="Dam 1" required />
						<Input name="postalcode" type="text" label={t('portal.club.postalCode')} placeholder="1234AB" required />
						<Input name="city" type="text" label={t('portal.club.city')} placeholder="Amsterdam" required />
						<Input name="phoneNumber" type="tel" label={t('portal.club.phone')} placeholder="+31612345678" required />
						<Styled.ButtonWrapper>
							{frame && <div onClick={closeAction}>{t('portal.common.cancel')}</div>}
							<Button>{t('portal.create.club')}</Button>
						</Styled.ButtonWrapper>
					</Styled.Form>
				</Styled.InformationContainer>
			</Styled.Wrapper>
			{!frame && <Footer />}
		</>
	);*/
};

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => ({
	...ownProps,
	isLoggedIn: !state.firebase.auth.isEmpty,
});

export default connect(mapStateToProps)(Create);
